* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* reminder - adjust helpers.siteZoomListenerStart when updating these */

@media screen and (max-width:1120px) {
  body:not(.mobile-app) {
    zoom: 80%;
  }
}

/* reminder - adjust helpers.siteZoomListenerStart when updating these */

@media screen and (max-width:900px) {
  body:not(.mobile-app) {
    zoom: 70%;
  }
}

/* reminder - adjust helpers.siteZoomListenerStart when updating these */

@media screen and (max-width:732px) {
  body:not(.mobile-app) {
    zoom: 65%;
  }
}

html, body {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.template-widget html, .template-widget body {
  position: unset;
  width: unset;
  height: unset;
  overflow: unset;
}

.markup-preview html, .markup-preview body {
  position: unset;
  width: unset;
  height: unset;
  overflow: unset;
}

#loading-spinner {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: black;
  z-index: 5000;
}

#loading-spinner.load-complete {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
}

#loading-spinner .spin-svg {
  display: flex;
  margin: auto;
  position: relative;
}

#loading-spinner .spin-svg svg {
  height: 100%;
  width: 100%;
}

#loading-spinner .spin-svg .loading-label {
  color: orange;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 22px;
  z-index: 1001;
}

#loading-spinner .spin-svg .loading-label div {
  margin: auto;
}

@keyframes landing-spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.landing-spin {
  animation: landing-spin 5s linear infinite;
}

body {
  font-family: "Segoe UI", Arial, Tahoma;
  line-height: 1.4;
}

.mobile-app select {
  font-size: 16px;
}

select {
  background-color: white;
}

.flex-box {
  display: flex;
}

.flex-center-contents {
  display: flex;
}

.flex-center-contents div {
  margin-top: auto;
  margin-bottom: auto;
}

.transparent {
  background-color: unset !important;
}

.support-warning .header-background {
  background-color: #5d221e;
}

.support-warning .info-box {
  background-color: #5d221e;
}

.support-warning .user-info {
  background-color: #5d221e;
}

.support-warning .weather-bar {
  background-color: #5d221e;
}

.support-warning .site-header {
  background-color: #5d221e;
}

.support-warning .header-dot {
  background-color: #5d221e;
}

.support-warning .site-logo {
  background-color: #5d221e;
}

.support-info {
  position: absolute;
  color: white;
  font-weight: bold;
  top: 7px;
  left: 400px;
  z-index: 10;
}

.header-background {
  background-color: black;
}

#root, #app {
  height: 100%;
}

.invisible {
  opacity: 0;
}

.zero-index {
  z-index: 0 !important;
}

.z-10 {
  z-index: 10;
}

.small-bold-text {
  font-size: .8em;
  font-weight: bold;
}

.full-height {
  height: 100%;
}

.no-pad {
  padding: 0px !important;
}

.no-border-radius {
  border-radius: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

.pad-all {
  padding: 5px;
}

.pad-left {
  padding-left: 15px;
}

.pad-left-small {
  padding-left: 5px;
}

.pad-right-small {
  padding-right: 5px;
}

.pad-bottom {
  padding-bottom: 5px;
}

.pad-top {
  padding-top: 5px;
}

.pad-top-10 {
  padding-top: 10px;
}

.pad-right {
  padding-right: 5px;
}

.left-spacer {
  margin-left: 5px;
}

.top-spacer {
  margin-top: 5px;
}

.right-spacer {
  margin-right: 5px;
}

.bottom-spacer {
  margin-bottom: 5px;
}

.left-spacer-10 {
  margin-left: 10px;
}

.right-spacer-10 {
  margin-right: 10px;
}

.top-spacer-10 {
  margin-top: 10px;
}

.bottom-spacer-10 {
  margin-bottom: 10px;
}

.spacer-all {
  margin: 10px;
}

.bold-text {
  font-weight: 500;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-21 {
  font-size: 21px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-23 {
  font-size: 23px !important;
}

.font-24 {
  font-size: 24px !important;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 10;
}

.auto-left-margin {
  margin-left: auto;
}

.auto-right-margin {
  margin-right: auto;
}

.auto-top-margin {
  margin-top: auto;
}

.auto-bottom-margin {
  margin-bottom: auto;
}

.no-background {
  background-color: transparent !important;
}

.white-background {
  background-color: white;
}

.shadow-top {
  padding-top: 5px;
  margin-top: 5px;
  border-top: 1px solid #c0c2c7;
  background-clip: content-box;
  border-top-right-radius: 3px;
}

.shadow-right {
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid #c0c2c7;
  background-clip: content-box;
  box-shadow: #949494 2px 2px 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.shadow-bottom {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #c0c2c7;
  background-clip: content-box;
  box-shadow: #949494 2px 2px 5px;
  border-bottom-right-radius: 3px;
}

.shadow-left {
  margin-left: 5px;
  padding-left: 5px;
  border-left: 1px solid #c0c2c7;
  background-clip: content-box;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.shadow-box {
  padding: 5px;
  margin: 5px;
  border: 1px solid #c0c2c7;
  background-clip: content-box;
  box-shadow: #949494 2px 2px 5px;
  border-radius: 3px;
}

.shadow-box .grid-widget {
  padding: 0px;
}

.text-btn {
  display: flex;
  user-select: none;
}

.text-btn .text-widget {
  margin: auto;
  width: unset;
  height: unset;
  color: white;
  padding: 2px;
  background-color: darkgrey;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 100px;
  cursor: default;
}

.btn-blue .text-widget {
  background-color: #183eb2;
}

.btn-blue .text-widget:hover {
  background-color: #4672f9;
}

.btn-red .text-widget {
  background-color: #c20000;
}

.btn-red .text-widget:hover {
  background-color: #e42626;
}

.btn-green-add .text-widget {
  background-color: #4AB84A;
}

.btn-green-add .text-widget:hover {
  background-color: #52cb52;
}

.sproc-btn {
  background-color: #dd7c0e;
  margin-right: 0px !important;
}

.sproc-btn:hover {
  background-color: #f0870f;
}

.noselect {
  user-select: none;
}

input[type=number]::-webkit-inner-spin-button {
  margin-left: 5px;
}

input[type=date] {
  font-family: arial;
}

input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type=date]::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}

input[inputmode=numeric] {
  padding-right: 10px;
}

select:disabled {
  background-color: #fafafa !important;
  opacity: 1;
}

.engine-widget>*:not(.editor-container) {
  display: none;
}

.hidden-widget {
  display: none;
}

.site-scene {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.site-scene.find-widget * {
  cursor: crosshair !important;
}

.site-scene.edit-mode {
  top: 15%;
  left: 15%;
  height: 85%;
  width: 85%;
  background-color: #fafafa;
}

.modal-layer.edit-mode {
  top: 15%;
  left: 15%;
  height: 85%;
  width: 85%;
}

.site-header {
  background-color: black;
  color: #fdba30;
}

.text-icon svg {
  width: 100%;
  height: 100%;
}

.dialog-layer {
  position: fixed;
  z-index: 1002;
  height: 100%;
  width: 100%;
  background-color: #00000075;
  display: flex;
}

.hide-list-markers li {
  text-align: left;
  margin-left: 20px;
}

.dialog-layer .dialog {
  margin: auto;
  width: 325px;
  min-height: 175px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.dialog-layer .title-bar {
  display: flex;
  flex: 0 30px;
  border-bottom: 1px solid #8c8e90;
  color: #fdba30;
  background-color: black;
  font-weight: 500;
}

.dialog-layer .title {
  margin: auto;
  margin-left: 10px;
  flex: 1;
  font-size: 15px;
}

.dialog-layer .close-btn {
  margin: auto;
  margin-right: 5px;
  flex: 0 25px;
  cursor: default;
  display: flex;
}

.dialog-layer .close-btn>div {
  margin: auto;
}

.dialog-layer .message-box {
  background-color: white;
  flex: 1;
  padding: 12px;
}

.dialog-layer .message {
  height: 100%;
  display: flex;
  text-align: center;
}

.dialog-layer .message>div {
  margin: auto;
}

.dialog-layer .btn-bar {
  flex: 0 46px;
  display: flex;
  padding: 11px;
}

.dialog-layer .ok-btn {
  margin: auto;
  margin-right: 5px;
  width: 75px;
  background-color: #226640;
  border-radius: 20px;
  font-size: 14px;
  text-align: center;
  color: white;
  cursor: default;
  height: 100%;
  display: flex;
}

.dialog-layer .ok-btn>div {
  margin: auto;
}

.dialog-layer .ok-btn:hover {
  background-color: #4E8566;
}

.modal-layer {
  position: fixed;
  z-index: 1001;
  height: 100%;
  width: 100%;
  background-color: #00000075;
}

.modal-layer .widget-loader {
  opacity: 1;
  z-index: 1002;
  background-color: white;
}

.modal-layer .grid, .site-scene .grid {
  display: grid;
  height: 100%;
  min-width: 0;
  min-height: 0;
}

.widget-not-found {
  display: flex;
  background-color: burlywood;
  border: 1px solid deeppink;
}

.widget-not-found {
  color: red;
}

.widget-not-found span {
  margin-left: 5px;
  color: black;
}

.full-screen .widget-loader {
  overflow: visible;
}

.widget-loader {
  overflow: hidden;
  position: relative;
}

.widget-loader>div {
  width: 100%;
  height: 100%;
}

.div-btn {
  height: 25px;
  width: 25px;
  display: flex;
  background-color: #efefef;
  border: 1px solid #a9a9a9;
  user-select: none;
  cursor: pointer;
}

.div-btn svg {
  height: 20px;
  margin: auto;
}

.search-bar {
  display: flex;
  margin: auto;
}

.search-bar .div-btn {
  margin-left: -1px;
  min-height: 30px;
  min-width: 30px;
}

.search-bar input {
  min-height: 30px;
  width: 100%;
  font-size: 16px;
  padding-left: 5px;
  outline: none;
}

.search-bar .center-content {
  width: 100%;
  padding: 5px;
}

.center-content {
  display: flex;
  margin: auto;
}

.card-list {
  overflow-y: auto;
  margin-top: 0;
}

.card-item {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 5px;
  padding-left: 10px;
  cursor: default;
  position: relative;
  min-height: 35px;
  max-height: 35px;
  border: 1px solid #e4e4e4;
}

.query-list .card-item:not(.top-level-parent-card) {
  max-height: unset;
}

.compact-list .card-item {
  max-height: unset !important;
}

.compact-list .card-item.top-level-parent-card .chevron {
  position: absolute;
}

.compact-list .card-item.top-level-parent-card .text-1 {
  margin-left: 20px;
}

.query-list .parent-card:not(.top-level-parent-card) {
  cursor: default;
}

.query-list .parent-card:not(.top-level-parent-card) .chevron {
  background-color: white;
  padding: 0;
  margin: 0;
  cursor: pointer;
  min-height: 32px;
  min-width: 34px;
  margin-top: -4px;
  margin-left: -11px;
  display: flex;
  border-top: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
}

.query-list .parent-card.expand:not(.top-level-parent-card) .chevron {
  border-bottom: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  border-top: none;
  border-right: none;
}

.query-list .parent-card:not(.top-level-parent-card) .chevron div {
  margin: auto;
  margin-top: -9px;
  margin-left: 12px;
  z-index: 0;
  pointer-events: none;
  user-select: none;
}

.query-list .parent-card:not(.top-level-parent-card) .text-1 {
  padding-left: 5px;
}

.card-item.icon-card .text-1 {
  margin-left: 40px;
  overflow: hidden;
}

.card-item.icon-card .text-2 {
  margin-left: 40px;
}

.card-item .card-icon {
  margin-top: 5px;
  margin-bottom: auto;
}

.card-icon {
  position: absolute;
  width: 35px;
  height: 35px;
}

.card-icon img {
  width: 100%;
  height: 100%;
}

.large-card .card-item {
  min-height: 50px;
  max-height: 250px;
}

.card-item.multiselect {
  flex-direction: row;
}

.card-item .multiselect-box {
  margin-left: auto;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 33px;
  border-left: 1px solid #e4e4e4;
  cursor: default;
}

.large-card .card-item .multiselect-box {
  width: 50px;
  height: 48px;
}

.card-item .multiselect-box>div {
  margin: auto;
}

.multiselect-box svg {
  width: 18px;
  height: 18px;
  margin: auto;
}

.mobile-user-info .current-user {
  width: 100%;
}

.mobile-user-info .info-box {
  margin-left: auto;
  margin-right: 35px;
}

.card-item.selected {
  background-color: #ffcb5c;
}

.card-item:not(.top-level-parent-card):not(.selected):hover {
  background-color: #ffefcb;
}

.card-item>div {
  margin-top: auto;
  margin-bottom: auto;
}

.card-item {
  margin-top: -1px;
}

.card-item .text-1 {
  font-weight: 500;
  font-size: 16px;
}

.card-item .text-3 {
  position: absolute;
  right: 5px;
  top: 4px;
  font-size: 13px;
  font-weight: bold;
  color: #8d8d8d;
}

.text-3-top .card-item .text-3 {
  top: 5px !important;
}

.entity-results .card-item .text-1 {
  top: unset;
  bottom: 4px;
  font-weight: 500;
  font-size: 14px;
  margin-top: 3px;
}

.entity-results .card-item .text-3 {
  top: 0px;
  right: 0px;
  font-size: 11px;
}

.no-icon .card-item .card-icon {
  display: none;
}

.no-icon .card-item.icon-card .text-1 {
  margin-left: 5px;
}

.mysalesman-banner {
  transition: all .3s;
}

.failed-validate-message {
  background-color: #772422;
  color: white;
  margin-top: -3px;
  padding-left: 5px;
  font-size: .9rem;
}

.move-event-btn {
  margin-left: 10px;
  margin-right: 10px;
}

.address-check-btn {
  display: flex;
}

.address-check-btn .text-widget {
  background-color: #183eb2;
  color: white;
  border-radius: 8px;
  height: 30px;
  width: 90%;
  margin: auto;
  font-size: .85rem;
  user-select: none;
}

.blue-background-empire {
  background-color: #093dee;
  border-bottom: rgb(47, 47, 47) solid 4px;
}

.move-event-btn .text-widget {
  background-color: #183eb2;
  color: white;
  border-radius: 10px;
  margin: auto;
  width: 75%;
  text-align: center;
  padding: 0px 3px;
}

#grid-filter-x {
  margin: 2px;
  margin-left: -12px;
  height: 20px;
  float: left;
}

#glyphicons-basic-burger:hover path {
  fill: grey;
}

#glyphicons-basic-filter-x:hover path {
  fill: darkred;
}

.move-event-btn .text-widget:hover, .address-check-btn .text-widget:hover {
  background-color: #2156f5;
  cursor: pointer;
}

@keyframes grid-filter-dropdown {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.grid-filter-list {
  min-width: 150px;
  background-color: whitesmoke;
  box-shadow: 3px 3px 8px rgba(100, 100, 100, 0.3);
  border: 1px solid rgb(112, 112, 112);
  padding-left: 5px;
  border-radius: 2px;
  position: absolute;
  z-index: 50;
  margin: 5px;
  padding-bottom: 5px;
  overflow: auto;
  height: 80%;
  animation: grid-filter-dropdown .2s ease forwards;
}

.check-all-icon svg {
  height: 18px;
  width: 18px;
  margin-right: 20px;
  margin-top: 6px;
}

.check-all-icon svg:hover {
  fill: rgb(78, 78, 78);
}

.check-all-text:hover {
  text-decoration: underline;
  cursor: default;
}

.check-all-text {
  user-select: none;
}

.create-base-event-btn {
  margin-left: 10px;
  margin-right: 10px;
  user-select: none;
}

.create-base-event-btn .text-widget {
  background-color: #dd7c0e;
  color: white;
  border-radius: 20px;
}

.create-base-event-btn .text-widget:hover {
  background-color: #f18811;
  cursor: default;
}

.select-schedule-button {
  margin-left: 10px;
  margin-right: 10px;
  user-select: none;
}

.select-schedule-button .text-widget {
  background-color: #226640;
  color: white;
  border-radius: 20px;
}

.select-schedule-button .text-widget:hover {
  background-color: #4E8566;
  cursor: default;
}

.three-line .card-item .text-3 {
  display: block;
  right: unset;
  position: initial;
}

.three-line .card-item * {
  overflow: hidden;
  white-space: nowrap;
}

.three-line .card-item {
  padding: 2px;
  padding-left: 10px;
  min-height: 60px;
  max-height: 60px;
}

.card-item .text-2 {
  font-size: 14px;
  color: #8d8d8d;
}

.top-level-parent-card, .parent-card {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.top-level-parent-card {
  background-color: #444444;
  color: white;
  border: 1px solid #e8ebf1;
}

.cardlist-multi .top-level-parent-card {
  background-color: #f6f6f6;
  color: black;
  outline: 1px solid #444444;
  margin-top: 1px;
  margin-bottom: 2px;
}

.cardlist-multi .card-list {
  overflow-y: scroll;
}

.top-level-parent-card .text-1 {
  font-weight: bold;
}

.parent-card>div, .top-level-parent-card>div {
  margin-top: auto;
  margin-bottom: auto;
}

.parent-card .chevron, .top-level-parent-card .chevron {
  font-size: 30px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -8px;
}

.parent-card.expand .chevron, .top-level-parent-card.expand .chevron {

  margin-left: 2px;
  margin-right: 18px;
}

.top-level-parent-card .chevron {
  transform: rotate(90deg);
}

.parent-card .chevron div {
  transform: rotate(90deg);
}

.parent-card.expand .chevron div {
  transform: rotate(-90deg);
}

.top-level-parent-card.expand .chevron {
  transform: rotate(-90deg);
}

.query-list .card-item.parent-card {
  padding: 0px;
  padding-left: 10px;
}

.query-list .card-item.parent-card .chevron {
  margin: 0;
  margin-left: -10px;
  margin-top: -1px;
  display: flex;
  min-width: 36px;
  max-width: 36px;
}

.query-list .card-item.parent-card .chevron div {
  margin: 0;
  height: 0px;
  width: 0px;
  margin-top: 12px;
  margin-left: 42px;
  text-align: center;
}

.query-list .card-item.parent-card.expand .chevron {
  border: 1px solid #e4e4e4;
  border-left: none;
  border-bottom: none;
  /* border: 1px solid purple; */
}

.query-list .card-item.parent-card.expand .chevron div {
  margin: 0;
  height: 0px;
  width: 0px;
  margin-top: 20px;
  margin-left: -7px;
  text-align: center;
}

.form {
  display: flex;
}

.form .title-label {
  overflow: hidden;
  white-space: nowrap;
}

.form-grid {
  position: relative;
  width: 100%;
  display: grid;
  min-width: 0;
  min-height: 0;
  overflow-y: auto;
  margin: 5px;
  margin-bottom: 0px;
  background-color: #f1f2f2;
}

.form-grid-marg-bot {
  margin-bottom: 20px !important;
}

.center-controls {
  display: flex;
  flex-direction: column;
}

.center-controls .form {
  width: unset;
  height: unset;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.center-controls .form-grid {
  margin: unset;
}

.center-controls .form-grid .form-control {
  margin-bottom: 20px;
}

.no-background .form-grid {
  background-color: white;
}

.form-control select {
  padding: 0;
}

.form-control textarea, .form-control input:not([type='checkbox']) {
  padding-left: 5px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  margin: 0px;
  padding: 0px;
  margin-right: 5px;
}

.form-control textarea {
  resize: none;
  font-family: inherit;
  font-size: inherit;
  padding-top: 2px;
  font-size: 14px;
}

.form-control textarea, .form-control input:not([type='checkbox']), .form-control select {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: 0;
  height: 25px;
}

.form-control textarea:hover, .form-control input:not([type='checkbox']):hover, .form-control select:hover {
  border-color: #FFC233;
}

.form-control textarea:focus, .form-control input:not([type='checkbox']):focus, .form-control select:focus {
  border-color: #FFC233;
  box-shadow: 0 0 0 1px #FFC233;
  -webkit-box-shadow: 0 0 0 1px #FFC233;
  outline: 0;
  outline-offset: -2px;
}

.control-wrapper {
  display: flex;
  min-width: 170px;
}

.form .control-wrapper.AutoFillDropDown {
  overflow: visible;
}

.form .control-wrapper.AutoDropDown {
  overflow: visible;
}

.form .control-wrapper.AutoSearch {
  overflow: visible;
}

.grid-widget .control-wrapper.AutoFillDropDown {
  overflow: visible;
}

.grid-widget .control-wrapper.AutoDropDown {
  overflow: visible;
}

.grid-widget .control-wrapper.AutoSearch {
  overflow: visible;
}

.form-control {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 5px;
  min-width: 0px;
}

.form-control>div {
  margin-top: auto;
  margin-bottom: auto;
}

.control-label {
  padding: 0;
  margin: 0;
  font-size: 10px;
  margin-top: -5px;
  display: flex;
}

.title-label {
  background-color: #e4b859;
  color: white;
  display: flex;
  font-size: 16px;
  padding: 1px;
  font-weight: 500;
}

.title-label>div {
  margin: auto;
}

.button-container {
  display: flex;
}

.button {
  display: flex;
  width: 100px;
  min-width: 100px;
  height: 30px;
  color: white;
  font-size: 15px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  cursor: default;
  user-select: none;
  border-radius: 50px;
}

.button.disabled {
  background-color: darkgrey !important;
}

.rcr-btn.disabled {
  background-color: darkgrey !important;
  pointer-events: none;
}

.button>div {
  margin: auto;
}

.email-dropper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-dropper svg {
  background-color: rgb(249, 242, 255);
  border-radius: 3px;
  border: 2px solid black;
  padding: 2px;
}

.green-button, .flex-btn-bar .btn-bar-btn.green-button {
  background-color: #226640;
  margin-bottom: 15px;
}

.green-button:hover, .flex-btn-bar .btn-bar-btn.green-button:hover {
  background-color: #4E8566;
}

.red-button, .flex-btn-bar .btn-bar-btn.red-button {
  background-color: #963928;
  margin-bottom: 15px;
}

.red-button:hover, .flex-btn-bar .btn-bar-btn.red-button:hover {
  background-color: #b14531;
}

.button.Save {
  background-color: #226640;
}

.recurrenc-icon {
  position: absolute;
}

.button.Save:hover {
  background-color: #4E8566;
}

.button.Copy {
  background-color: blue;
}

.button.Copy:hover {
  background-color: lightblue;
}

.button.Approve {
  background-color: #226640;
}

.button.Approve:hover {
  background-color: #4E8566;
}

.button.Cancel {
  background-color: #dd7c0e;
}

.button.Cancel:hover {
  background-color: #f18811;
}

.button.Delete {
  background-color: #963928;
}

.button.Delete:hover {
  background-color: #b14531;
}

.scheduleDeleter {
  background-color: #963928;
}

.scheduleDeleter:hover {
  background-color: #b14531;
}

.button.Deny {
  background-color: #963928;
}

.button.Deny:hover {
  background-color: #b14531;
}

.button.Unsubmit {
  background-color: #963928;
}

.button.Unsubmit:hover {
  background-color: #b14531;
}

.lock-text-btn .text-widget {
  width: 77%;
  height: 30px;
  margin-top: 5px;
  margin-left: 15px;
  background-color: rgb(206, 1, 1);
  box-shadow: 1px 6px #999;
}

.lock-text-btn .text-widget:active {
  box-shadow: 1px 2px #666;
  transform: translateY(4px);
}

.unlock-text-btn .text-widget:active {
  box-shadow: 1px 1px #666;
  transform: translateY(1px);
}

.lock-text-btn .text-widget:hover, .unlock-text-btn .text-widget:hover {
  background-color: darkred;
}

.active-column {
  background-color: rgb(201, 212, 179);
}

.lock-btn-only-text {
  position: absolute;
}

.locked-payp-text .text-widget {
  font-size: larger;
  font-weight: bold;
}

.unlock-text-btn .text-widget {
  width: 75%;
  height: 30px;
  margin-top: 13px;
  margin-left: 15px;
  background-color: rgb(206, 1, 1);
  box-shadow: 1px 2px #999;
}

.admin-cardlist-lock {
  background-color: blue;
  height: 30px;
  width: 50px;
}

.button.Unapprove {
  background-color: #963928;
}

.button.Unapprove:hover {
  background-color: #b14531;
}

.button.Submit {
  background-color: #183eb2;
}

.button.Submit:hover {
  background-color: #4672f9;
}

.centered-button .button {
  margin: auto;
}

.blue-btn .button, .button.Print, .button.Receipt {
  background-color: #344d6c;
}

.blue-btn .button:hover, .button.Print:hover, .button.Receipt:hover {
  background-color: #44638a;
}

.blue-btn .text-widget {
  background-color: #44638a;
  border: 2px solid #344d6c;
  color: white;
  font-weight: 500;
  border-radius: 10px;
}

.green-btn {
  background-color: #4AB84A;;
  border: 2px solid #2b6b2b;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  height: 32px;
  width: 140px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

.copy-txt-btn {
  margin: auto;
}

.copy-txt-btn .text-widget {
  background-color: #44638a;
  border: 2px solid #344d6c;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  user-select: none;
  padding: 5px 25px;
}

.copy-txt-btn .text-widget:hover {
  background-color: #5176a4;
}

.modal-layer .container-type-Button {
  padding: 2px;
}

.modal-layer .button {
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  margin-left: 5px;
  min-width: unset;
}

.richtextbox-control {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 5px;
}

.richtextbox-control textarea {
  flex: 1;
}

.sub-scene {
  position: relative;
}

.sub-scene-flex {
  display: flex;
  height: 100%;
}

.tab.default {
  position: relative;
  display: flex;
  width: 150px;
  margin-left: 8px;
  border: 1px solid #888b8e;
  border-bottom: none;
  height: 35px;
  margin-top: auto;
  cursor: default;
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px 5px 0px 0px;
  line-height: 35px;
}

.tab.default:not(.active) {
  background-color: #252525;
  color: white;
}

.tab.default.active:before {
  content: '';
  border-top: 5px solid #fdba30;
  width: 100%;
  position: absolute;
  border-radius: 4px 4px 0px 0px;
}

.large-bold-text {
  font-size: 18px;
  font-weight: 500;
}

.right-align-text .text-widget>div {
  margin-left: 10px;
}

.left-align-text .text-widget>div {
  margin-left: 0px;
}

.tab.default div {
  margin: auto;
  margin-bottom: 3px;
}

.auto-size.vertical-tab-card .card-item {
  min-height: unset;
  max-height: unset;
  padding-top: 15px;
  padding-bottom: 15px;
}

.auto-size.vertical-tab-card .card-item.selected:before, .tab.vertical-tab.active:before {
  margin-top: -15px;
}

.vertical-tab-card .card-list {
  overflow: hidden;
}

.vertical-tab-card .card-item {
  min-height: 100px;
  max-height: 100px;
  padding-right: 12px !important;
}

.vertical-tab-card .card-item, .tab.vertical-tab {
  display: flex;
  cursor: pointer;
  position: relative;
  padding-right: 8px;
  border: 1px solid #888b8e;
  border-right: none;
  width: 100%;
  background-color: #252525;
  color: white;
  user-select: none;
  margin-top: 5px;
  margin-left: 5px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: unset;
}

.vertical-tab-card .card-item:hover {
  background-color: #252525 !important;
}

.vertical-tab-card .card-item.selected:hover {
  background-color: white !important;
}

.vertical-tab-card .card-item.selected, .tab.vertical-tab.active {
  background-color: white;
  color: black;
  cursor: default;
}

.vertical-tab-card .card-item.selected:before {
  margin-top: -5px;
}

.vertical-tab-card .card-item.selected:before, .tab.vertical-tab.active:before {
  content: '';
  border-left: 5px solid #fdba30;
  height: 100%;
  position: absolute;
  border-radius: 4px 0px 0px 4px;
}

.vertical-tab-card .card-item div, .tab.vertical-tab div {
  margin: auto;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: .5px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  padding-right: 5px;
  white-space: nowrap;
}

.horizontal-tab-sub-scene {
  padding-right: 5px;
}

.mobile-bottom-tabs {
  padding-right: 5px;
}

.mobile-bottom-tabs .horizontal-menu-tab, .inverted-tabs .horizontal-menu-tab {
  transform: rotate(180deg);
}

.mobile-bottom-tabs .horizontal-menu-tab>div, .inverted-tabs .horizontal-menu-tab>div {
  transform: rotate(180deg);
}

.mobile-card-list .card-item {
  margin-top: 10px;
  min-height: 50px;
  max-height: 50px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  border-radius: 25px;
}

.mobile-card-list .card-item .text-1 {
  margin: auto;
  font-size: 18px;
}

.mobile-create-asset-card-list .card-item {
  margin-top: 10px;
  min-height: 50px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  border-radius: 10px;
}

.mobile-create-asset-card-list .card-item .text-1 {
  
  font-size: 18px;
}

.mobile-confirm-btn .button-confirmation {
  margin-left: 0px;
  padding-left: 0px;
}

.mobile-confirm-btn .confirm-mode .confirm-text {
  margin-top: -5px;
}

.mobile-confirm-btn .confirm-mode .confirm-buttons {
  min-height: 35px;
}

.mobile-confirm-btn .confirm-mode .confirm-buttons .button {
  min-width: 55px !important;
  margin-left: 2px !important;
}

.tab.horizontal-menu-tab {
  position: relative;
  display: flex;
  border: 1px solid #888b8e;
  border-bottom: none;
  cursor: default;
  font-weight: 500;
  font-size: 17px;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.small-tab {
  margin: 0 !important;
  margin-left: 2px !important;
  font-size: 16px !important;
}

.pad-right-tab {
  margin-right: 2px !important;
}

.tab.horizontal-menu-tab:not(.active) {
  background-color: #252525;
  color: white;
}

.tab.horizontal-menu-tab.active:before {
  content: '';
  border-top: 5px solid #fdba30;
  width: 100%;
  position: absolute;
  border-radius: 4px 4px 0px 0px;
}

.tab.horizontal-menu-tab>div {
  margin: auto;
}

.simple-tab .button-container .tab {
  color: black;
  background-color: white;
  border: none;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 22px;
}

.simple-tab .button-container .tab>div {
  margin: auto;
}

.simple-tab .button-container .tab.active {
  font-weight: bold;
  font-size: 28px;
}

.simple-tab .button-container .tab.active:before {
  content: '';
  border-top: none;
}

.device-info {
  display: flex;
  flex-direction: column;
}

.device-info img {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 64px !important;
  width: 64px !important;
  background-color: white !important;
}

.device-info>div {
  margin: auto;
}

.device-info .device-details {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.device-info textarea {
  width: 100%;
}

.homebase-tab {
  width: 100%;
  height: 80%;
  cursor: pointer;
  color: black;
  background-color: #e7e7e7;
  text-align: center;
  font-size: 14;
  border-radius: 25px;
  padding: 4px;
  margin-top: 8px;
  margin-bottom: 5px;
  margin-left: 8px;
}

.homebase-tab:hover {
  color: white;
  background-color: #e4b859;
  font-size: 16;
  font-weight: 500;
}

.old-sidebar-tab {
  width: 100%;
  display: flex;
  box-shadow: #c5c5c5 1px 1px 4px;
  margin: 2px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid transparent;
  color: white;
  background-color: #252525;
  border-radius: 5px;
}

.old-sidebar-tab.active {
  box-shadow: 1px 1px 4px burlywood;
  border: 1px solid #fdba30;
  cursor: initial;
  color: black;
  background-color: white;
  font-weight: bold;
}

.sidebar-tab div {
  margin: auto;
}

.sidebar-tab {
  width: 100%;
  display: flex;
  margin: 2px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid #536042;
  color: #536042;
  border-radius: 5px;
  background-color: white;
}

.sidebar-tab.active {
  cursor: initial;
  color: white;
  background-color: white;
  font-weight: 500;
  background-color: #536042;
}

.text-widget {
  display: flex;
}

.text-widget div {
  margin: auto;
}

.header-text .text-widget {
  color: black;
  background-color: white;
  font-weight: 500;
  font-size: 18px;
  border: 1px solid #fdba30;
  box-shadow: 1px 1px 4px burlywood;
}

.max-size-text .text-widget>div {
  width: 100%;
  height: 100%;
}

.query-grid-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.grid-header {
  display: flex;
  flex-direction: column;
}

.header-cols {
  width: 100%;
  display: flex;
  flex: 0 0 56px;
  min-height: 0;
}

.header-column {
  position: relative;
}

.header-column.dragging {
  z-index: 10;
  pointer-events: none;
}

.header-column.dragging .draggable {
  position: absolute;
}

.header-column .draggable {
  position: absolute;
  width: 100%;
}

.header-column.sorted .column-label, .header-column.sorted-desc .column-label {
  background-color: #314e79;
}

.header-column .triangle {
  transition: .2s ease-in-out;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
  border-color: transparent transparent transparent white !important;
}

.header-column.sorted .triangle {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg)
}

.query-grid {
  display: flex;
  min-height: 0;
  flex: 1;
  width: 100%;
  border-left: 3px solid #c2ccd4;
  position: relative;
  flex-direction: column;
}

.query-grid.child-grid {
  position: unset;
}

.group-by {
  background: rgb(116, 116, 116);
  background: linear-gradient(0deg, rgba(138, 138, 138, 1) 0%, rgba(128, 127, 127, 1) 20%, rgba(203, 203, 203, 1) 100%);
  margin-bottom: 1px;
  transition: height 0.15s ease-out;
  width: 100%;
  min-height: 0px;
  flex: 0 15px;
}

.group-by.expand {
  flex: 0 30px;
  transition: flex 0.15s ease-out;
}

.group-by .clear-group-by {
  fill: white;
}

.group-by .clear-group-by:hover svg {
  fill: #383838;
}

.group-by .clear-group-by svg {
  margin-top: 4px;
  height: 20px;
}

.map-list {
  margin-top: 5px;
}

.map-list, .query-list {
  border-top: 1px solid #dce2e8;
}

.column-label {
  height: 30px;
  color: white;
  background-color: black;
  border-right: 1px solid #dce2e8;
  display: flex;
  position: relative;
}

.column-label .label-text {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}

.column-label.blade:before, .column-label.blade:after {
  content: '';
  border-style: solid;
  position: absolute;
  border-width: 12px 6px 0px 6px;
  border-color: #62c3f0 transparent transparent transparent;
  z-index: 100;
}

.column-label.blade:before {
  transform: rotate(180deg);
}

.column-label.blade-left:before {
  bottom: -6px;
  left: -6px;
}

.column-label.blade-left:after {
  top: -6px;
  left: -6px;
}

.column-label.blade-right:before {
  bottom: -6px;
  right: -6px;
}

.column-label.blade-right:after {
  top: -6px;
  right: -6px;
}

.query-grid .resize-handle {
  width: 14px;
  height: 100%;
  position: absolute;
  right: -7px;
  cursor: e-resize;
  z-index: 1;
}

.header-filter input:not([type='checkbox']) {
  width: 100%;
  outline: none;
  padding-left: 2px;
  height: 25px;
  border: none;
}

.header-filter {
  border-right: 1px solid #dce2e8;
  border-bottom: 1px solid #dce2e8;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
}

.header-filter .flex-box>div {
  display: flex;
  width: 100%;
}

.lookup-fields-container {
  height: 25px;
}

.type-filter {
  display: flex;
  background-color: white;
  flex: 1;
}

.type-filter>.flex-box {
  flex: 1;
}

.filter-mode-box {
  color: teal;
  border: 1px solid teal;
  background-color: #a9f5f5;
  height: 21px;
  flex: 0 0 21px;
  display: flex;
  margin: 2px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  z-index: 3;
}

.filter-mode-box>div {
  margin: auto;
  margin-top: -1px;
}

.filter-mode-select {
  position: absolute;
  top: 25px;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid teal;
  border-radius: 5px;
  min-width: 170px;
}

.filter-mode-select>div {
  cursor: default;
  padding: 5px;
  display: flex;
}

.filter-mode-select>div:first-child {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.filter-mode-select>div:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.filter-mode-select>div:hover {
  background-color: #dfffff;
}

.filter-mode-select>div.active {
  color: teal;
  border-right: none;
  border-left: none;
  background-color: #a9f5f5;
  font-weight: 500;
}

.filter-mode-val {
  margin-left: 5px;
  flex: 0 0 25px;
  border: 1px solid teal;
  color: teal;
  font-weight: 500;
  background-color: #a9f5f5;
  margin: 2px;
  border-radius: 3px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.filter-mode-val>div {
  margin: auto;
  margin-top: -2px;
}

.filter-mode-label {
  flex: 1;
  margin: auto;
  margin-left: 5px;
}

.filter-done, .filter-clear {
  display: flex;
  z-index: 3;
}

.filter-clear {
  margin-right: 2px;
}

.filter-done svg, .filter-clear svg {
  height: 20px;
  width: 20px;
  margin: auto;
  fill: #565656;
}

.filter-done svg:hover {
  fill: green;
}

.filter-clear svg:hover {
  fill: red;
}

.type-filter .two-value input:first-of-type {
  padding-left: 5px;
}

.type-filter .two-value input {
  text-align: center;
}

.double-date-filter {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 25px;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #888b8e;
  min-width: 170px;
  padding: 10px;
  border-radius: 5px;
}

.date-display {
  display: flex;
  color: #a7a7a7;
  position: absolute;
  height: 100%;
}

.date-display>div {
  margin: auto;
}

.double-date-filter input {
  width: unset;
  border-bottom: 1px solid #dce2e8;
}

.double-date-filter input:first-of-type {
  margin-bottom: 10px;
}

.bool-helper-text {
  height: 25px;
  display: flex;
  width: 100%;
  color: #757575;
}

.bool-helper-text>div {
  margin: auto;
}

.bool-options-dropdown, .lookup-filter-container {
  display: flex;
  position: absolute;
  top: 25px;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #888b8e;
  min-width: 170px;
  padding: 10px;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  max-height: 200px;
}

.bool-option {
  display: flex;
  padding: 5px;
  padding-bottom: 0;
  cursor: default;
}

.bool-option * {
  margin-top: auto;
  margin-bottom: auto;
}

.bool-option input {
  margin-right: 10px;
}

.lookup-filter-container .scrollbox {
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.lookup-filter-option {
  display: flex;
  min-height: 25px;
  cursor: default;
}

.lookup-filter-option input {
  height: 13px;
  margin: auto;
  margin-top: 7px;
  flex: 0 0 13px;
}

.lookup-filter-option .lookup-filter-label {
  margin: auto;
  margin-left: 10px;
  flex: 1;
}

.filter-helper-text {
  display: flex;
  color: #757575;
  position: absolute;
  width: 100%;
}

.filter-helper-text>div {
  width: 100%;
  text-align: center;
  margin-top: 2px;
}

.column-picker {
  min-height: 56px;
  min-width: 40px;
  display: flex;
  background-color: #eeeeee;
}

.column-picker-btn {
  cursor: pointer;
  margin: auto;
  user-select: none;
  padding: 4px;
}

.column-selection-box {
  position: absolute;
  width: 200px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 30px;
  margin-left: 25px;
  border: 1px solid #c2ccd4;
  background-color: white;
  box-shadow: 2px 2px 7px #c2ccd4;
  z-index: 11;
}

.column-selection-item {
  display: flex;
  margin-left: 5px;
  cursor: default;
  padding-top: 5px;
}

.column-selection-item * {
  margin-top: auto;
  margin-bottom: auto;
}

.column-selection-item.all-props {
  border-bottom: 2px solid #a9a9a9;
  margin-right: 2px;
}

.selection-item-label {
  margin-left: 5px;
}

.row-numbers {
  display: grid;
  width: 40px;
  grid-auto-rows: 30px;
  height: 100%;
  overflow: hidden;
}

.row-num {
  display: flex;
  border-right: 3px solid #eeeeee;
  font-weight: 500;
  font-size: 11px;
}

.row-num div {
  margin: auto;
}

.row-num:hover {
  color: #0070ca;
  cursor: default;
}

.scroll-content {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
}

.col-pin {
  margin-left: auto;
  display: flex;
  height: 100%;
  width: 25px;
  cursor: pointer;
  margin-right: 10px;
}

.col-isDeleted {
  z-index: 3;
}

.grid-row-blocker {
  pointer-events: none;
}

.delete-editable .grid-row-blocker {
  pointer-events: all;
}

.col-pin svg {
  fill: white;
  height: 15px;
  width: 13px;
  transform: rotate(90deg);
  margin: auto;
}

.col-pin.pinned svg {
  transform: none;
}

.static-content {
  display: flex;
  flex-direction: column;
}

.static-grid {
  flex: 1;
  min-height: 0;
  display: flex;
}

.static-bumper {
  height: 20px;
}

.pinned-grid {
  display: flex;
}

.pinned-grid .header-cols {
  border-right: 2px solid #a7acb1;
}

.pinned-grid .grid-body {
  border-right: 2px solid #a7acb1;
}

.pinned-grid .group-by {
  display: none !important;
}

.grid-sidebar {
  display: flex;
  flex-direction: column;
}

.static-bumper {
  border-top: 2px solid #a7acb1;
}

.hover-replace {
  color: orange;
  background-color: purple;
  font-weight: bold;
}

.title-bar {
  width: 100%;
  min-height: 0;
  flex: 0 0 35px;
  display: flex;
}

.title-bar .grid-label {
  margin: auto;
  margin-left: 10px;
  color: #0070ca;
  font-weight: bold;
}

.title-bar .grid-rowcount {
  margin: auto;
  margin-right: 20px;
  font-weight: bold;
}

.scroll-content .grid-header-padding {
  min-width: 20px;
  min-height: 20px;
}

.grid-body {
  display: flex;
  flex-direction: column;
  min-height: 0px;
  flex: 1;
  position: relative;
}

.grid-column {
  display: flex;
  flex-direction: column;
}

.pinned-grid-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.grid-cell {
  min-height: 30px;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  border-right: 1px solid #d0d5da;
}

.grid-cell span {
  margin: auto;
  margin-left: 5px;
}

.scrollbar-container {
  min-width: 20px;
  background-color: #f1f1f1;
  border-left: 1px solid #c1c1c1;
  position: absolute;
  right: 0;
  top: 72px;
  bottom: 17px;
  display: flex;
  padding: 2px;
}

.query-grid.grouped .scrollbar-container {
  margin-top: 11px;
}

.grid-scrollbar {
  min-height: 70px;
  width: 100%;
  position: relative;
  background-color: #c1c1c1;
}

.grid-row {
  display: flex;
}

.grid-row.even .grid-cell {
  background-color: #f2f5f7;
}

.grid-row.even .grid-cell.filtered {
  background-color: #ccebda;
}

.grid-row.odd .grid-cell.filtered {
  background-color: #d9f0e3;
}

.grid-row.last-pin .grid-cell {
  border-bottom: 2px solid #a7acb1;
}

.grid-row.highlight .grid-cell {
  background-color: #fdd178 !important;
}

.legend-control {
  top: .5em;
  right: .5em;
  background-color: unset !important;
  max-height: 90%;
}

.legend-control svg {
  fill: rgba(256, 256, 256, .5);
  width: 40px;
  height: 40px;
}

.legend-control path.outline-path {
  fill: rgba(255, 255, 255, .75);
}

.legend-control path {
  fill: rgba(0, 60, 136, .70);
}

.legend-control:hover path {
  fill: rgba(0, 60, 136, .85);
}

.legend-control:hover path.outline-path {
  fill: rgba(255, 255, 255, .85);
}

.legend-control.open {
  background-color: white !important;
  border-radius: 4px;
  padding: 10px;
  min-width: 325px;
  max-width: 325px;
  overflow: auto;
}

.map-legend-item {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  font-size: 24px;
}

.legend-item-name {
  padding-right: 5px;
}

.legend-item-options {
  display: flex;
}

.legend-item-options * {
  margin-top: auto;
  margin-bottom: auto;
}

.legend-item-options input {
  margin-right: 5px;
}

.legend-item-children {
  margin-left: 15px;
}

.expand-button {
  padding: 2px;
  width: 15px;
  height: 15px;
  display: flex;
  margin-right: 3px;
}

.expand-button:hover div {
  transition: .1s ease-in-out;
  border-color: #579bc3 transparent transparent transparent;
}

.expand-button div.open {
  border-color: #579bc3 transparent transparent transparent;
}

.expand-button div {
  margin: auto;
  border-style: solid;
  border-width: 6px 4px 0px 4px;
  border-color: #424242 transparent transparent transparent;
  margin-right: 5px;
  transform: rotate(-90deg);
}

.expand-button div.open {
  transform: none;
}

.child-spacer {
  min-width: 18px;
}

.zoom-monitor {
  bottom: .5em;
  left: .5em;
  border-radius: 4px;
  padding: 2px;
  background-color: rgba(255, 255, 255, .4);
}

.zoom-monitor div {
  background-color: rgba(0, 60, 136, .5);
  font-weight: 700;
  color: #fff;
  padding: 2px;
  margin: 1px;
}

.document-widget {
  display: flex;
  flex-direction: column;
  min-height: 0px;
}

.document-items {
  flex: 1;
  padding: 5px;
  overflow: auto;
}

.new-document {
  flex: 0 0 40px;
  display: flex;
}

.new-document input {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.document-item {
  display: flex;
  min-width: 0px;
  min-height: 45px;
  max-height: 45px;
}

.document-item.odd {
  background-color: #f1f2f2;
}

.document-item .doc-btn {
  height: 30px;
  margin-top: 7px;
}

.doc-btn {
  display: flex;
  margin: auto;
  flex: 1;
  max-width: 90px;
  margin: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 500;
  background-color: #b8b8b8;
  cursor: default;
}

.doc-btn * {
  margin: auto;
}

.doc-btn.highlight {
  background-color: #fdba30;
}

.doc-btn.doc-import {
  margin-left: auto;
  min-width: 110px;
}

.doc-saving-spinner {
  height: 20px;
  color: white;
  fill: white;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.doc-saving-spinner svg {
  height: 20px;
  margin-left: -5px;
}

.doc-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
  margin: auto;
}

.doc-details .top-detail {
  display: flex;
  min-width: 0px;
  font-weight: 500;
}

.doc-details .doc-date {
  flex: 0 0 200px;
}

.doc-details .doc-name {
  flex: 1;
}

.doc-details .bottom-detail {
  display: flex;
}

.new-doc {
  display: flex;
  width: 150px;
  height: 30px;
  color: white;
  font-size: 15px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  cursor: default;
  user-select: none;
  background-color: #226640;
  border-radius: 50px;
  padding-right: 10px;
  padding-left: 10px;
}

.single-file-container .preview-box {
  border: none;
}

.single-file-container.doc-edit-container {
  background-color: black;
  padding: 5px;
}

.new-doc * {
  margin: auto;
}

.new-doc.disabled {
  background-color: darkgrey;
}

.new-doc:not(.disabled):hover {
  background-color: #4E8566;
}

.doc-edit-container {
  display: flex;
  flex-direction: column;
  min-height: 0px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: black;
}

.doc-edit {
  display: flex;
  min-width: 0px;
  min-height: 200px;
  max-height: 200px;
  background-color: white;
}

.doc-edit .left-pane {
  display: flex;
  flex-direction: column;
  min-height: 0px;
  flex: 1;
  margin-right: 5px;
  position: relative;
}

.doc-edit .left-pane .button-bar {
  display: flex;
  min-width: 0px;
  flex: 0 0 40px;
  background-color: #3f3f3f;
}

.doc-edit .left-pane .attach-preview {
  display: flex;
  min-width: 0px;
  flex: 1;
  padding: 5px;
  padding-right: 0px;
}

.hyperlink-preview {
  position: absolute;
  bottom: 5px;
  left: 5px;
  height: 35px;
  width: 40%;
  padding-right: 5px;
  color: white;
  font-weight: 500;

}

.hyperlink-preview .create-hyperlink {
  width: 100%;
  height: 100%;
  border-top: 2px solid black;
  background-color: #205867;
  display: flex;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
}

.hyperlink-preview .create-hyperlink:hover {
  text-decoration: underline;
}

.hyperlink-preview .create-hyperlink div {
  margin: auto;
}

.hyperlink-box * {
  cursor: unset !important;
}

.hyperlink-box {
  display: flex;
  flex-direction: column;
  cursor: unset !important;
}

.hyperlink-box .hyperlink-label {
  margin: auto;
  margin-bottom: 0px;
  margin-left: 15px;
  font-size: 12px;
  font-weight: bold;
}

.hyperlink-box input {
  margin: auto;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;
}

.hyperlink-box {
  width: 100%;
  height: 100%;
}

.min-width-300 {
  min-width: 300px;
}

.doc-edit .left-pane .attach-preview.preview-mode {
  position: relative;
}

.doc-edit .left-pane .attach-preview.preview-mode .trash-photo {
  width: 40px;
  height: 40px;
  top: 0px;
  right: 0px;
  left: unset;
}

.doc-edit .left-pane .attach-preview.preview-mode .trash-photo>div {
  background-color: #dcdcdcbf;
  cursor: pointer;
}

.doc-edit .left-pane .attach-preview.preview-mode .trash-photo>div:hover {
  background-color: #e4e4e4bf;
}

.attach-preview .add-attachment {
  display: flex;
  flex-direction: column;
  flex: 2;
  font-weight: 500;
  color: white;
  background-color: #205867;
  text-align: center;
  padding-bottom: 20px;
}

.attach-preview .add-attachment.attached {
  background-color: #ff9933;
  padding-bottom: 0px;
}

.attach-preview .add-attachment>* {
  margin: auto;
}

.hyperlink-link {
  flex: 2 1;
  display: flex;
  background-color: #ff9933;
  text-align: center;
}

.hyperlink-link a {
  color: white;
  font-weight: bold;
  text-decoration: none;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.preview-box {
  display: flex;
  flex: 3;
  border: 1px solid darkgrey;
  margin-left: 5px;
}

.preview-box>* {
  margin: auto;
}

.doc-edit .preview-mode .add-attachment {
  display: none;
}

.doc-edit .attach-mode .preview-box {
  display: none;
}

.doc-edit .attach-mode .preview-box.display {
  display: block;
}

.doc-edit .attach-mode .trash-photo {
  display: none;
}

.single-file-container .doc-edit .left-pane {
  margin: 0px;
}

.single-file-container .doc-edit .left-pane .attach-preview {
  padding: 0px;
  max-height: 100%;
}

.single-file-container .doc-edit .left-pane .attach-preview .preview-box {
  padding: 5px;
  margin: 0px;
}

.single-file-container .doc-edit .left-pane .attach-preview .preview-box img {
  height: 100%;
}

#filter-filter-text {
  font-size: 12px;
  width: 165px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin: 5px 0px 5px 3px;
  transition: all 0.30s ease-in-out;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  border: 1px solid #DDDDDD;
}

#filter-filter-text:hover, #filter-filter-text:focus {
  box-shadow: 0 0 4px rgb(11, 133, 182);
  outline: none;
}

.filter-filter-submit {
  font-size: 12px;
  padding: 0px 5px 0px 5px;
  margin-top: 5px;
  margin-left: 2px;
}

.doc-edit .right-pane {
  display: flex;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
  flex: 2;
}

.doc-edit .right-pane .display-name {
  display: flex;
  font-weight: 500;
  height: 15px;
  margin-top: 1px;
}

.doc-edit .right-pane .display-name div {
  margin: auto;
}

.doc-edit .right-pane .user-date {
  display: flex;
  min-width: 0px;
  flex: 0 0 50px;
  padding-left: 5px;
}

.user-date .doc-date {
  margin-top: auto;
  margin-bottom: auto;
  min-width: 140px !important;
}

.user-date .doc-user {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  min-width: 0px;
  display: flex;
}

.doc-edit .right-pane .doc-comment {
  display: flex;
  flex: 1;
  padding: 5px;
}

.doc-edit .right-pane .doc-comment textarea {
  flex: 1;
  resize: none;
  padding: 6px;
  font-size: 15px;
  outline: 0;
  border-radius: 4px;
  font-family: "Source Sans Pro", "Segoe UI", Arial, Tahoma;
}

.attach-save-cancel {
  display: flex;
  flex: 0 0 40px;
  margin-left: 20px;
}

.attach-save-cancel .doc-btn {
  color: white;
  background-color: darkgrey;
}

.attach-save-cancel .doc-save:not(.disabled) {
  background-color: #226640;
}

.attach-save-cancel .doc-save:not(.disabled):hover {
  background-color: #4E8566;
}

.attach-save-cancel .doc-cancel:not(.disabled) {
  background-color: #FDC134;
}

.attach-save-cancel .doc-cancel:not(.disabled):hover {
  background-color: #FDCD5D;
}

.attach-save-cancel .doc-delete:not(.disabled) {
  background-color: #B22418;
}

.attach-save-cancel .doc-delete:not(.disabled):hover {
  background-color: #F9465A;
}

.attach-save-cancel .doc-import:not(.disabled) {
  background-color: #183eb2;
}

.attach-save-cancel .doc-import:not(.disabled):hover {
  background-color: #4672f9;
}

.user-date input, .doc-user select {
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: 0;
  font-size: 15px;
  padding: 4px;
  font-weight: bold;
  height: 30px;
  min-width: 0px;
  margin-right: 5px;
}

.excel-import-load-slider {
  width: 90px;
  height: 80%;
  margin-top: 3px;
  margin-left: 2px;
  background-color: rgb(8, 230, 27);
  z-index: 5;
  animation-name: excel-import-slider;
  animation-duration: 3s;
  color: black;
}

@keyframes excel-import-slider {
  from {
    width: 0px;
  }

  to {
    width: 80px;
  }
}

.excel-import-load {
  display: none;
  position: relative;
  left: 40px;
  width: 100px;
  height: 80%;
  margin-top: 5px;
  background-color: rgb(230, 230, 230);
  border-radius: 3px;
  font-weight: bold;
  user-select: none;
}

.excel-import-load:hover {
  cursor: default;
}

.excel-import-load-slider.excel-import-failed {
  background-color: red;
}

.user-date input:hover, .doc-user select:hover, .doc-comment textarea:hover {
  border-color: #FFC233;
}

.user-date input:focus, .doc-user select:focus, .doc-comment textarea:focus {
  border-color: #FFC233;
  box-shadow: 0 0 0 1px #FFC233;
  -webkit-box-shadow: 0 0 0 1px #FFC233;
  outline: 0;
  outline-offset: -2px;
}

.doc-user.invalid select {
  border-color: red;
}

.doc-date.invalid {
  border-color: red;
}

.preview-box * {
  cursor: pointer;
}

.add-attachment {
  cursor: pointer;
}

.add-attachment:not(.attached):hover {
  text-decoration: underline;
}

.event-grid-widget {
  background-color: #f1f2f2;
  margin: 5px;
  display: flex;
}

.event-grid {
  display: flex;
  flex-direction: column;
  min-height: 0px;
  width: 100%;
  margin-right: 5px;
  padding-right: 5px;
}

.event-grid-title {
  display: flex;
  background-color: black;
  color: white;
  font-weight: 500;
  min-height: 25px;
  position: relative;
}

.event-grid-title>div {
  margin: auto;
}

.add-event-button {
  position: absolute;
  border-radius: 20px;
  height: 30px;
  width: 100px;
  background-color: #03ccbe;
  right: 15px;
  top: 10px;
  display: flex;
  font-weight: normal;
  cursor: default;
}

.add-event-button:hover {
  background-color: #05dacb;
}

.add-event-button>div {
  margin: auto;
}

.event-grid-header {
  display: flex;
  flex: 0 0 35px;
  padding-right: 17px;
  background-color: #f1f2f2;
  border-bottom: 1px solid #dce2e8;
  border-left: 1px solid #dce2e8;
  border-right: 1px solid #dce2e8;
}

.event-grid-body {
  flex: 1;
  border-bottom: 1px solid #dce2e8;
  margin-bottom: 5px;
}

.event-header-cell {
  display: flex;
  flex: 1;
  color: black;
  font-weight: 500;
  background-color: #f1f2f2;
  white-space: nowrap;
  overflow: hidden;
}

.event-header-cell:not(:last-of-type) {
  border-right: 1px solid #dce2e8;
}

.event-header-cell * {
  margin: auto;
  margin-left: 5px;
}

.event-grid-body {
  overflow: auto;
}

.event-grid-row {
  display: flex;
  background-color: white;
  height: 30px;
  border-left: 1px solid #dce2e8;
}

.event-grid-row.odd {
  background-color: #f1f2f2;
}

.event-grid-cell {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
}

.event-grid-cell:not(:last-of-type) {
  border-right: 1px solid #dce2e8;
}

.event-grid-cell * {
  margin: auto;
  margin-left: 5px;
}

.event-dialog {
  position: relative;
}

.close-x {
  position: absolute;
  right: 0;
  top: 2px;
  line-height: 35px;
  font-size: 35px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.modal-layer .form {
  border-top: none;
}

.modal-layer .form-grid {
  background-color: white;
}

.modal-layer .title-label {
  background-color: #e4b859;
}

.event-header-text .text-widget>div, .event-header-text .text-widget {
  width: 100%;
}

.event-header-text .text-widget * {
  white-space: nowrap;
}

.event-header-text .text-widget {
  margin-top: 8px;
}

.event-header-text .primary-text {
  font-weight: bold;
  font-size: 30px;
  margin-top: -20px;
}

.event-header-text .secondary-text {
  font-weight: bold;
  font-size: 25px;
  color: darkgray;
  margin-top: -10px;
}

.header-text-border .text-widget {
  border-right: 3px solid black;
}

.resources-grid-container {
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.resource-grid {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 20px;
}

.resource-add-row {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 13px;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: default;
  background-color: white;
  opacity: .7;
  user-select: none;
}

.resource-add-row div {
  color: black;
}

.resource-add-row:hover {
  opacity: .85;
}

.resource-grid .control-label {
  display: none;
}

.resource-body {
  border-bottom: 1px solid #dce2e8;
}

.resource-title {
  min-height: 27px;
  display: flex;
  color: white;
  font-weight: 500;
  position: relative;
}

.resource-title>div {
  margin: auto;
}

.resource-header {
  display: flex;
  font-weight: 500;
  background-color: #f1f2f2;
  min-height: 30px;
  border-left: 1px solid #dce2e8;
}

.resource-header .resource-cell {
  border-right: 1px solid #dce2e8;
  border-bottom: 1px solid #dce2e8;
  display: flex;
  padding-left: 5px;
}

.resource-header .resource-cell>div {
  margin-top: auto;
  margin-bottom: auto;
}

.resource-header .resource-cell:first-of-type {
  padding-left: 0px;
}

.resource-header .resource-cell:first-of-type>div {
  margin: auto;
}

.resource-row {
  display: flex;
  height: 30px;
  border-left: 1px solid #dce2e8;
  border-right: 1px solid #dce2e8;
}

.resources-grid-container .resource-Time .resource-dirty {
  background-color: #7b354d30;
}

.resources-grid-container .resource-Time .resource-dirty.even {
  background-color: #7b354d50;
}

.resources-grid-container .resource-Material .resource-dirty {
  background-color: #3f463630;
}

.resources-grid-container .resource-Material .resource-dirty.even {
  background-color: #53614250;
}

.resources-grid-container .resource-Equipment .resource-dirty {
  background-color: #344d6c30;
}

.resources-grid-container .resource-Equipment .resource-dirty.even {
  background-color: #344d6c50;
}

.resource-row.even {
  background-color: #f1f2f2;
}

.resource-row .resource-cell {
  padding-left: 5px;
  border-right: 1px solid #dce2e8;
  display: flex;
  overflow: hidden;
}

.resource-row .resource-cell:last-of-type {
  border-right: none;
}

.resource-row .resource-cell:first-of-type {
  padding-left: 0px;
}

.resource-row .resource-cell:first-of-type div {
  margin: auto;
}

.resource-row .resource-cell div {
  margin-bottom: auto;
  margin-top: auto;
  white-space: nowrap;
}

.resources {
  display: flex;
}

.resources-grid-container {
  flex: 1;
}

.entry {
  display: flex;
  flex: 0 0 250px;
  margin-left: 20px;
  margin-top: 5px;
}

.resource-entry {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 10px;
}

.resources .resource-entry {
  margin: 0;
  width: 100%;
}

.entry-label {
  display: flex;
  height: 25px;
  color: white;
}

.entry-label>div {
  margin: auto;
}

.resource-entry .form-control {
  margin-top: 10px;
}

.resource-entry .form-control textarea {
  min-height: 100px;
}

.form-control input:not([type='checkbox']) {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.entry-button-container {
  margin-top: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.entry-button-container .entry-button {
  border-radius: 100px;
  display: flex;
  height: 35px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: default;
  user-select: none;
}

.entry-button-container .entry-button>div {
  margin: auto;
}

.entry-button-container .entry-button:hover {
  color: white !important;
}

.entry-button-container .entry-button.entry-add {
  border: 3px solid #344d6c;
  color: #344d6c;
}

.entry-button-container .entry-button.entry-add:hover {
  background-color: #344d6c;
}

.entry-button-container .entry-button.entry-clear {
  border: 3px solid #dd7c0e;
  color: #dd7c0e;
}

.entry-button-container .entry-button.entry-clear:hover {
  background-color: #dd7c0e;
}

.entry-button-container .entry-button.entry-remove {
  border: 3px solid #963928;
  color: #963928;
}

.entry-button-container .entry-button.entry-remove:hover {
  background-color: #963928;
}

.resource-row.active {
  background-color: #fdd178 !important;
}

.resource-row.active .resource-cell {
  border: none;
}

.site-header {
  display: flex;
}

.time-ticker {
  margin-right: auto;
  margin-left: 15px;
  font-size: 18px;
  display: flex;
  font-weight: 500;
}

.time-ticker .date-text {
  margin-right: 15px;
}

.time-ticker .date-text, .time-ticker .time-text {
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 1px;
}

.weather-bar {
  color: #fdba30;
  background-color: black;
  display: flex;
  position: relative;
}

.weather {
  margin: auto;
  display: flex;
}

.weather-temp {
  font-weight: 500;
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.weather-label {
  font-size: 16px;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.weather-label .forecast {
  white-space: nowrap;
  overflow: hidden;
  max-width: 80%;
}

.weather-label svg {
  fill: #fdba30;
  height: 22px;
  width: 22px;
}

.wind-speed {
  display: flex;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
}

.wind-speed>div {
  margin-left: 5px;
}

.user-info, .info-box {
  display: flex;
  background-color: black;
  color: #fdba30;
  height: 100%;
  z-index: 10;
}

.user-button-bar {
  display: flex;
  margin-left: auto;
  margin-right: 10px;
  flex: 1;
  min-height: 0px;
}

.user-button-bar .user-button {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
  width: 28px;
  height: 28px;
  border-radius: 100px;
  border: 2px solid #fdba30;
  display: flex;
  cursor: pointer;
}

.user-button-bar .user-button>div {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  line-height: 20px;
  font-size: 24px;
  font-weight: 500;
}

.current-user {
  flex: 0 0 170px;
  display: flex;
  min-height: 0px;
}

.current-user .user-label {
  margin: auto;
  display: flex;
}

.mobile-info .current-user .user-label {
  margin: 5px auto auto 20px;
  font-size: 20px;
  color: white;
  font-weight: 500;
  letter-spacing: .3px;
}

.mobile-info .triangle {
  border-color: transparent transparent transparent white;
  margin-top: 11px;
}

.mobile-info .dropdown-menu {
  margin-left: -325px;
  font-size: 18px;
  margin-top: 45px;
  font-weight: bold;
  width: 250px;
}

.mobile-info .dropdown-caret-wrapper {
  margin-top: 35px;
  margin-left: -315px;
}

.user-name {
  cursor: pointer;
}

.user-name:hover {
  text-decoration: underline;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 6px;
  border-color: transparent transparent transparent #fdba30;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-top: 8px;
}


.top-left-radius .text-widget {
  position: relative;
}

.top-left-radius .text-widget:before {
  content: '';
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: black;
}

.top-left-radius .text-widget:after {
  content: '';
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: white;
  border-top-left-radius: 15px;
}

.header-text.top-left-radius .text-widget:after {
  border-left: 1px solid #fdba30;
  border-top: 1px solid #fdba30;
  width: 21px;
  height: 21px;
  margin-left: -1px;
  margin-top: -1px;
}

.header-text.top-left-radius .text-widget:before {
  margin-left: -1px;
  margin-top: -1px;
  border: 1px solid transparent;
}

.image-widget {
  display: flex;
  flex: 1;
}

.image-container {
  flex: 1;
  min-width: 0px;
  min-height: 0px;
  display: flex;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.stretch .image-container img {
  max-height: unset;
  margin: unset;
}

.click-through {
  pointer-events: none;
}

.dark-background {
  background-color: black;
}

.site-logo img {
  width: 45px !important;
  height: 45px !important;
}

.widget-selector {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

.widget-selector .focus-item {
  flex: 1;
  min-height: 0px;
}

.widget-selector .widget-selector-items {
  flex: 0 0 150px;
  display: flex;
  min-height: 0px;
}

.flex-fit-items .widget-selector-items {
  flex: 2;
}

.flex-fit-items .focus-item {
  flex: 5;
}

.widget-selector-items .selector-item {
  flex: 1;
  min-width: 0px;
  box-shadow: #949494 2px 2px 5px;
  background-color: white;
  margin: 5px;
  margin-right: 0px;
  border: 1px solid #c0c2c7;
  padding: 5px;
  overflow: hidden;
}

.widget-selector-items .selector-item:first-of-type {
  margin-left: 0px;
}

.widget-selector-items .widget-loader {
  pointer-events: none;
}

.widget-selector-items .advanced-btn {
  display: none !important;
}

.widget-selector-items .ol-control {
  display: none !important;
}

.focus-item {
  border: 1px solid #c0c2c7;
  box-shadow: #949494 2px 2px 5px;
  margin-bottom: 3px;
  padding: 5px;
}

.focus-item .widget-loader {
  width: 100%;
  height: 100%;
}

.selector-item .widget-loader {
  width: 100%;
  height: 100%;
}

.widget-selector .image-container img {
  height: 100%;
  width: auto;
  background-color: black;
}

.height-fix .image-container img {
  height: 100%;
  width: auto;
}

.mobile-event-header .text-widget>div {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-right: auto;
  margin-left: 10px;
  width: 100%;
}

.mobile-event-header .primary-text {
  color: black;
  font-weight: bold;
  font-size: 23px;
  margin: 0;
  white-space: nowrap;
}

.mobile-event-header .secondary-text {
  color: darkgray;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  margin-top: -5px;
  white-space: nowrap;
}

.resource-display {
  display: flex;
  flex-direction: column;
  background-color: #e8ecf2;
  height: 100%;
  overflow: auto;
  padding-bottom: 150px;
}

.resource-card {
  display: flex;
  flex-direction: column;
  flex: 0 0 90px;
  margin: 10px;
  margin-bottom: 0px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: #c5c5c5 1px 1px 4px;
  background-color: white;
  border-radius: 4px;
}

.resource-card .top-line {
  display: flex;
  color: #868686;
  margin-top: auto;
  margin-bottom: auto;
}

.resource-card .middle-line>div:first-of-type, .resource-card .top-line>div:first-of-type {
  margin-right: auto;
}

.resource-card .middle-line>div:last-of-type, .resource-card .top-line>div:last-of-type {
  margin-left: auto;
}

.resource-card .middle-line {
  display: flex;
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.resource-card .bottom-line {
  display: flex;
  color: #868686;
  margin-top: auto;
  margin-bottom: auto;
}

.resource-duration, .resource-qty {
  display: flex;
}

.resource-qty>div:last-of-type, .resource-duration>div:last-of-type {
  margin-left: 5px;
  font-weight: normal;
  font-size: 14px;
  margin-top: auto;
  margin-bottom: 2px;
}

.map-tab-map .legend-control {
  margin-top: 40px;
}

.mysales-map .legend-control {
  display: none;
}

.polygon-btn .button-container .button {
  display: block;
}

.polygon-btn-disabl .button-container .button {
  display: block;
  color: #979797;
}

.goo-goo-bar img {
  padding: 5px;
}

.goo-goo-bar img:hover {
  padding: 3px;
}

.mobile-asset-label .text-widget {
  display: flex;
  border-bottom: 1px solid #dce2e8;
  margin-right: 5px;
  margin-left: 5px;
  width: unset !important;
}

.mobile-asset-label .text-widget>div {
  margin-right: auto;
  margin-left: 10px;
}

.mobile-asset-label .primary-text {
  font-size: 25px;
  font-weight: bold;
  margin-top: -5px;
}

.mobile-asset-label .secondary-text {
  font-weight: 500;
  font-size: 20px;
  color: darkgray;
  margin-top: -5px;
}

.modal-frame {
  display: flex;
  background-color: black;
  color: white;
}

.modal-title {
  font-weight: 500;
  font-size: 18px;
  margin: auto;
  margin-left: 10px;
}

.modal-close-btn {
  margin: auto;
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.button-confirmation {
  display: flex;
  flex-direction: column;
  width: 145px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 10px;
}

.confirm-text {
  font-size: 12px;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
  flex: 0 0 16px;
  padding-top: 4px;
}

.confirm-buttons {
  display: flex;
  flex: 1;
}

.confirm-buttons .button {
  height: 25px;
  width: 60px;
  min-width: 65px;
  font-size: 13px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0px;
  font-weight: normal;
}

.confirm-buttons .button.confirm-cancel-btn {
  margin-left: auto;
}

.confirm-cancel-btn {
  background-color: #dd7c0e;
}

.confirm-cancel-btn:hover {
  background-color: #f18811;
}

.button-container.confirm-mode {
  padding: 0;
}

.editor-container {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
}

.editor-container.center-edit {
  z-index: 9999;
}

.top-bar {
  position: absolute;
  top: 0;
  left: 15%;
  width: 85%;
  height: 15%;
  display: flex;
}

.top-bar-scenes {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.top-bar-widgets {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.top-bar-label {
  font-size: 18px;
  font-weight: bold;
  padding-left: 5px;
  border-bottom: 1px solid #888b8e;
  display: flex;
}

.top-bar-label div:last-of-type {
  margin-left: auto;
  margin-right: 10px;
  font-size: 15px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: default;
}

.top-bar-label div:last-of-type:hover {
  text-decoration: underline;
}

.top-bar-item-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 5px;
  background-color: #e8ecf2;
}

.top-bar-item-container .top-bar-item {
  display: flex;
  min-height: 30px;
  flex-direction: column;
  background-color: white;
  margin-bottom: 5px;
  padding: 5px;
  box-shadow: #c5c5c5 1px 1px 4px;
  cursor: default;
  border-left: 4px solid white;
  position: relative;
  font-weight: 500;
  border-left: 3px solid transparent;
}

.top-bar-item-container .top-bar-item.active {
  border-left: 3px solid green;
  font-weight: bold;
}

.top-bar-item-container .top-bar-item:not(.active):hover {
  border-left: 3px solid #91cc91;
}

.top-bar-item-container .top-bar-item>div {
  margin-top: auto;
  margin-bottom: auto;
}

.side-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.side-bar-scene {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.side-bar-widget {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5px;
  min-height: 0px;
}

.side-bar-title {
  font-weight: 500;
  display: flex;
  font-size: 18px;
  flex: 0 0 30px;
}

.side-bar-title * {
  margin-top: auto;
  margin-bottom: auto;
}

.side-bar-title .title-content {
  font-weight: bold;
  margin-left: 5px;
}

.side-bar-prop {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.side-bar-prop>div {
  font-size: 13px;
}

.side-bar-prop input {
  padding-left: 3px;
}

.side-bar-prop textarea {
  resize: none;
  font-family: Arial;
  padding: 3px;
  font-size: 14px;
  height: 80px;
}

.editing-this-widget {
  border: 2px dashed purple;
}

.editing-this-widget:before {
  content: "";
  background-color: red;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 1000000;
  border-radius: 50%;
  margin-left: -7px;
  margin-top: -7px;
}

.side-bar-parameters {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0px;
}

.parameters-header {
  display: flex;
  margin-top: 10px;
}

.parameters-title {
  font-weight: 500;
  font-size: 17px;
  margin-right: auto;
}

.parameters-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 5px;
  background-color: #e8ecf2;
  border-top: 1px solid #888b8e;
  border-bottom: 1px solid #888b8e;
  min-height: 0px;
}

.parameters-item {
  min-height: 0px;
  display: flex;
  flex: 0 0 90px;
  flex-direction: column;
  background-color: white;
  padding: 5px;
  margin-bottom: 5px;
  box-shadow: #c5c5c5 1px 1px 4px;
  cursor: default;
  border-left: 4px solid white;
  position: relative;
  border-left: 3px solid transparent;
}

.param-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}

.param-input>div {
  font-size: 13px;
}

.param-input input {
  padding-left: 3px;
}

.dark-mode * {
  background-color: black !important;
  color: green !important;
}

.param-add-btn {
  width: 50px;
  border: 1px solid grey;
  border-bottom: none;
  display: flex;
  margin-right: 5px;
  cursor: default;
}

.param-add-btn:hover {
  background-color: #ccf1dc;
}

.param-add-btn>div {
  margin: auto;
}

.scene-save-refresh-container {
  display: flex;
}

.scene-save-btn {
  border: 1px solid grey;
  flex: 9 0 70px;
  cursor: default;
  margin: 10px;
  font-size: 24px;
  text-align: center;
  padding: 12px;
}

.scene-refresh-btn {
  border: 1px solid grey;
  flex: 0 0 50px;
  cursor: default;
  margin: auto;
  font-size: 24px;
  text-align: center;
  padding: 12px
}

.scene-save-btn:hover, .scene-refresh-btn:hover {
  background-color: #ccf1dc;
}

.scene-save-btn>div, .scene-refresh-btn>div {
  margin: auto;
}

.widget-detail-btn, .new-widget-btn, .widget-finder {
  margin-left: 5px;
  border: 1px solid grey;
  width: 28px;
  height: 28px;
  display: flex;
  border-radius: 3px;
  cursor: default;
}

.new-widget-btn {
  margin-left: auto;
  font-weight: bold;
  font-size: 20px;
}

.widget-finder.finding, .widget-finder:hover {
  background-color: grey;
}

.widget-finder.finding svg, .widget-finder:hover svg {
  fill: white;
}

.widget-finder svg {
  margin: auto;
}

.widget-detail-btn:hover, .new-widget-btn:hover {
  color: white;
  background-color: grey;
}

.new-widget-btn div {
  margin: auto;
  margin-top: -2px;
}

.widget-detail-btn {
  font-size: 10px;
  display: flex;
  position: absolute;
  right: 10px;
  margin-top: 10px;
  user-select: none;
}

.widget-detail-btn div {
  margin: auto;
  padding: 0px;
}

.create-widget-pane {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px;
}

.create-widget-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.create-widget-buttons {
  flex: 0 0 60px;
  display: flex;
}

.create-widget-btn {
  display: flex;
  flex: 1;
  border: 1px solid grey;
  cursor: default;
  font-size: 18px;
  margin: 10px;
}

.create-widget-btn>div {
  margin: auto;
}

.create-widget-btn:hover {
  color: white;
  background-color: grey;
}

.workflow-editor-btn {
  font-size: 13px;
  font-weight: normal;
  display: flex;
  margin-left: auto;
  width: 100px;
  border: 1px solid grey;
  cursor: default;
}

.workflow-editor-btn:hover {
  background-color: #ccf1dc;
}

.workflow-editor-btn>div {
  margin: auto;
}

.center-editor {
  position: absolute;
  top: 15%;
  left: 15%;
  width: 85%;
  height: 85%;
  display: flex;
  flex-direction: column;
}

.workflow-editor-btn {
  margin-bottom: -1px;
  margin-right: 5px;
}

.workflow-card {
  display: flex;
  flex-direction: column;
  flex: 0 0 50px;
  background-color: white;
  margin: 3px;
  box-shadow: #c5c5c5 2px 2px 4px;
  cursor: default;
  padding: 5px;
  padding-left: 10px;
  border-left: 3px solid transparent;
}

.workflow-card:hover {
  border-left: 3px solid green;
}

.wf-card-name {
  font-size: 18px;
  font-weight: 500;
}

.new-workflow {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.new-workflow .top-pane {
  flex: 2;
  display: flex;
}

.new-workflow .bottom-pane {
  flex: 3;
  display: flex;
}

.triggers-pane, .actions-pane {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.wf-title-bar {
  flex: 0 0 30px;
  display: flex;
}

.wf-title-bar .wf-title {
  margin-top: auto;
  margin-left: 10px;
  margin-bottom: -10px;
}

.wf-title {
  font-size: 20px;
  font-weight: 500;
  flex: 1;
}

.bottom-pane .wf-title {
  font-size: 25px;
}

.wf-add-btn, .wf-btn {
  height: 25px;
  border: 1px solid gray;
  display: flex;
  cursor: default;
  width: 60px;
}

.wf-add-btn {
  flex: 0 0 60px;
  margin-top: auto;
  margin-bottom: -11px;
  margin-right: 20px;
}

.wf-btn {
  height: 30px;
  margin-left: 10px;
}

.wf-btn:hover, .wf-add-btn:hover {
  background-color: #ccf1dc;
}

.wf-btn>div, .wf-add-btn>div {
  margin: auto;
}

.wf-btn-bar {
  display: flex;
  margin-top: 10px;
}

.wf-btn-bar .wf-btn:first-of-type {
  margin-left: auto;
}

.wf-items-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid gray;
  margin: 10px;
  padding: 10px;
}

.wf-item {
  padding-left: 5px;
  cursor: default;
}

.wf-item:hover {
  background-color: #ffefcb;
}

.trigger-form, .action-form {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 10px;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
}

.trigger-form input, .action-form input {
  height: 30px;
  font-size: 18px;
}

.trigger-form select, .action-form select {
  height: 30px;
  font-size: 18px;
}

.horizontal-props {
  display: flex;
}

.horizontal-props .side-bar-prop {
  flex: 1;
  min-width: 0px;
}

.horizontal-props .side-bar-prop:not(:last-child) {
  margin-right: 10px;
}

.bool-toggle {
  border: 1px solid grey;
  cursor: default;
  display: flex;
  height: 30px;
}

.bool-toggle>div {
  margin: auto;
  user-select: none;
}

.bool-toggle:hover {
  background-color: teal;
  color: white;
}

.trigger-specific-props {
  border-top: 1px solid grey;
  margin: 15px 5px 15px 5px;
}

.wf-summary-text {
  display: flex;
  font-size: 20px;
}

.wk-highlight {
  color: teal;
  margin-left: 10px;
  margin-right: 10px;
}

.wf-title-pane {
  flex: 1;
  display: flex;
  flex: 0 0 40px;
  margin-top: 10px;
}

.wf-title-pane .wf-btn {
  margin: auto;
  margin-top: 18px;
  margin-left: 10px;
  margin-right: 10px;
  width: 120px;
}

.wf-title-pane .side-bar-prop {
  flex: 1;
  margin-left: 10px;
}

.wf-title-pane .side-bar-prop input {
  height: 30px;
  font-size: 16px;
}

.widget-carousel {
  display: flex;
  flex: 1;
  position: relative;
}

.widget-carousel-items {
  display: flex;
  flex: 1;
}

.widget-carousel-items .widget-loader {
  flex: 1;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  font-size: 45px;
  line-height: 18px;
  cursor: pointer;
  z-index: 10;
  user-select: none;
  width: 40px;
  height: 40px;
  display: flex;
}

.carousel-btn>div {
  margin: auto;
}

.carousel-right {
  right: 0px;
}

.carousel-left {
  transform: rotate(180deg);
  left: 0px;
}

.widget-carousel-items {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.carousel-nav {
  position: absolute;
  display: flex;
  height: 20px;
  width: 100%;
  bottom: 0;
}

.carousel-nav .pip-container {
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.carousel-nav .nav-pip {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid #707070;
}

.carousel-nav .nav-pip:not(:first-of-type) {
  margin-left: 10px;
}

.carousel-nav .nav-pip.active {
  background-color: #4AB84A;
}

#PhotoPicker {
  display: none;
}

.photo-picker {
  display: flex;
  position: relative;
}

.photo-picker .picker-container {
  margin: auto;
}

.picker-container {
  display: flex;
  flex-direction: column;
}

.photo-picker-icon {
  display: flex;
}

.photo-picker-icon svg {
  margin: auto;
  fill: #2C2C2CBF;
}

.photo-picker-label {
  color: #2C2C2CBF;
  font-weight: 500;
  margin: 5px;
}

.entry-banner {
  background-color: #F2F2F3;
}

.image-picker-thumbnail {
  flex: 1;
  display: flex;
  max-width: 50%;
  height: 100%;
  margin: auto;
  background-color: black;
}

.image-picker-thumbnail img {
  margin: auto;
}

.trash-photo {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 25%;
}

.trash-photo>div {
  display: flex;
  width: 45px;
  height: 45px;
  margin: 10px;
  position: absolute;
  right: 0;
  background-color: #FFFFFFBF;
  border-radius: 3px;
}

.trash-photo svg {
  margin: auto;
  fill: #2C2C2CBF;
}

.link-button .button {
  flex: 1;
  font-size: 20px;
  color: #4AB84A;
  text-decoration: underline;
}

.icon-button .button {
  border-radius: unset;
  margin: auto;
  height: 100%;
  flex: 1;
  color: #2C2C2CBF;
  font-weight: 500;
  flex-direction: column;
  z-index: 2;
}

.icon-btn-container {
  display: flex;
  flex-direction: column;
}

.icon-btn-container svg {
  margin: auto;
  width: 60px;
  height: 60px;
}

.icon-btn-container>div {
  margin: 5px;
}

.button svg {
  fill: #2C2C2CBF;
}

.back-button .icon-btn-container {
  display: flex;
  flex: 1;
  margin: unset;
}

.back-button svg {
  margin-right: auto;
  margin-left: 5px;
  fill: #FFFFFF;
  width: 40px;
  height: 40px;
}

.mobile-form .mobile-dropdown-control {
  padding-bottom: 10px;
}

.mobile-form .mobile-dropdown-button {
  height: 100%;
  max-height: 80px;
}

#map {
  position: relative;
}

.mysalesman-header {
  color: rgb(255, 200, 0);
  background-color: black;
  border-bottom: 8px solid #5e0d0d;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  user-select: none;
}

.ol-tooltip-clickthrough {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  user-select: none;
  pointer-events: none !important;
}

.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}

.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}

.ol-tooltip-measure:before, .ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.ol-tooltip:hover {
  cursor: pointer;
}

.map-measure-open {
  position: relative;
}

.map-measure-connector-piece {
  position: absolute;
  margin-left: 29px;
  top: 40px;
  height: 50px;
  width: 7px;
  border-top: 2px solid #2f2f2f;
  border-bottom: 2px solid #2f2f2f;
  background-color: white;
  opacity: .6;
}

.map-drawing-controls {
  position: fixed;
  right: 0px;
  bottom: 20px;
  background-color: white;
  border-radius: 4px;
  z-index: 1;
  padding: 5px;
  box-shadow: #2f2f2f 3px 2px 5px;
}

.map-measure-controls {
  position: absolute;
  right: 40px;
  top: 69px;
  background-color: white;
  border-radius: 4px;
  z-index: 1;
  animation: controls-in;
  animation-duration: 1s;
  padding: 5px;
  box-shadow: #2f2f2f 3px 2px 5px;
}

.wiki-container {
  display: flex;
  flex-direction: row;
}

.wiki-list {
  width: 280px;
  background-color: rgb(234, 234, 234);
  margin: 30px 0px 30px 15px;
  padding: 0px 8px 15px 8px;
  display: flex;
  flex-direction: column;
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  overflow: auto;
}

.wiki-list .wiki-item {
  color: white;
  background-color: black;
  /*margin: 10px 12px 0px 12px;*/
  /*margin-top: 10px;*/
  /* border-radius: 5px; */
  padding: 10px 0px 10px 10px;
  user-select: none;
  border-bottom: 2px solid grey;
}

.wiki-content {
  background-color: rgb(234, 234, 234);
  width: 100%;
  margin: 30px 15px 30px 0px;
  padding-left: 10px;
  border: 2px solid black;
  display: flex;
}

.wiki-new-btn {
  position: fixed;
  bottom: 20px;
  width: 205px;
  padding: 10px;
  background-color: #224269;
  color: white;
  border: 3px double #469cf3;
  font-size: 1.05rem;
}

.wiki-new-btn:hover, .wiki-save-cancel button:hover, .wiki-edit-btn:hover, .wiki-edit-save-cancel button:hover {
  background-color: #35639c;
}

.wiki-edit-btn {
  position: fixed;
  right: 50px;
  bottom: 60px;
  padding: 8px 30px;
  background-color: #224269;
  color: white;
  border: 3px double #469cf3;
  font-size: 1.05rem;
  z-index: 5;
}

.wiki-edit-save-cancel {
  position: fixed;
  right: 40px;
  bottom: 60px;
  z-index: 5;
}

.wiki-edit-save-cancel button {
  background-color: #224269;
  color: white;
  border: 3px double #469cf3;
  padding: 8px 30px;
  font-size: 1.05rem;
  margin: 0px 15px;
}

.wiki-save-cancel {
  display: flex;
  justify-content: space-evenly;
  padding-top: 15px;
}

.wiki-save-cancel button {
  margin-top: 20px;
  background-color: #224269;
  color: white;
  border: 2px double #469cf3;
  padding: 8px;
  font-size: 1.05rem;
}

.new-wiki-sidebar {
  margin-top: 100px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.new-wiki-sidebar select {
  padding: 5px;
  width: 100%;
  margin-top: 5px;
}

.new-wiki-sidebar input {
  padding: 3px;
  width: 100%;
  margin-top: 5px;
}

.wiki-search {
  width: 90%;
  margin: 15px auto;
  padding: 5px;
  border: 2px solid black;
}

.wiki-list .wiki-item:hover {
  background-color: rgb(77, 77, 77);
}

.selected-wiki {
  border-left: solid 10px #469cf3;
  background-color: #224269 !important;
}

.wiki-editor {
  overflow: auto;
  margin: 10px;
  width: 100%;
}

.ol-popup-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  z-index: 5;
  color: white;
}

/* .ol-popup-content {
  padding: 5px;
  border-radius: 5px;
  color: black;
  font-weight: 500;
  background-color: #dbdbdb;
  border: 2px solid #ce0300;
  outline: 1px solid black;
  background-image: linear-gradient(to bottom, #e5e5e5 0%, #b3b3b3 100%);
} 

.ol-popup-content:hover {
  background-image: linear-gradient(to bottom, #b3b3b3 0%, #e5e5e5 100%);
  cursor: pointer;
} */

.closeBox {
  display: none;
}

.ol-popup-content:hover {
  cursor: pointer;
}

.wiki-editor-active {
  overflow: auto;
  border: 3px dotted red;
  margin: 10px;
  width: 100%;
}

.wiki-editor-class {
  background-color: white;
  overflow-y: auto;
  padding: 20px;
  min-height: 400px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}

.wiki-toolbar-class {
  position: fixed;
  margin-top: -50px;
  z-index: 5;
}

.component-grid-dropdown {
  color: lime;
}

@keyframes controls-in {
  from {
    right: 15px;
    opacity: 0;
  }

  to {
    right: 40px;
    opacity: 1;
  }
}

.map-drawing-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  width: 24px;
}

.map-measure-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  height: 24px;
  width: 24px;
}

.map-measure-buttons svg {
  fill: #007abb;
}

.map-measure-controls svg:hover, .map-measure-open svg:hover {
  fill: rgb(253, 186, 48);
  cursor: pointer;
}

.map-crosshair {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  pointer-events: none;
}

.map-crosshair svg {
  width: 100%;
  height: 100%;
}

.mobile-form .textbox-control {
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.mobile-form .textbox-control input {
  height: 100%;
  font-size: 20px;
}

.textbox-control .typeahead-container {
  display: flex;
  width: 100%;
  left: 0;
  pointer-events: all;
}

.color-picker input {
  padding-right: 5px;
}

.typeahead-loader {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: #c5c5c5 1px 1px 4px;
  padding: 60px;
}

.typeahead-wrapper {
  z-index: 5;
  position: absolute;
  display: flex;
  pointer-events: all;
  max-height: 400px;
  width: 98%;
}

.mysales-user-form .typeahead-wrapper {
  max-height: 190px;
}

.map-search-load-bg {
  background-color: #f7f7f7;
}

.textbox-control .typeahead-dropdown {
  flex: 1;
  position: static;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  width: calc(100% - 25px);
  z-index: 10;
  overflow: auto;
  padding-bottom: 5px;
}

.typeahead-item {
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  margin-top: 5px;
  box-shadow: #c5c5c5 1px 1px 4px;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 50px;
}

.typeahead-item .text-1 {
  margin-top: auto;
  margin-bottom: auto;
}

.typeahead-item .text-2 {
  font-size: 14px;
  color: #2C2C2C8A;
}

.typeahead-item .text-3 {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 12px;
}

.mobile-log {
  height: 200px;
  width: 100%;
  overflow: auto;
  display: fixed;
  bottom: 0;
  z-index: 999999999;
  display: flex;
  flex-direction: column;
}

.info-card-wrapper {
  padding: 5px;
  display: flex;
  min-width: 0px;
  width: 100%;
}

.info-card {
  flex: 1;
  min-width: 0px;
  min-height: 0px;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 4px 1px #00000047;
}

.InfoCardHeader .info-card-icon {
  display: flex;
  padding-bottom: 10px;
  min-width: 0px;
  min-height: 0px;
  width: 45px;
  height: 45px;
}

.InfoCardHeader .info-card-text {
  display: flex;
  flex-direction: column;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0px;
}

.InfoCardHeader .info-card-icon img {
  margin: 5px;
  height: 100%;
}

.InfoCardHeader .text-1 {
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  flex: 1;
  display: flex;
}

.widget-testing-text {
  background-color: rgb(253, 186, 48, .3);
  color: black;
  font-size: 1.3em;
  font-weight: bold;
  margin-top: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid #5d221e;
}

.InfoCardHeader .text-1 div {
  margin-top: auto;
  margin-bottom: auto;
}

.InfoCardHeader .text-2 {
  font-size: 14px;
  color: #8d8d8d;
  flex: 1;
  display: flex;
}

.InfoCardHeader .text-2 div {
  margin-top: auto;
  margin-bottom: auto;
}

.InfoCardHeader .text-3 {
  position: absolute;
  right: 10px;
  bottom: 4px;
  font-size: 13px;
  font-weight: bold;
  color: #8d8d8d;
}

.InfoCardEventHeader {
  padding: 2px;
}

.InfoCardEventHeader .info-card-text {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.InfoCardEventHeader .info-card-icon img {
  margin: 3px;
  width: 28px;
  height: 28px;
}

.InfoCardEventHeader .text-1 {
  flex: 3;
  display: flex;
  min-width: 0px;
  min-height: 0px;
}

.InfoCardEventHeader .text-1 .info-card-icon {
  display: flex;
  padding-bottom: 4px;
  width: 35px;
}

.InfoCardEventHeader .text-1 .entity-label {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  display: flex;
}

.entity-label.no-icon-label {
  padding-left: 5px;
}

.InfoCardEventHeader .text-1 .entity-label div {
  margin-top: auto;
  margin-bottom: auto;
}

.InfoCardEventHeader .text-1 .entity-name {
  font-size: 16px;
  color: #8d8d8d;
  margin-left: 10px;
  line-height: 20px;
  display: flex;
}

.InfoCardEventHeader .text-1 .entity-name div {
  margin-top: auto;
  margin-bottom: auto;
}

.InfoCardEventHeader .date-range {
  display: flex;
  flex: 2;
  margin-left: 5px;
  font-size: 14px;
  color: #8d8d8d;
}

.InfoCardEventHeader .text-3 {
  position: absolute;
  right: 10px;
  top: 4px;
  font-size: 13px;
  font-weight: bold;
  color: #8d8d8d;
}

.entity-agency-label {
  font-size: 17px;
  font-weight: bold;
  color: #252525;
}

.entity-agency-label .text-widget {
  display: flex;
}

.entity-agency-label .text-widget div {
  margin-left: 5px;
}

.auto-fill-drop-down {
  display: flex;
  flex: 1;
  position: relative;
  overflow: visible;
}

.auto-fill-drop-down input {
  flex: 1;
  height: 100% !important;
  font-size: 16px;
}

.auto-complete-items {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50px;
  max-height: 300px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: white;
  padding-bottom: 5px;
  z-index: 2;
}

.auto-complete-item {
  display: flex;
}

.info-card.AutoCompleteCard {
  height: 47px;
  border: 1px solid transparent;
  cursor: default;
  border-radius: 3px;
  background-color: white;
}

.AutoCompleteCard .info-card-text {
  flex: 1;
}

.AutoCompleteCard .text-1 {
  height: 100%;
  display: flex;
  font-weight: 500;
  font-size: 14px;
}

.AutoCompleteCard .text-1 div {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.AutoCompleteCard .text-2 {
  position: absolute;
  right: 5px;
  top: 2px;
  font-size: 13px;
  font-weight: bold;
  color: #8d8d8d;
}

.AutoCompleteCard .text-3 {
  position: absolute;
  right: 5px;
  top: 30px;
  font-size: 10px;
  color: #8d8d8d;
}

.auto-complete-item.hover .AutoCompleteCard {
  border: 1px solid #ffcb5c;
}

.auto-complete-items .AutoCompleteCard {
  margin-bottom: -5px;
}

.auto-fill-drop-down .selected-card {
  display: flex;
  flex: 1;
  margin-top: -3px;
  overflow: hidden;
}

.auto-fill-drop-down .selected-card {
  position: relative;
  display: flex;
}

.auto-fill-drop-down .selected-card .re-select {
  right: 0;
  width: 0px;
  display: flex;
  transition-property: width;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
  position: absolute;
  height: 100%;
}

.auto-fill-drop-down .selected-card .re-select:hover div {
  background-color: #ffcb5c;
}

.auto-fill-drop-down .selected-card:hover .re-select {
  width: 40px;
}

.auto-fill-drop-down .selected-card .re-select div {
  flex: 1;
  display: flex;
  background-color: #ffd581;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
}

.auto-fill-drop-down .selected-card .re-select svg {
  margin: auto;
}

.auto-fill-drop-down .info-card-wrapper {
  margin-right: 0px;
  transition-property: margin-right;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.auto-fill-drop-down .selected-card:hover .info-card-wrapper {
  margin-right: 40px;
}

.control-wrapper .auto-fill {
  flex: 0 35px;
  min-width: 0px;
  margin: auto;
  font-weight: bold;
  height: 100%;
  display: flex;
  margin-left: -8px;
  margin-top: 0px;
}

.auto-fill-button {
  display: flex;
  flex: 1;
  user-select: none;
}

.auto-fill-button>div {
  margin: auto;
  cursor: pointer;
  padding-top: 11px;
}

.auto-fill-button svg {
  margin: auto;
  width: 100%;
  height: 23px;
  width: 23px;
}

.failed-validate select, .failed-validate textarea, .failed-validate input {
  border: 2px solid red !important;
}

select.failed-validate, textarea.failed-validate, input.failed-validate {
  border: 2px solid red !important;
}

.valid-failed .text-widget, .valid-failed select, .valid-failed textarea, .valid-failed input, select.valid-failed, textarea.valid-failed, input.valid-failed {
  border: 2px solid red !important;
  box-shadow: .5px .5px 3px .5px red;
}

.valid-failed select:focus, .valid-failed textarea:focus, .valid-failed input:focus, select.valid-failed:focus, textarea.valid-failed:focus, input.valid-failed:focus {
  border: 2px solid darkred !important;
  box-shadow: 1px 1px 3px 1.5px red !important;
  outline: none;
}

.recur-check.valid-failed {
  border: 2px solid red !important;
  box-shadow: .5px .5px 3px .5px red;
}

#report-frame {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.dropdown-menu-container {
  position: relative;
  z-index: 10;
  overflow: visible;
  display: flex;
  height: 60px;
}

.dropdown-wrapper {
  position: absolute;
}

.dropdown-caret-wrapper {
  overflow: hidden;
  margin-left: -50px;
  margin-top: 28px;
  width: 20px;
  height: 12px;
  position: fixed;
  z-index: 1;
}

.dropdown-caret {
  transform: rotate(45deg);
  margin-top: 8px;
  margin-left: -10px;
  width: 40px;
  height: 40px;
  position: absolute;
  border: 1px solid #cdcdcd;
  background-color: white;
}

.dropdown-menu {
  position: fixed;
  width: 200px;
  margin-left: -205px;
  margin-top: 38px;
  background-color: white;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
  border: 1px solid #cdcdcd;
  border-radius: 4px;
}

.dropdown-menu-item {
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: default;
  background: white;
}

.dropdown-menu-item:hover {
  background-color: #0487c5;
  color: white;
}

.display-image {
  flex: 1;
  display: flex;
}

.display-image img {
  margin: auto;
}

/* relative-absolute-fixed */

.map-action-btn {
  position: relative;
}

.map-action-btn .wrapper {
  position: absolute;
  right: 0;
}

.map-action-btn .advanced-btn {
  position: fixed;
  display: flex;
  margin-left: -50px;
  margin-top: -50px;
  border-radius: 50px;
  width: 42px;
  height: 42px;
  background-color: green;
  border: 2px solid white;
  color: white;
  font-weight: bold;
  font-size: 30px;
  box-shadow: #2f2f2f 3px 2px 5px;
  letter-spacing: 1px;
  user-select: none;
}

.map-action-btn .advanced-btn:hover {
  background-color: #039803;
}

.map-action-btn .advanced-btn>div {
  margin: auto;
  margin-top: -10px;
  margin-left: 6px;
}

.advanced-txt-btn {
  display: flex;
  border-radius: 50px;
  background-color: green;
  border: 2px solid white;
  color: white;
  font-weight: bold;
  font-size: 30px;
  box-shadow: #2f2f2f 3px 2px 5px;
  letter-spacing: 1px;
  user-select: none;
  z-index: 1000;
}

.advanced-txt-btn:hover {
  background-color: #039803;
}

.advanced-txt-btn>div {
  margin: auto;
  margin-top: -10px;
  margin-left: 3px;
}

.map-footer-bar-container {
  position: relative;
  height: 22px;
  margin-top: -22px;
  padding-bottom: 2px;
  background-color: white;
}

.map-footer-bar-container .wrapper {
  position: absolute;
  width: 100%;
  height: 20px;
}

.footer-bar {
  background-color: black;
  color: white;
  font-size: 11px;
  width: 100%;
  display: flex;
  height: 100%;
}

.footer-bar>div {
  min-height: 0px;
  line-height: 20px;
}

.footer-bar>div {
  margin-left: 20px;
}

.polygon-panel-helptext {
  position: absolute;
  /* right: 0; */
  background-color: #2d2d2d;
  color: white;
  padding: 15px 30px 40px 30px;
  animation: animate-slide-down .3s ease-in-out;
  border: 2px solid #2d2d2d;
  border-bottom-right-radius: 10px;
  border-bottom: 2px solid #969696;
  border-right: 1px solid #969696;
  min-width: 350px;
  /* width: 100%; */
}

.polygon-roof-modal-btns {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.polygon-roof-modal-btns button {
  width: 130px;
}

.polygon-panel-helptext div {
  margin-bottom: 15px;
}

.polygon-panel-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #2d2d2d;
  color: white;
}

.polygon-panel {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* @media only screen and (max-width: 900px) {
  .image-grouping-row-container {
    min-height: 115px;
  }
} */


.polygon-panel-buttons {
  display: flex;
  align-items: center;
  min-width: 450px;
  justify-content: center;
  user-select: none;
}

.polygon-panel-buttons label {
  user-select: none;
}

.polygon-panel input {
  accent-color: #7a2726;
  height: 18px;
  width: 18px
}

.overflow-auto {
  overflow: auto;
}

.panel-subscene {
  background-color: #f5f5f5;
}

.photo-upload-control {
  border: 1px solid #ccc;
  display: flex;
  padding: 0px 25px;
  height: 100%;
  width: 100%;
  align-items: center;
}

.PhotoUploader {
  /* overflow: visible !important; */
}

.photo-upload-dragndrop {
  outline: 5px dashed blue;
  outline-offset: -30px;
  background-color: cyan;
  width: 100%;
  height: 100%;
}

.photo-upload-control img {
  border: 1px solid black;
}

.photo-upload-control-button {
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  border: 1px solid darkgrey;
  background-image: linear-gradient(to bottom, #f8af11 0%, #e7790a 100%);
  user-select: none;
  max-width: 150px;
  /* margin: auto;
  margin-left: 10px; */
}

.photo-upload-control-button:hover {
  cursor: pointer;
  background-image: linear-gradient(to bottom, #e7790a 0%, #f8af11 100%);
}

.polygon-btn {
  margin: 10px;
  padding: 5px 30px;
  border-radius: 5px;
  background-color: #772422;
  color: white;
  font-weight: bold;
  border: 1px solid darkred;
  background-image: linear-gradient(to bottom, #7a2726 0%, #5e0d06 100%);
  user-select: none;
  height: 35px;
}

.polygon-btn-disabl {
  margin: 10px;
  padding: 5px 30px;
  border-radius: 5px;
  color: #979797;
  background-color: #d8d8d8;
  font-weight: bold;
  border: 1px solid darkgrey;
  user-select: none;
  height: 35px;
}

.homepage-text, .mysalesman-red {
  color: #772422;
  user-select: none;
}

.consumer-site-link {
  color: #772422;
  font-size: 14px !important;
}

.consumer-site-link:hover {
  color: red;
}

.mysales-total-est {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.catalog-subtext {
  font-size: 12px;
  color: #444444;
}

.typeahead-item:hover {
  background-color: #FFF3E0;
  font-weight: 500;
  outline: 1px solid black;
}

.typeahead-selected {
  background-color: #FFF3E0;
  font-weight: 500;
  outline: 1px solid black;
}

.polygon-next-img {
  position: absolute;
  right: 120px;
  bottom: 20px;
}

.clear-all-img {
  position: absolute;
  right: 70px;
  bottom: 20px;
}

.image-grouping-container {
  padding: 25px 5px;
  overflow: auto;
  background-color: #ffffff;
  max-width: 100vw;
}

.image-grouping-row {
  margin-bottom: 20px;
}

.image-grouping-delete {
  color: #772422;
  fill: #772422;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.image-grouping-delete:hover {
  cursor: pointer;
}

.image-grouping-row-picker {
  display: flex;
  align-items: center;
  overflow: auto;
  /* -ms-overflow-style: scroll;
  scrollbar-width: 0px; */
  scroll-behavior: smooth;
}

.image-grouping-row-picker::-webkit-scrollbar {
  display: none;
}

.image-grouping-row-container {
  display: flex;
  margin: auto;
}

.image-grouping-cell-container:hover {
  outline: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
}

.homepage-text:hover {
  text-decoration: underline;
  cursor: pointer;
  color: darkred;
}

.polygon-btn, .polygon-btn-disabl {
  justify-content: center;
  align-items: center;
}

.smaller-steps {
  font-size: .9rem;
}

.mysales-user-form .control-label {
  color: #505050;
  font-weight: 500;
  font-size: .8rem;
  padding-top: 2px;
}

.mysales-sales-team-card {
  display: flex;
  padding: 8px;
  border: 1px solid grey;
  user-select: none;
  margin: 5px;
  border-radius: 5px;
  background-color: #eeeeee;
  overflow: hidden;
}

.mysales-sales-team-card:hover {
  background-color: #ffefcb;
}

.mysales-sales-team-card .mySales-email {
  word-break: break-all;
}

.mySales-email {
  color: #5e0d0d;
}

.item-catalog-card .delete-control {
  margin-right: 5px;
  margin-top: -3px;
  margin-bottom: -5px;
}

.item-catalog-delete {
  color: red;
  position: relative;
  left: 245px;
  margin-top: -5px;
  margin-bottom: -15px;
  font-size: 1.5rem;
  width: 25px;
}

.item-catalog-delete:hover {
  cursor: pointer;
  font-weight: bolder;
}

/* .mySales-email:hover {
  text-decoration: underline;
  cursor: pointer;
} */

.mysales-heard-us-form .form-grid {
  grid-auto-rows: 60px !important;
  margin: 0px 20px;
  overflow: hidden;
  background-color: #ffffff;
  padding-top: 18px;
  font-size: 16px !important;
}

.mysales-user-form .form-grid {
  grid-auto-rows: 60px !important;
  margin: 0px 20px;
  overflow: hidden;
  background-color: #f5f5f5;
}

.mysales-user-form input, .mysales-user-form select, .mysales-address-search input {
  border: 1px solid #787878;
  background: #fff !important;
  border-radius: 0px !important;
}

.mysales-your-address {
  align-self: flex-start;
  padding-top: 15px;
}

.mysales-user-form .typeahead-dropdown {
  cursor: default;
  user-select: none;
}

.mysales-user-form .typeahead-container {
  max-height: 210px;
}

.polygon-btn-pos {
  margin: 10px;
  padding: 8px 15px;
  border-radius: 5px;
  background-color: #772422;
  color: white;
  font-weight: bold;
  border: 1px solid darkred;
  background-image: linear-gradient(to bottom, #7a2726 0%, #5e0d06 100%);
  user-select: none;
}

.polygon-btn-neg {
  margin: 10px;
  padding: 8px 15px;
  border-radius: 5px;
  color: black;
  background-color: #dbdbdb;
  font-weight: bold;
  border: 1px solid darkgrey;
  background-image: linear-gradient(to bottom, #e5e5e5 0%, #b3b3b3 100%);
  font-size: 12px;
  user-select: none;
}

.polygon-btn-pos:hover, .polygon-btn:hover {
  background-image: linear-gradient(to bottom, #5e0d06 0%, darkred 100%);
  /* cursor: pointer; */
}

.polygon-btn-neg:hover {
  background-image: linear-gradient(to bottom, #b3b3b3 0%, #e5e5e5 100%);
  /* cursor: pointer; */
}

.polygon-btn-disabled {
  margin: 10px;
  padding: 8px 15px;
  border-radius: 5px;
  color: #979797;
  background-color: #d8d8d8;
  font-weight: bold;
  border: 1px solid darkgrey;
  cursor: default;
  font-size: 12px;
  user-select: none;
}

.mysales-next-header>div {
  border-radius: 5px;
  color: #979797;
  background-color: #d8d8d8;
  font-weight: bold;
  border: 1px solid darkgrey;
  cursor: default;
  height: 35px;
  width: 90px;
  margin: 15px;
}

.mysales-next-header-active>div {
  border-radius: 5px;
  background-color: #772422;
  color: white;
  font-weight: bold;
  border: 1px solid darkred;
  background-image: linear-gradient(to bottom, #7a2726 0%, #5e0d06 100%);
  user-select: none;
  height: 35px;
  width: 90px;
  margin: 15px 7px;
}

.mysales-next-header-active>div:hover {
  background-image: linear-gradient(to bottom, #5e0d06 0%, darkred 100%);
  /* cursor: pointer; */
}

.step-circle {
  background-color: #787878;
  border-radius: 30px;
  padding: 3px 9px;
  color: white;
  user-select: none;
}

.mysales-back-btn {
  border: 1px solid grey;
  margin-left: 7px;
  padding: 0px 10px 5px 7px;
  user-select: none;
}

.mysales-back-btn:hover {
  cursor: pointer;
  background-color: lightgrey;
}

.small-margins {
  margin: 0px 15px;
}

.cardlist-spaced .card-item {
  margin: 5px 10px 10px 10px;
}

.mysales-header-right {
  color: white;
  text-align: right;
  font-size: .9rem;
  margin-bottom: 8px !important;
}

.active-step-circle {
  background-color: #1a1a1a;
  border-radius: 30px;
  padding: 3px 9px;
  color: white;
  user-select: none;
}

.mysales-grey-text {
  color: #5A5A5A;
}

.contractor-header {
  font-size: 1rem;
  text-align: right;
  margin-right: 10px;
  padding-right: 10px;
}

.consumer-link a {
  font-weight: 500;
  color: #0645AD;
}

.consumer-link a:hover {
  color: rgb(255, 200, 0)
}

.contractor-header .text-widget div {
  width: 100%;
}

.grey-border-sides {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.mysales-header-left {
  background-color: #d6d6d6;
}

.add-another-area .button-container .button {
  width: auto;
  padding: 5px 35px;
}

.mysales-cardlist-container {
  display: flex;
  /* margin: 10px; */
  margin: 10px 10px 10px 5px;
  border-bottom: 1px solid lightgray;
  justify-content: space-evenly;
  /* min-height: 108px; */
  overflow: hidden;
  min-height: fit-content;
  padding-top: 5px;
}

.relative-bottom-22 {
  position: relative;
  bottom: 22px;
}

.mysales-cardlist-price {
  color: #5e0d0d;
  font-weight: 500;
  align-self: flex-end;
  position: relative;
  bottom: 25px;
  /* margin-bottom: -25px;
  margin-right: -40px; */
}

.mysales-cardlist-content {
  display: flex;
  flex-direction: column;
  max-height: 150px;
  width: 100%;
  margin-bottom: -15px;
}

.mysales-cardlist-trash {
  fill: #b70f0f;
  /* margin-left: 20px;
  margin-top: 60px; */
}

.mysales-cardlist-trash svg:hover {
  fill: red;
  cursor: pointer;
}

.mysales-cardlist-numbers {
  display: flex;
  align-items: center;
  margin: 0px 10px;
}

.mysales-cardlist-button-container {
  display: flex;
  width: 255px;
  z-index: 10;
}

@keyframes animate-slide-in {
  0% {
    transform: translate(25px);
    opacity: .4;
  }

  100% {
    transform: translate(0px);
    opacity: 1;
  }
}

@keyframes animate-slide-down {
  0% {
    transform: translateY(-15px);
    opacity: .1;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes image-catalog-select {
  0% {
    transform: translate(0px);
    opacity: 1;
  }

  100% {
    transform: translate(-50px);
    opacity: 0;
  }
}

@keyframes image-catalog-deselect {
  0% {
    transform: translate(-50px);
    opacity: 0;
  }

  100% {
    transform: translate(0px);
    opacity: 1;
  }
}

.image-catalog-deselect .image-grouping-cell-container {
  animation: image-catalog-deselect .3s ease-in-out;
}

.polygon-selection-modal {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, .4);
  height: 100%;
}

.polygon-help-ok {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.polygon-panel-help {
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 100%;
  max-height: 25px; */
  overflow: hidden;
  background-color: rgb(45, 45, 45);
  border: 2px solid rgb(66, 66, 66);
  height: 40px;
  padding-left: 25px;
  min-width: 70px;
  border-radius: 5px;
}

.polygon-panel-help:hover {
  cursor: pointer;
  background-color: rgb(66, 66, 66);
}

#polygon-panel-help-triangle {
  /* help text triangle SVG */
  transition: all .4s;
  transform: rotate(90deg);
  margin-right: 10px;
}

.polygon-selection-modal>div {
  width: 300px;
  background-color: #f1f2f2;
  /*f5f5f5*/
  margin: 35px auto auto auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 500;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, .6);
  border: 2px solid #782524;
  border-top: 5px solid #782524;
}

.polygon-selection-modal-button-container {
  width: 220px;
}

.polygon-selection-modal-button-container .polygon-btn-pos {
  width: 200px;
}

.add-gate-dropdown {
  position: absolute;
  background-color: #2d2d2d;
  border-right: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  animation: animate-slide-down .2s ease-in-out;
}

.first-image-cell {
  animation: none !important;
}

.image-catalog-select {
  animation: image-catalog-select .3s ease-in-out;
}

.slide-in .sub-scene>div>div {
  animation: animate-slide-in .2s ease-in-out;
}

.mysales-button-loading {
  background-color: rgba(245, 245, 245, .3);
}

.no-slide-in {
  animation: none !important;
}

.slide-in .sub-scene>div {
  overflow-x: hidden;
}

.mysales-cardlist-delete-card {
  background-color: #aa180b;
  border-radius: 10px 0 0 10px;
  color: white;
  display: flex;
  /* position: absolute; */
  margin-left: -290px;
  margin-top: 54px;
  height: 50px;
  width: 320px;
  align-items: center;
  justify-content: flex-end;
  animation: animate-delete-slide .2s linear;
  position: relative;
  z-index: 10;
}

@keyframes animate-delete-slide {
  0% {
    transform: translate(220px);
    opacity: .4;
  }

  100% {
    transform: translate(0px);
    opacity: 1;
  }
}

.mysales-all-done {
  background-color: #d6d6d6;
}

.mysales-completed-top-card {
  border: 1px solid lightgray;
}

.mysales-cardlist-container:hover {
  /* background-color: rgba(225, 255, 254, .5); */
  outline: 1px solid rgba(0, 0, 0, .8);
  cursor: default;
  background-color: rgba(255, 254, 238, .5);
  /* border: 4px double rgba(179, 255, 254); */
}

/* .mysales-cardlist-container:hover .step-circle {
  background-color: yellow;
  outline: 1px solid black;
  color: black;
} */

.mysales-completed-top-card .text-widget div {
  margin: auto 0px;
  margin-left: 15px;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 500;
  color: #363636;
}

.image-grouping-cell-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2px 15px 2px 2px;
}

.image-grouping-arrows {
  display: flex;
  align-items: center;
  user-select: none;
  margin-bottom: 25px;
  margin-top: 10px;
}

.image-grouping-arrows svg {
  pointer-events: none;
}

.image-grouping-row-header {
  color: rgb(77, 77, 77);
  border-bottom: 1px solid lightgray;
  margin-bottom: 10px;
  font-weight: 500;
  width: 95%;
}

.image-grouping-arrows:hover {
  cursor: pointer;
  fill: darkgray;
}

.mysales-complete-contact {
  border: 1px solid lightgray;
  overflow: auto;
  padding: 5px 0px;
}

.mysales-complete-contact .text-widget div {
  margin: auto 0px auto 15px;
  font-size: .9rem;
}

.control-panel-wrapper {
  position: absolute;
  width: 33px;
  height: 100%;
  top: 0;
  right: 10px;
  padding-top: 47px;
  padding-bottom: 32px;
}

.control-panel {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: #2f2f2f 3px 2px 5px;
  z-index: 2;
}

.control-panel .top-controls {
  margin-bottom: auto;
  margin-top: 10px;
}

.control-panel .bottom-controls {
  margin-top: auto;
  margin-bottom: 10px;
}

.map-control {
  display: flex;
  margin-top: 7px;
}

.map-control>div {
  margin: auto;
  height: 24px;
  cursor: pointer;
}

.map-control svg {
  fill: #007abb;
  margin: auto;
}

.mobile-map-edit-control {
  display: flex;
  margin-bottom: 4px;
  margin-left: 60px;
  margin-right: 60px;
}

.mobile-map-edit-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  position: absolute;
  top: 0px;
  z-index: 100;
  background-color: white;
  padding-bottom: 4px;
}

.mobile-map-edit-control svg {
  height: 18px;
  width: 18px;
  margin: auto;
}

.mobile-map-edit-control .mobile-edit-control-label {
  margin: auto;
  font-weight: 500;
  font-size: 16px;
  flex: 1;
  margin-left: 10px;
  margin-bottom: 4px;
}

.mobile-map-edit-control .mobile-select-box {
  margin-left: auto;
  border-left: 1px solid #e4e4e4;
  width: 35px;
  height: 33px;
  display: flex;
}

.mobile-map-location-button {
  margin: auto !important;
  width: 140px !important;
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.mobile-map-lat-lon-button {
  margin: auto !important;
  width: 100px !important;
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.map-edit-wrapper {
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: column;
  min-width: 0px;
}

.map-edit-control {
  display: flex;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
}

.map-edit-control svg {
  height: 18px;
  width: 18px;
  margin: auto;
}

.map-edit-control .edit-control-label {
  margin: auto;
  font-weight: 500;
  font-size: 16px;
  flex: 1;
  margin-left: 10px;
}

.map-edit-control .select-box {
  margin-left: auto;
  border-left: 1px solid #e4e4e4;
  width: 35px;
  height: 33px;
  display: flex;
}

.multimapcontrol {
  height: 50px;
  margin-left: 10px;
}

.multimapcontrol .Cancel {
  float: left;
  width: 99px !important;
  margin-left: 10px !important;
  height: 32px !important;
}

.map-edit-control .geo-picker {
  height: 35px;
}

.map-edit-wrapper .button.Cancel {
  height: 30px;
  font-size: 15px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 15px;
  min-width: 0px;
  min-height: 0px;
  width: 200px;
  display: flex;
}

.map-edit-wrapper .small-button.Cancel {
  height: 20px !important;
  width: 100px !important;
}

.map-edit-wrapper .button.Cancel div {
  margin: auto;
}

.click-to-pan {
  cursor: all-scroll;
}

.compact-list .card-item {
  min-height: 27px;
  max-height: 27px;
  padding: 0;
  padding-left: 10px;
}

.compact-list .card-item .text-1 {
  font-size: 14px;
}

.compact-list .card-item .multiselect-box {
  min-height: 27px;
  max-height: 27px;
}

.compact-list .card-item .multiselect-box svg {
  width: 15px;
  height: 15px;
}

.compact-list .multiselect .text-1 {
  margin-right: 35px;
}

.compact-list:not(.query-list) .parent-card.expand .chevron, .compact-list .top-level-parent-card.expand .chevron {
  margin-left: -3px;
  margin-right: 13px;
}

.compact-list .parent-card .chevron, .compact-list .top-level-parent-card .chevron {
  margin-left: 5px;
  margin-right: 5px;
}

.mobile-search-bar input {
  margin-left: 5px;
}

.mobile-search-bar .div-btn {
  margin-right: 5px;
  min-height: 29px;
  max-height: 29px;
}

.resources-item-container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #e8ecf2;
  margin-top: 5px;
}

.resource-items {
  flex: 1;
  margin-bottom: 120px;
}

.resources-item-container .plus-button {
  background-color: green;
  width: 63px;
  height: 63px;
  position: absolute;
  left: 50%;
  top: 100%;
  margin-left: -32px;
  margin-top: -100px;
  border-radius: 32px;
  display: flex;
  border: 3px solid white;
  box-shadow: #c5c5c5 2px 2px 4px;
  flex: 0 0 100px;
}

.resources-item-container .plus-button .cross-vertical {
  margin: auto;
  height: 30px;
  width: 6px;
  top: 14px;
  left: 26px;
  background-color: white;
  position: absolute;
}

.resources-item-container .plus-button .cross-horizontal {
  margin: auto;
  height: 6px;
  width: 30px;
  top: 26px;
  left: 14px;
  background-color: white;
  position: absolute;
}

.mobile-interface .entry {
  width: 100%;
  display: flex;
  flex: 1;
  margin: 10px;
}

.save-btn-mobile .button-container {
  padding: 0px;
}

.save-btn-mobile .button {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 0px;
  min-width: unset;
  border-left: 2px solid grey;
  color: white;
  font-size: 18px;
}

.save-btn-mobile .button.disabled {
  background-color: #252525 !important;
}

.save-btn-mobile .button:not(.disabled) {
  font-weight: 500;
  color: black;
  background-color: #fdba30;
}

.mobile-selectable-cards .card-list {
  padding: 10px;
}

.mobile-selectable-cards .card-list>div {
  margin: 20px;
  margin-right: 0px;
  min-height: 50px;
  border-radius: 10px;
  margin-top: 10px;
}

.ol-clickthrough {
  pointer-events: none !important;
}

.mobile-app .document-widget .right-pane {
  display: none;
}

.mobile-app .document-widget .left-pane {
  margin-right: 0px;
}

.mobile-app .document-widget .left-pane .button-bar {
  padding-left: 10px;
  padding-right: 10px;
}

.mobile-app .document-widget .left-pane .button-bar .doc-btn {
  flex: 1;
}

.mobile-app .document-widget .attach-preview {
  flex-direction: column;
  padding: 0px;
}

.mobile-app .document-widget .preview-box {
  margin-left: 0px;
}

.mobile-app .document-item>*:not(.doc-details) {
  display: none;
}

.mobile-app .doc-details .bottom-detail {
  display: none;
}

.mobile-app .new-document {
  flex: 0 100px;
}

.mobile-app .new-doc {
  width: 100%;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 20px;
  height: 40px;
  font-size: 20px;
  background-color: #4AB84A;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
}

.mobile-app .new-doc.disabled {
  background-color: #c5c5c5 !important;
  box-shadow: none;
}

.mobile-app .doc-edit-container .attach-save-cancel {
  margin-left: 0px;
}

.mobile-app .doc-edit-container .attach-save-cancel .doc-btn {
  flex: 1;
}

.full-screen-rel {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.full-screen-abs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#FullScreenImage .image-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  display: flex;
  z-index: 9;
  visibility: visible;
  transform: translate3d(0px, 0px, 0px);
}

#FullScreenImage .image-container img {
  margin: auto;
}

#FullScreenImage .close-btn {
  top: 0;
  position: fixed;
  right: 0;
  width: 60px;
  height: 60px;
  z-index: 10;
  display: flex;
  margin-top: 10px;
  margin-right: 10px;
  background-color: #5d5d5d;
  border-radius: 30px;
  border: 2px solid white;
  visibility: visible;
  transform: rotate(45deg) translate3d(0px, 0px, 0px);
}

#FullScreenImage .close-btn .cross-vertical {
  margin: auto;
  height: 30px;
  width: 6px;
  top: 14px;
  left: 26px;
  background-color: white;
  position: absolute;
}

#FullScreenImage .close-btn .cross-horizontal {
  margin: auto;
  height: 6px;
  width: 30px;
  top: 26px;
  left: 14px;
  background-color: white;
  position: absolute;
}

.group-by-rows {
  display: flex;
  flex-direction: column;
}

.group-by-row {
  display: flex;
  background-color: teal;
  color: white;
  padding: 5px;
  border-bottom: 1px solid cyan;
  font-weight: bold;
  height: 30px;
  line-height: 18px;
}

.group-text {
  margin-right: auto;
  margin-left: 10px;
}

.query-grid>.group-by {
  color: white;
  font-weight: bold;
  display: flex;
}

.query-grid>.group-by>div {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
}

.pay-period-total {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 5px;
  padding: 5px;
  box-shadow: #c5c5c5 1px 1px 4px;
  border-left: 4px solid white;
  cursor: default;
  position: relative;
}

.pay-period-total .sub-title {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 12px;
  font-weight: bold;
  color: #8d8d8d;
}

.pay-period-total:hover {
  border-left: 4px solid #ffd581;
}

.pay-period-total.selected {
  border-left: 4px solid #fdba30;
  margin-left: 10px;
}

.pay-period-total .title {
  margin-left: 5px;
  font-weight: bold;
  font-size: 15px;
}

.pay-period-total .complete-totals {
  display: flex;
}

.pay-period-total .pending-totals {
  display: flex;
}

.pay-period-total .hours {
  display: flex;
  font-size: 14px;
  flex: 2;
}

.pay-period-total .users {
  display: flex;
  font-size: 14px;
  margin-left: 10px;
  flex: 1;
}

.pay-period-total .qty {
  margin-left: 5px;
  font-weight: 500;
}

.pending-totals .qty {
  color: #183eb2;
}

.complete-totals .qty {
  color: #226640;
}

.grid-widget {
  display: flex;
  flex-direction: column;
  padding: 5px;
  -webkit-overflow-scrolling: touch;
}

.grid-widget .grid-header {
  display: flex;
  flex-direction: row;
  background-color: #252525;
  border-left: 1px solid #252525;
  min-height: 30px;
}

.grid-widget .header-col {
  flex: 1;
  display: flex;
  color: white;
  border-right: 1px solid #dce2e8;
  height: 30px;
  overflow: hidden;
}

.grid-widget .header-col:last-of-type {
  border-right: 1px solid #252525;
}

.grid-widget .header-col * {
  margin: auto;
  margin-left: 5px;
}

.grid-widget .gw-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  border-bottom: 1px solid #d0d5da;
  border-left: 1px solid #d0d5da;
}

.full-height-body .grid-widget .gw-body {
  height: 100%;
}

.full-height-body .grid-widget .gw-body .grid-row:last-child {
  border-bottom: 1px solid #d0d5da;
}

.grid-widget .grid-row {
  min-height: 30px;
  display: flex;
}

.grid-widget .row-cell {
  display: flex;
  flex: 1;
  border-right: 1px solid #d0d5da;
  height: 30px;
  min-width: 0px;
  position: relative;
}

.grid-widget .row-cell:last-of-type {
  border-right: 1px solid #d0d5da;
}

.grid-widget .grid-row.even {
  background-color: #f2f5f7;
}

.grid-widget .grid-row.deleted {
  opacity: .3;
}

.grid-widget .row-cell>* {
  margin: auto;
  display: flex;
  width: 100%;
}

.grid-widget .row-cell>*:not(.control-cell) {
  margin-left: 5px;
}

.grid-widget .grid-header.scroll-buffer {
  padding-right: 17px;
}

.row-cell.clickable {
  cursor: pointer;
}

.row-cell.clickable:hover {
  text-decoration: underline;
}

.row-cell .control-cell {
  padding-left: 5px;
  padding-right: 5px;
}

.row-cell .control-wrapper {
  width: 100%;
  margin: auto;
}

.row-cell .cbox {
  margin: auto;
}

.move-event-tab {
  font-size: 24px !important;
}

.move-event-tab .tab:hover {
  cursor: pointer;
}

.move-event-tab .tab.default.active:before {
  border-top: 5px solid #536142;
}

.side-padding .card-list {
  padding: 8px
}

.row-cell>div:not(.control-cell) {
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  padding-right: 5px;
}

.row-cell ::-webkit-scrollbar {
  height: 8px;
}

.row-cell ::-webkit-scrollbar-thumb {
  background: rgb(163, 163, 163);
}

.row-cell ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.row-cell ::-webkit-scrollbar-track {
  background: #e6e6e6;
}

.grid-widget .form-control {
  margin-left: 0px;
  margin-right: 0px;
}

.grid-widget input[type="date"] {
  width: 130px;
}

.grid-widget input[type="number"] {
  text-align: right;
  line-height: 25px;
}

.grid-widget .control-label {
  display: none;
}

.grid-widget .row-cell .control-wrapper {
  margin: auto;
}

.checkbox-control {
  display: flex;
  flex-direction: column;
  min-height: 0px;
}

.checkbox-control .cbox {
  flex: 1;
  display: flex;
  min-height: 0px;
  padding: 3px;
  width: 26px;
}

.checkbox-control .cbox svg {
  width: 20px;
  height: 20px;
  background-color: white;
}

.checkbox-control .control-label {
  white-space: nowrap;
  overflow: hidden;
}

.radiolookup-control {
  display: flex;
  flex-direction: column;
  min-height: 0px;
}

.radio-lookup-items {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}

.radio-lookup-item {
  display: flex;
  margin-right: 8px;
}

.radio-lookup-label {
  font-size: 10px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 3px;
  cursor: default;
}

.radio-lookup-check {
  margin-top: auto;
  margin-bottom: auto;
  width: 20px;
  height: 20px;
}

.lcr-form .radio-lookup-item {
  background-color: #9ecbff;
  user-select: none;
  padding: 10px 19px;
  margin: 20px 20px 0px 20px;
  box-shadow: 3px 3px 5px grey;
  border-radius: 5px;
}

.lcr-form .radio-lookup-items{
  justify-content: center;
}

.lcr-form .radio-lookup-item:hover {
  background-color: #559CEC;
}

.lcr-form .radio-lookup-label {
  font-size: 1.2rem;
  font-weight: 500;
}

.lcr-form .radio-lookup-item.selected {
  color: white;
  background-color: #242426;
  box-shadow: none;
}

.lcr-form .radio-lookup-check {
  background-color: #9ecbff;
}

.lcr-form .radio-lookup-check {
  display: none;
}

.radio-lookup-item .radio-lookup-check svg {
  width: 20px;
  height: 20px;
  background-color: white;
}

.recur-form {
  width: 225px;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}

.recur-form input[type="radio"], .rec-container input[type="radio"] {
  margin-top: 5px;
}

.recur-form-row {
  padding-bottom: 10px;
}

.recur-form-row input, .recur-form-row select {
  margin-left: 10px;
}

.recur-form div {
  margin-bottom: 7px;
}

.react-calendar__tile--active {
  background: inherit !important;
  color: inherit !important;
}

.disabled-recur, .disabled-recur select {
  color: rgb(177, 177, 177);
}

.recurrence-widget {
  font-size: .9em;
  overflow: hidden;
}

.recurrence-widget input[type='radio'], .recurrence-widget input[type='checkbox'] {
  margin-right: 5px;
}

.recurrence-widget input[type='checkbox'] {
  margin-top: 5px;
  margin-left: 10px;
}

.recurrence-widget input[type='checkbox'], .recurrence-widget input[type='radio'] {
  position: fixed;
  opacity: 0;
  height: 0;
  width: 0;
}

.recur-check.single-check {
  margin-left: 0px;
  margin-top: -1px;
}

.recur-check.single-check+div {
  margin-left: 27px;
}

.recur-radio {
  border-radius: 50%;
  height: 16px !important;
  width: 16px !important;
  margin-left: 0px !important;
  margin-top: 2px;
}

.recurrence-widget .recur-radio.recur-check:after {
  width: 3px;
  height: 7px;
  border-width: 0 2px 3px 0;
  left: 4px;
  top: 1px;
}

.recur-radio+div {
  margin-left: 20px;
  margin-bottom: 10px;
  text-align: left;
}

.recur-check {
  margin-left: -10px;
  position: fixed;
  height: 21px;
  width: 21px;
  border: 1px solid black;
}

.recurrence-widget .recur-check:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.recurrence-widget input:checked~.recur-check:after {
  display: block;
}

.black-link, .black-link:visited {
  color: black !important;
}

.recur-check:after {
  content: "";
  position: absolute;
  display: none;
}

.recurrence-widget input:checked~.recur-check {
  background-color: #ffbd59;
}

.mysales-address-search {
  min-height: 250px;
}

.rec-container {
  display: flex;
  max-height: 90%;
  min-height: 400px;
}

#daily1, #daily2, #daily3, #weekly1, #weekly2, #monthly1, #monthly2 {
  margin-right: 5px;
}

.fixed-flex {
  align-items: stretch;
  padding: 15px;
  margin-top: 10px;
  overflow: auto;
}

.sched-type-selector {
  width: 130px;
  padding-top: 30px;
  border-left: 2px solid silver;
  border-right: 2px solid silver;
}

.sched-type-selector div {
  margin-bottom: 15px;
}

.rec-form-options {
  width: 400px;
  border-right: 2px solid silver;
}

.sched-type-selector {
  width: 130px;
  padding-top: 30px;
  border-left: 2px solid silver;
  border-right: 2px solid silver;
}

.sched-type-selector div {
  margin-bottom: 15px;
}

.rec-form-options {
  width: 400px;
  border-right: 2px solid silver;
}

.rec-child2 {
  width: 50%;
  padding: 10px;
}

.rec-child2>div {
  border: 1px solid rgb(144, 144, 144);
  border-radius: 4px;
  min-height: 90%;
  padding: 8px;
}

.rec-child>div>div {
  margin-bottom: 15px;
}

.empty-title-header {
  height: 20px;
  width: 100%;
  background-color: #536142;
  margin-bottom: 15px !important;
  margin-top: 15px;
}

.rec-child h3, .rec-child2 h3, .rec-child h4, .rec-child2 h4, .recur-form-row h4 {
  text-align: center;
  margin-bottom: 10px;
  background-color: #536142;
  color: white;
}

.med-margin-bottom {
  margin-bottom: 85px;
}

.rec-grid-title {
  font-weight: bold;
  font-size: 1.1em;
  margin-left: 10px;
  margin-top: 6px;
}

.rec-grid-title .text-widget div {
  margin: 0;
}

.rec-child>div, .rec-child2>div {
  margin-bottom: 23px;
}

.rec-child label {
  text-align: center;
}

.rec-child label, .rec-child2 label {
  user-select: none;
}

.check-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  font-size: .85em;
  align-items: stretch;
}

.check-child {
  margin: 1px;
  padding: 1px;
  width: 35px;
}

.check-child input {
  margin-right: 3px;
}

.check-child div div {
  padding-top: 20px;
}

.small-textbox {
  width: 60px;
  height: 22px;
}

.submit-rec-btn {
  user-select: none;
  padding: 4px;
}

.configure-evt-btn {
  user-select: none;
  padding: 2px;
}

.configure-evt-btn p {
  margin-top: 5px;
}

.recur-save-btn {
  margin: 15px 15px 50px 10px;
}

.recur-save-btn .text-widget {
  background-color: #226640;
  color: white;
  border-radius: 10px;
  padding: 5px 50px;
  float: right;
}

.recur-save-btn .text-widget:hover {
  background-color: #4E8566;
  cursor: pointer;
}

.recur-view-btn .text-widget {
  background-color: #dd7c0e;
  color: white;
  border-radius: 20px;
  padding: 2px;
  margin: 10px auto;
  width: 200px;
  user-select: none;
}

.recur-view-btn .text-widget:hover {
  background-color: #f18811;
  cursor: default;
}

.recur-view-btn, .recur-save-btn {
  font-size: 1.05rem;
}

.timesheet-widget {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.sheet-days {
  height: 65px;
  display: flex;
}

.sheet-day {
  flex: 1;
  color: white;
  background-color: #a5a5a5;
  margin-left: 1px;
  margin-right: 1px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.sheet-day.weekend {
  background-color: #262626;
  color: #a5a5a5;
}

.sheet-day.active {
  background-color: #FDBA30;
  color: black;
}

.sheet-day>*:not(.total) {
  margin-left: auto;
  margin-right: auto;
}

.sheet-day .label {
  font-size: 20px;
  font-weight: bold;
}

.sheet-day .date {
  margin-top: -4px;
  font-size: 14px;
}

.sheet-day .total {
  margin: 4px;
  margin-top: 1px;
  height: 17px;
  font-size: 13px;
  display: flex;
  background-color: white;
  color: black;
  font-weight: 500;
}

.sheet-day .total * {
  margin: auto;
}

.timesheet-header {
  height: 26px;
  display: flex;
  background-color: #262525;
  margin-bottom: 1px;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.timesheet-header .user {
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.timesheet-header .date-picker {
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 12px;
  height: 18px;
}

.timesheet-header .pp-number {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.timesheet-header .sheet-status {
  display: flex;
  margin: auto;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
}

.timesheet-header .sheet-status>div {
  display: flex;
}

.timesheet-header .sheet-status .check-approve {
  color: #3c9770;
  font-weight: bold;
  margin-left: 5px;
}

.timesheet-header .sheet-status .check-submit {
  color: #4672f9;
  font-weight: bold;
  margin-left: 5px;
}

.period-select {
  background-color: #262525;
  color: white;
  width: 20px;
  display: flex;
  cursor: pointer;
}

.period-select * {
  margin: auto;
}

.time-records {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  padding-bottom: 200px;
}

.time-record {
  display: flex;
  min-height: 66px;
  border-bottom: 1px solid #bcc1c6;
  border-left: 1px solid #bcc1c6;
  border-right: 1px solid #bcc1c6;
  position: relative;
  overflow: hidden;
}

.time-edit {
  display: flex;
  border-bottom: 1px solid #8c8e90;
  border-left: 1px solid #8c8e90;
  border-right: 1px solid #8c8e90;
  height: 66px;
}

.time-edit .time-entry {
  flex: 1;
  height: 65px;
}

.time-records .time-edit .time-code {
  border-bottom: none;
}

.time-records .time-edit .time-comment {
  flex: 0 300px;
}

.time-edit.even, .time-record.even {
  background-color: #f2f5f7;
}

.time-edit.even input, .time-record.even input {
  background-color: #f2f5f7;
}

.time-edit.even select, .time-record.even select {
  background-color: #f2f5f7;
}

.time-edit.even textarea, .time-record.even textarea {
  background-color: #f2f5f7;
}

.time-record select:disabled {
  background-color: unset !important;
  color: black;
  opacity: 1;
}

.time-record input:disabled {
  background-color: unset !important;
  color: black;
  opacity: 1;
}

.time-record textarea:disabled {
  background-color: unset !important;
  color: black;
  opacity: 1;
}

.time-code {
  flex: 0 175px;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  border-left: 1px solid #dce2e8;
}

.time-code .time {
  display: flex;
  flex: 1;
}

.time-code .time input {
  text-align: right;
  font-weight: bold;
  width: 100%;
  border: none;
  line-height: 30px;
}

.time input::-webkit-inner-spin-button {
  margin-left: 5px;
}

.time-code .wage-code {
  display: flex;
  flex: 1;
}

.time-code .wage-code select {
  width: 100%;
  border: none;
  border-bottom: 1px solid #dce2e8;
}

.time-comment {
  flex: 0 300px;
  display: flex;
}

.time-comment textarea {
  font-family: "Source Sans Pro", "Segoe UI", Arial, Tahoma;
  resize: none;
  flex: 1;
  padding: 3px;
  border: none;
  border-left: 1px solid #dce2e8;
  margin-right: 20px;
}

.time-comment.no-pad textarea {
  margin-right: 0px;
}

.time-entity {
  display: flex;
  flex: 1;
  min-width: 0px;
  position: relative;
}

.time-entity .time-entity-details {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.time-entity .top-row {
  display: flex;
  font-weight: 500;
  height: 23px;
  padding-left: 5px;
}

.time-entity .top-row .right-align {
  margin: auto;
  margin-right: 40px;
  font-size: 14px;
}

.time-entity .icon {
  flex: 0 37px;
  display: flex;
}

.time-entity .icon img {
  width: 32px;
  height: 32px;
  margin: auto;
  margin-right: 0px;
  margin-top: 4px;
}

.time-entity .top-row .name:not(.no-link):hover {
  text-decoration: underline;
  cursor: default;
}

.time-entity .top-row .date {
  margin-left: 10px;
  color: #008ac1;
}

.time-entity .bottom-row {
  display: flex;
  height: 43px;
  font-size: 14px;
}

.time-entity *:not(.text-label) {
  overflow: hidden;
}

.time-entity .bottom-row .text-1 {
  flex: 3;
  display: flex;
  padding-left: 5px;
}

.time-entity .bottom-row .text-2 {
  flex: 1;
  display: flex;
  padding-left: 5px;
}

.time-entity.lr-entity .bottom-row .text-1 {
  flex: 3;
}

.time-entity.lr-entity .bottom-row .text-2 {
  flex: 3;
}

.time-entity .bottom-row .text-3 {
  flex: 1;
  display: flex;
  padding-left: 5px;
}

.time-entity .text-label {
  font-weight: 500;
  margin-right: 5px;
}

.time-entity .reselect {
  position: absolute;
  right: 0px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.time-entity .reselect svg {
  width: 20px;
  fill: #dd7c0e;
}

.bottom-bar {
  display: flex;
  background-color: #262525;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin-top: 1px;
  height: 23px;
}

.bottom-bar .total {
  flex: 1;
  display: flex;
}

.bottom-bar .total * {
  margin: auto;
}

.bottom-bar .period-total {
  flex: 0 100px;
  display: flex;
}

.bottom-bar .period-total * {
  margin: auto;
}

.time-entry {
  display: flex;
  height: 66px;
}

.new-record {
  min-height: 66px;
}

.time-entry .project-type {
  flex: 0 175px;
  position: relative;
}

.time-entry .project-type select {
  width: 100%;
  border: none;
  border-bottom: 1px solid #dce2e8;
  border-left: 1px solid #dce2e8;
  height: 33px;
}

.time-entry .entity-search {
  flex: 1;
}

.time-entry .entity-search input {
  width: 100%;
  border: none;
  height: 33px;
  border-bottom: 1px solid #dce2e8;
  border-left: 1px solid #dce2e8;
  padding-left: 5px;
}

.time-entry .time-code {
  flex: 0 175px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dce2e8;
}

.time-entry .time-comment {
  flex: 0 1 301px;
  display: flex;
  border-bottom: 1px solid #dce2e8;
  border-right: 1px solid #dce2e8;
}

.entity-search .search-results {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 300px;
  border-left: 1px solid #dce2e8;
  border-bottom: 1px solid #dce2e8;
  border-right: 1px solid #dce2e8;
  padding-bottom: 5px;
  position: relative;
  z-index: 10;
  background-color: white;
}

.entity-search .search-results .result-item {
  cursor: pointer;
  margin: 5px;
  margin-bottom: 0px;
  box-shadow: #c5c5c5 1px 1px 4px;
  border-left: 4px solid white;
  height: 65px;
}

.entity-search .search-results .result-item.active {
  border-left: 4px solid #fdba30;
  box-shadow: burlywood 1px 2px 4px;
}

.delete-row {
  position: absolute;
  right: 0px;
  top: 27px;
  width: 22px;
  height: 24px;
  cursor: pointer;
}

.delete-row svg {
  fill: red;
}

.timebanks-widget {
  display: flex;
}

.time-bank {
  flex: 1;
  display: flex;
  position: relative;
  margin: 20px;
  padding: 5px;
  background-color: lightgoldenrodyellow;
  box-shadow: 1px 2px 7px burlywood;
  min-width: 0px;
  border-radius: 5px;
}

.bank-label {
  margin: auto;
  font-weight: 500;
  font-size: 18px;
}

.bank-name, .bank-balance {
  display: flex;
}

.bank-name *, .bank-balance * {
  margin: auto;
}

.bank-controls {
  position: absolute;
  font-size: 13px;
  font-weight: 500;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.bank-controls>div {
  margin-bottom: 5px;
}

.bank-controls>div:hover {
  text-decoration: underline;
}

.accrual-settings {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.accrual-settings .settings-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

.time-bank .back-button {
  position: absolute;
  cursor: pointer;
  width: 24px;
  height: 24px;
  top: 5px;
  left: 0px;
}

.time-bank .back-button svg {
  fill: black;
  width: 24px;
  height: 24px;
}

.time-bank .line-item {
  display: flex;
  padding: 5px;
  font-size: 14px;
}

.time-bank .line-item .label {
  flex: 3;
  min-width: 0px;
}

.time-bank .line-item input {
  flex: 2;
  min-width: 0px;
  text-align: right;
  height: 23px;
  line-height: 23px;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #ccc;
}

.time-bank .line-item input::-webkit-inner-spin-button {
  margin-left: 5px;
}

.hourly-header {
  display: flex;
  font-weight: bold;
  font-size: 15px;
  padding: 5px;
}

.hourly-header :nth-child(1) {
  flex: 3;
  min-width: 0px;
}

.hourly-header :nth-child(2) {
  flex: 2;
  min-width: 0px;
}

.manual-adjustment {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.time-bank .item-value {
  display: flex;
  flex: 2;
  min-width: 0px;
}

.time-bank .item-value :first-child {
  margin-right: 5px;
}

.time-bank .item-value>div {
  width: 45px;
}

.time-bank .line-item select {
  flex: 2;
  min-width: 0px;
  height: 23px;
  border-radius: 4px;
  border: 1px solid #ccc;
  text-indent: 5px;
}

.time-bank .line-item textarea {
  resize: none;
  flex: 8;
  min-height: 65px;
  padding: 5px;
  font-family: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.bank-header {
  display: flex;
  font-weight: 500;
  font-size: 18px;
}

.bank-header * {
  margin: auto;
}

.time-bank .static-value {
  flex: 2;
}

.manual-adjustment .button-container {
  flex: 1;
  display: flex;
  max-height: 30px;
}

.manual-adjustment button {
  width: 70px;
  padding: 2px;
  margin-left: auto;
  margin-right: 5px;
  margin-top: 5px;
}

.manual-adjustment button:disabled {
  background-color: #c1c1c1;
}

.leave-request {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #e4e4e4;
  flex: 1;
  margin: 5px;
  margin-top: 0px;
  width: unset !important;
}

.leave-request .request-header {
  font-size: 18px;
  font-weight: 500;
}

.leave-request .line-item {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.leave-request .line-item textarea {
  resize: none;
  height: 100px;
  width: 400px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: inherit;
  padding: 5px;
}

.leave-request .line-item input {
  width: 225px;
  height: 28px;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #ccc;
}

.leave-request .line-item input[type=number] {
  width: 120px;
  text-align: right;
  line-height: 28px;
}

.leave-request select {
  width: 225px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.lr-submit {
  background-color: #183eb2;
  color: white;
  border-radius: 15px;
  height: 25px;
  width: 75px;
  display: flex;
  cursor: pointer;
  margin-top: 15px;
}

.lr-submit.disabled {
  background-color: darkgrey !important;
  cursor: default;
}

.lr-submit:hover {
  background-color: #4672f9;
}

.lr-submit * {
  margin: auto;
  font-size: 14px;
}

.day-select {
  position: absolute;
  right: 5px;
  top: 0px;
  display: flex;
  flex-direction: column;
}

.day-select-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 176px;
  position: absolute;
  top: 15px;
  right: 2px;
  border: 1px solid #8c8e90;
}

.day-select-label {
  font-weight: 500;
  font-size: 18px;
  display: flex;
  flex: 0 30px;
}

.day-select-label * {
  margin: auto;
}

.day-select .triangle {
  position: absolute;
  right: 0px;
  top: -2px;
  border-color: transparent transparent transparent black;
  width: 12px;
  height: 11px;
  cursor: pointer;
}

.day-select-items {
  display: flex;
  flex-direction: column;
  overflow: auto;
  border: 1px solid #8c8e90;
  background-color: white;
  z-index: 10;
  margin-top: 10px;
  position: absolute;
  right: -1px;
  top: 20px;
  max-height: 200px;
  width: 176px;
}

.day-item {
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  white-space: nowrap;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  background-color: #a5a5a5;
  cursor: pointer;
  color: white;
  margin-top: 2px;
  margin-left: 1px;
  margin-right: 1px;
}

.day-item.weekend {
  background-color: #262626;
  color: #a5a5a5;
}

.leave-requested .total {
  color: #255bff;
  background-color: rgb(216, 220, 233);
  font-weight: bold;
}

.day-item.active {
  background-color: #FDBA30;
  color: black;
}

.day-item:hover {
  border-left: 3px solid #ffcb5c;
  border-right: 3px solid #ffcb5c;
}

.day-item>* {
  flex: 1;
}

.tr-container {
  position: relative;
}

.delete-control {
  margin: auto;
  display: flex;
  pointer-events: all !important;
}

.delete-control svg {
  fill: red;
  opacity: .75;
  width: 26px;
  height: 26px;
}

.delete-control:hover svg {
  opacity: 1;
}

.error-text {
  color: red;
}

.top-align .search-bar .center-content {
  padding-top: 0px;
}

.complete-check-container .text-widget>div {
  margin: auto;
  margin-left: 30px;
}

.complete-check {
  border: 5px solid white;
  background-color: green;
  width: 65px;
  height: 65px;
  border-radius: 40px;
  position: relative;
}

.complete-check .line-1 {
  background-color: white;
  width: 32px;
  height: 7px;
  top: 25px;
  left: 16px;
  transform: rotate(-55deg);
  position: absolute;
}

.complete-check .line-2 {
  background-color: white;
  width: 15px;
  height: 7px;
  top: 33px;
  left: 13px;
  transform: rotate(35deg);
  position: absolute;
}

.has-handle .resize-handle {
  position: absolute;
  right: 1px;
  width: 3px !important;
  height: 100% !important;
  top: 0;
  border-right: 2px solid grey;
  cursor: ew-resize;
  z-index: 2000;
  margin-left: 1px;
}

.has-handle {
  padding-right: 3px;
}

.contacts-search .center-content {
  padding-left: 0px;
  padding-right: 0px;
}

.border-list {
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}

.typeahead-list-form .textbox-control {
  margin-top: 5px;
}

.typeahead-list-form .typeahead-container {
  height: unset;
}

.typeahead-list-form .typeahead-dropdown {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
}

.map-tab-typeahead .typeahead-dropdown .typeahead-item, .typeahead-list-form .typeahead-dropdown .typeahead-item {
  cursor: default;
}

.map-tab-typeahead .typeahead-dropdown .text-3, .typeahead-list-form .typeahead-dropdown .text-3 {
  top: unset;
  bottom: 5px;
}

.map-tab-typeahead .typeahead-dropdown .text-2, .typeahead-list-form .typeahead-dropdown .text-2 {
  height: 19px;
}

.map-tab-typeahead .typeahead-dropdown .text-1, .typeahead-list-form .typeahead-dropdown .text-1 {
  top: unset;
  bottom: 5px;
  font-weight: 500;
  font-size: 13px;
}

.map-tab-typeahead {
  overflow: unset !important;
}

/* .map-tab-typeahead .typeahead-container {
  height: unset;
} */

.map-tab-typeahead .typeahead-dropdown {
  margin-left: 5px;
  position: relative;
}

.map-tab-typeahead .textbox-control {
  margin-top: 0px;
}

.save-txt-btn {
  background-color: #226640;
  color: white;
  border-radius: 50px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  font-size: 15px;
  height: 30px;
  user-select: none;
}

.save-txt-btn:hover {
  background-color: #4E8566;
}

.cancel-txt-btn {
  background-color: #dd7c0e;
  color: white;
  border-radius: 50px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  font-size: 15px;
  height: 30px;
  user-select: none;
}

.cancel-txt-btn:hover {
  background-color: #f18811;
}

.map-tab-typeahead {
  pointer-events: none;
}

.map-tab-typeahead .form-control .textbox-control {
  pointer-events: all;
}

.modal-layer .map-edit-wrapper {
  border: 1px solid #e4e4e4;
  border-bottom: none;
}

.tenant-display {
  display: flex;
  font-size: 17px;
  font-weight: bold;
}

.tenant-display>div {
  margin: auto;
}

.tenant-picker {
  display: flex;
}

.tenant-picker select {
  height: 25px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #252525;
  outline: 0;
  height: 25px;
  margin: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.day-total {
  display: flex;
}

.day-total .spacer {
  flex: 1;
}

.day-total .total-amount {
  display: flex;
  flex: 0 176px;
  background-color: #f2f5f7;
  height: 33px;
  border: 1px solid #dce2e8;
  border-top: none;
}

.day-total .total-amount>div {
  margin: auto;
  margin-right: 5px;
  font-size: 14px;
}

.day-total .spacer-right {
  flex: 0 300px;
}

option:disabled {
  color: red;
}

.dropdown-placeholder-text {
  position: absolute;
  top: 7px;
  left: 5px;
  color: #757575;
  font-size: 13px;
  pointer-events: none;
}

.grid-row .row-cell .grid-warning-icon {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  border: 2px solid #b14531;
  display: flex;
  cursor: default;
  position: absolute;
  right: 5px;
  top: 3px;
  background-color: white;
  overflow: hidden;
}

.grid-row .row-cell .grid-warning-icon>div {
  margin-left: 7px;
  margin-right: auto;
  margin-bottom: auto;
  line-height: 18px;
  font-size: 20px;
  font-weight: 500;
  color: #b14531;
}

.grid-row .row-cell.sort-number>div:not(.grid-warning-icon) {
  display: block;
  text-align: right;
  padding-right: 5px;
}

.grid-row .row-cell.sort-number>div.grid-warning-icon {
  right: unset;
  margin-left: 5px;
}

.grid-warning-details {
  display: none;
  z-index: 999999;
}

.grid-warning-icon:hover .grid-warning-details {
  display: block;
  position: absolute;
  width: 100px;
  height: 25px;
  top: 28px;
  left: -180px;
}

.warning-details-wrapper {
  position: fixed;
  font-size: 14px;
  background-color: white;
  height: 30px;
  padding-right: 10px;
  padding-left: 10px;
  line-height: 24px;
  border-radius: 15px;
  border: 2px solid #b14531;
  z-index: 999999;
}

.approved-grid-label, .submitted-grid-label {
  display: flex;
  font-size: 15px;
  font-weight: bold;
  margin-top: 7px !important;
}

.submitted-grid-label>div:first-child {
  color: #183eb2;
  margin-right: 5px;
}

.approved-grid-label>div:first-child {
  color: #226640;
  margin-right: 5px;
}

.selector-item {
  display: flex;
}

.selector-preview {
  height: 100%;
  width: auto;
  margin: auto;
}

.primary-bg {
  background-color: #536042 !important;
}

.secondary-bg {
  background-color: #d3dccb !important;
}

.secondary-bg-content>div {
  background-color: #d3dccb !important;
}

.left-pane-header {
  font-weight: 500;
  font-size: 20px;
  color: white;
  border-radius: 5px;
}

.medium-header {
  font-weight: 500;
  font-size: 16px;
  color: white;
  border-radius: 5px;
}

.transparent-list .card-list {
  background-color: transparent;
}

.primary-bg-headers .card-list .top-level-parent-card {
  background-color: #536042;
}

.top-tabs {
  display: flex;
  background-color: white;
}

.top-tabs .card-list {
  overflow: hidden;
  display: flex;
}

.top-tabs .card-item {
  min-width: 120px;
  max-width: 120px;
  padding-bottom: 12px !important;
}

.top-tabs .card-item {
  display: flex;
  cursor: default;
  position: relative;
  padding-bottom: 8px;
  border: 1px solid #888b8e;
  border-bottom: none;
  width: 100%;
  background-color: #252525;
  color: white;
  user-select: none;
  margin-top: auto;
  margin-left: 5px !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-left: unset;
}

.top-tabs .card-item:hover {
  background-color: #252525 !important;
}

.top-tabs .card-item.selected:hover {
  background-color: white !important;
}

.top-tabs .card-item.selected, .tab.vertical-tab.active {
  background-color: white;
  color: black;
  cursor: default;
}

.top-tabs .card-item.selected:before {
  margin-top: -5px;
}

.top-tabs .card-item.selected:before, .tab.vertical-tab.active:before {
  content: '';
  border-top: 5px solid #fdba30;
  width: 100%;
  position: absolute;
  border-radius: 4px 4px 0px 0px;
}

.top-tabs .card-item div, .tab.vertical-tab div {
  margin: auto;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: .5px;
  white-space: nowrap;
  margin-top: 2px;
}

.banner-list .card-list {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #d3dccb;
}

.banner-list .card-list :first-child {
  margin-top: auto;
}

.banner-list .card-list div {
  margin-bottom: 5px;
}

.banner-list .card-item {
  border-radius: 8px;
  border: 2px solid #536042;
}

.banner-list .card-item .text-1 {
  margin: auto;
  font-size: 18px;
  margin-top: -2px;
  color: #536042;
}

.banner-list .card-item.selected {
  background-color: #536042;
}

.banner-list .card-item.selected .text-1 {
  color: white;
}

.black-background {
  background-color: black !important;
}

.white-text {
  color: white;
}

.modal-close-btn {
  color: white;
}

.grid-content {
  display: flex;
  width: 100%;
  min-height: 0px;
}

.query-layout {
  display: flex;
  flex-direction: column;
  position: relative;
}

.layout-loading {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, .2);
}

.layout-loading .layout-spinner-container {
  margin: auto;
}

.layout-loading .layout-spinner-container svg {
  fill: black;
}

.query-layout .layout-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px;
  padding-top: 0px;
  margin-bottom: 10px;
  overflow: auto;
  min-height: 50%;
}

.query-layout .layout-list .layout-item {
  flex: 0 30px;
  background-color: white;
  margin-top: 2px;
  margin-bottom: 2px;
  box-shadow: 1px 1px 2px 2px rgba(197, 197, 197, 1);
  display: flex;
  cursor: default;
  font-weight: 500;
  position: relative;
}

.query-layout .layout-list .layout-item>* {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  padding: 4px;
}

.layout-list .layout-item:hover {
  background-color: #ffefcb;
}

.layout-list .layout-item.active {
  background-color: #ffcb5c;
}

.layout-list .layout-list-header {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 5px;
}

.layout-item .layout-name {
  flex: 1;
  z-index: 3;
  margin-right: 30px;
}

.layout-item .delete-layout {
  position: absolute;
  right: 0px;
  width: 100px;
  z-index: 1;
  background-color: inherit;
  transition: right .10s ease-out;
}

.layout-item.active:hover .delete-layout {
  right: 30px;
  transition: right .15s ease-out;
  display: flex;
  border-right: 1px solid gray;
}

.layout-item .delete-icon {
  position: absolute;
  right: 0px;
  display: flex;
  z-index: 0;
  background-color: white;
  height: 30px;
}

.layout-item .delete-icon svg {
  fill: gray;
}

.layout-item .delete-icon:hover svg {
  fill: #b14531;
}

.query-layout .edit-layout {
  display: flex;
  flex-direction: column;
  flex: 0 280px;
  position: relative;
  overflow: auto;
}

.query-layout .layout-buttons {
  display: flex;
  padding-top: 10px;
  border-top: 2px solid darkgray;
}

.layout-load-btn {
  display: flex;
  flex: 0 30px;
  border: 1px solid #dd7c0e;
  border-radius: 15px;
  background-color: white;
  color: #dd7c0e;
  font-weight: 500;
  cursor: default;
  margin-bottom: 5px;
}

.layout-load-btn.active {
  color: white;
  background-color: #dd7c0e;
  border: 1px solid white;
}

.layout-load-btn div {
  margin: auto;
}

.layout-control {
  margin-top: 2px;
  padding-left: 2px;
  padding-right: 2px;
}

.layout-control div {
  font-size: 12px;
  color: #505050;
  margin-left: 2px;
}

.layout-control textarea {
  resize: none;
  width: 100%;
  margin: 0px;
  font-family: inherit;
  font-size: inherit;
  padding-top: 2px;
  font-size: 14px;
  padding-left: 3px;
  height: 80px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: 0;
}

.layout-control input {
  width: 100%;
  height: 25px;
  padding-left: 3px;
  margin: 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.layout-control input:disabled {
  color: black;
}

.layout-control textarea:disabled {
  color: black;
}

.layout-buttons {
  display: flex;
  padding: 2px;
}

.layout-buttons .layout-btn {
  display: flex;
  flex: 1;
  border: 1px solid #dd7c0e;
  border-radius: 15px;
  background-color: white;
  color: #dd7c0e;
  font-weight: 500;
  cursor: default;
  padding: 2px;
  margin-left: 3px;
  margin-right: 3px;
}

.layout-buttons .layout-btn.active:hover {
  background-color: #f0870f;
}

.layout-load-btn.active:hover {
  background-color: #f0870f;
}

.layout-buttons .layout-btn.active {
  color: white;
  background-color: #dd7c0e;
  border: 1px solid white;
}

.layout-buttons .layout-btn div {
  margin: auto;
}

.layout-control textarea:hover, .layout-control input:hover {
  border-color: #FFC233;
}

.layout-control textarea:focus, .layout-control input:focus {
  border-color: #FFC233;
  box-shadow: 0 0 0 1px #FFC233;
  -webkit-box-shadow: 0 0 0 1px #FFC233;
  outline: 0;
  outline-offset: -2px;
}

.manual-lat-long-div {
  text-align: center;
  margin: 5px 20px;
  border-bottom: 1px solid black;
}

.mobile-manual-lat-long-div {
  text-align: center;
  margin: 5px 20px;
}

#manual-lat, #manual-long {
  border-radius: 4px;
  border: 1px solid #ccc;
  height: 20px;
  padding: 4px;
}

#manual-long {
  margin-top: 8px;
}

#manual-lat:hover, #manual-long:hover {
  border-color: #fdc34e;
}

#manual-lat:focus, #manual-long:focus {
  border-radius: 4px;
  border: 2px solid #fdc34e;
  outline: none;
}

.checkbox-bar {
  display: flex;
  padding-bottom: 5px;
  flex-direction: column;
  height: 57px;
}

.layout-cbox {
  display: flex;
  min-height: 0px;
}

.layout-cbox .cbox {
  display: flex;
  min-height: 0px;
  padding: 3px;
  width: 26px;
}

.layout-cbox .cbox svg {
  width: 20px;
  height: 20px;
}

.layout-cbox .control-label {
  margin-top: auto;
  margin-bottom: auto;
  color: #505050;
  font-size: 10px;
}

.layout-cbox.default-cbox {
  position: absolute;
  right: 5px;
}

.share-with-container .control-label {
  margin-top: auto;
  margin-bottom: auto;
  color: #505050;
  font-size: 10px;
}

.ag-grid-widget {
  display: flex;
  flex: 1;
  padding: 5px;
  position: relative;
}

.ag-grid-widget.hidden-ag-grid {
  visibility: hidden;
  pointer-events: none;
}

.ag-grid-widget>div {
  flex: 1;
}

.ag-lookup-filter {
  max-height: 200px;
  max-width: 300px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: 0px;
}

.ag-filter-item {
  display: flex;
  flex: 1;
  padding: 3px;
}

.ag-filter-item>div {
  margin-left: 5px;
  margin-top: -2px;
}

.ag-filter-item:hover>div {
  color: #1543c4;
}

.ag-top-bar-control {
  position: absolute;
  right: 14px;
  top: 10px;
  z-index: 2;
}

.ag-grid-button {
  width: 80px;
  height: 26px;
  border: 2px solid #bdc3c7;
  display: flex;
  cursor: pointer;
  font-size: 13px;
  color: black;
  border-radius: 16px;
  background-color: white;
  font-weight: 500;
}

.ag-grid-button:hover {
  background-color: #fbfcfc;
  /* background-color: #ecf0f1; */
}

.ag-grid-button div {
  margin: auto;
}

.aggregate-selector {
  display: flex;
}

.aggregate-selector div {
  margin-right: 5px;
}

.ag-floating-bottom-container .ag-row:first-child {
  color: black;
  background-color: #f5f7f7;
}

.ag-grid-row-count {
  position: absolute;
  display: flex;
  z-index: 2;
  right: 340px;
  height: 36px;
  font-size: 14px;
  color: black;
  font-weight: 500;
}

.ag-grid-row-count-v2 {
  position: absolute;
  display: flex;
  z-index: 2;
  right: 240px;
  height: 36px;
  font-size: 14px;
  color: black;
  font-weight: 500;
  margin-top: -37px;
}

.center-justify {
  justify-content: center;
}

.grid-row .row-cell.center-justify>div {
  width: 100% !important;
  justify-content: center;
  text-align: center !important;
}

.grid-row .row-cell.left-justify>div {
  width: 100% !important;
  justify-content: left;
  text-align: left !important;
  margin-left: 5px !important;
}

.grid-row .row-cell.right-justify>div {
  width: 100% !important;
  justify-content: right;
  text-align: right !important;
  margin-right: 5px !important;
}

.right-justify {
  justify-content: right;
}

.ag-grid-widget .right-justify span {
  margin-right: 5px;
  float: right;
}

.ag-grid-row-count-v2 div, .ag-grid-row-count div {
  margin: auto;
}

.ag-theme-balham .ag-ltr .ag-column-drop-horizontal {
  padding-left: 90px !important;
}

.ag-grid-refresh-arrow {
  position: absolute;
  display: flex;
  z-index: 8;
  height: 27px;
  width: 27px;
  font-size: 14px;
  color: black;
  display: flex;
  border: 2px solid #bdc3c7;
  border-radius: 50px;
  left: 55px;
  top: 40px;
  background-color: white;
  cursor: pointer;
}

.ag-grid-refresh-arrow-v1 {
  position: absolute;
  display: flex;
  z-index: 8;
  height: 27px;
  width: 27px;
  font-size: 14px;
  color: black;
  display: flex;
  border: 2px solid #bdc3c7;
  border-radius: 50px;
  left: 15px;
  top: 40px;
  background-color: white;
  cursor: pointer;
}

.ag-grid-refresh-arrow:hover, .ag-grid-refresh-arrow-v1:hover {
  background-color: #fbfcfc;
}

.ag-grid-refresh-arrow .ag-svg-container, .ag-grid-refresh-arrow-v1 .ag-svg-container {
  margin: auto;
  display: flex;
}

.ag-grid-refresh-arrow .ag-svg-container svg, .ag-grid-refresh-arrow-v1 .ag-svg-container svg {
  fill: #313434;
}

.ag-grid-refresh-arrow:hover .ag-svg-container svg, .ag-grid-refresh-arrow-v1:hover .ag-svg-container svg {
  fill: #414447;
}

.ag-grid-clear-filters-v2, .ag-grid-clear-filters, .ag-grid-excel-export, .clear-geo-filter-btn, .ag-grid-clear-layout {
  position: absolute;
  display: flex;
  z-index: 8;
  height: 36px;
  font-size: 14px;
  color: black;
  display: flex;
}

.green-clear-filters div {
  background-color: #d9f0e3 !important;
}

.green-clear-filters div:hover {
  background-color: #ccebda !important;
}

.ag-grid-clear-filters-v2 div, .ag-grid-clear-filters div, .ag-grid-excel-export div, .clear-geo-filter-btn div, .ag-grid-clear-layout div {
  margin: auto;
  border: 2px solid #bdc3c7;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 80px;
  text-align: center;
  border-radius: 16px;
  background-color: white;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  user-select: none;
}

.ag-grid-clear-filters-v2.disabled div, .ag-grid-clear-layout.disabled div {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

.ag-grid-clear-filters-v2:not(.disabled) div:hover, .ag-grid-clear-filters:not(.disabled) div:hover, .ag-grid-excel-export:not(.disabled) div:hover, .ag-grid-clear-layout:not(.disabled) div:hover, .ag-grid-button:hover, .update-layout-btn:hover, .clear-geo-filter-btn div:hover {
  background-color: #eeeeee;
}

.ag-grid-clear-filters {
  right: 213px;
}

.ag-grid-clear-filters-v2 {
  right: 103px;
}

.ag-grid-clear-layout {
  right: 209px !important;
}

.ag-grid-excel-export {
  right: 103px;
}

.clear-geo-filter-btn div {
  background-color: #fbfcfc;
}

.clear-geo-filter-btn {
  right: 340px;
  top: -2px;
}

.ag-grid-query-name {
  position: absolute;
  z-index: 2;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: left;
  min-height: 36px;
  font-size: 14px;
  font-weight: 600;
  color: black;
  pointer-events: none;
  margin-left: 25px;
}

.ag-grid-query-name.v-2 {
  margin-top: -37px;
}

.ag-grid-query-name>div {
  margin: auto;
  display: flex;
  margin-top: 9px;
}

.ag-grid-query-name .layout {
  margin-left: 30px;
}

.ag-grid-query-name .update-layout-btn {
  margin-left: 10px;
  display: flex;
  border: 2px solid #bdc3c7;
  margin-top: -2px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 16px;
  font-size: 13px;
  background-color: white;
  cursor: pointer;
  pointer-events: initial;
  padding-left: 5px;
  padding-right: 5px;
}

.ag-grid-query-name .update-layout-btn:hover {
  background-color: #eeeeee;
}

.ag-grid-query-name .update-layout-btn div {
  margin: auto;
  padding: 1px;
  padding-left: 7px;
  padding-right: 7px;
}

.share-with {
  display: flex;
  flex-direction: column;
}

.share-with-container {
  position: relative;
  display: flex;
}

.share-with-items .user-tenant-tabs {
  display: flex;
}

.share-with-items .user-tenant-tabs div {
  flex: 1;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  cursor: default;
}

.share-with-items .user-tenant-tabs div:hover {
  text-decoration: underline;
}

.share-with-items {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 180px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  right: -200px;
  top: -160px;
  border: 1px solid darkgray;
  width: 190px;
  font-size: 14px;
}

.share-with-item {
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: default;
}

.share-with-item:hover {
  color: #1543c4;
}

.share-with-item * {
  margin-top: auto;
  margin-bottom: auto;
}

.share-with-item input {
  margin-right: 5px;
  margin-left: 5px;
}

.share-link {
  color: #1543c4;
  font-weight: 500;
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  cursor: default;
}

.share-link:hover {
  text-decoration: underline;
}

.account-toggle-widget {
  display: flex;
  font-size: 20px;
  font-weight: 500;
}

.account-toggle {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.account-toggle-widget .label {
  margin-left: 5px;
  margin-right: 5px;
}

.account-toggle-widget .svg-btn {
  height: 24px;
  width: 24px;
  margin-top: 4px;
  border-radius: 20px;
  border: 1px solid grey;
  display: flex;
  background-color: #e8ecf2;
  cursor: pointer;
}

.account-toggle-widget .svg-btn:hover {
  background-color: #f4f8fd;
}

.account-toggle-widget .svg-btn svg {
  height: 16px;
  width: 16px;
  margin: auto;
}

.account-toggle-widget .login-inactive svg {
  fill: #226640;
}

.account-toggle-widget .login-inactive:hover svg {
  fill: #4E8566;
}

.account-toggle-widget .login-active svg {
  fill: #963928;
}

.account-toggle-widget .login-active:hover svg {
  fill: #b14531;
}

.account-toggle-widget .login-inactive, .account-toggle-widget .login-active {
  display: flex;
}

.account-toggle-widget .login-inactive .label {
  color: #dd7c0e;
}

.account-toggle-widget .login-active .label {
  color: #226640;
}

.entity-header .primary {
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden
}

.entity-header .secondary {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}

.pad-image .image-container {
  padding: 10px;
}

.pad-image-small .image-container {
  padding: 5px;
}

.ag-row-odd .ag-filtered-cell {
  background-color: #ccebda;
}

.ag-row-even .ag-filtered-cell {
  background-color: #d9f0e3;
}

.ag-row-odd {
  background-color: #f7fafc !important;
}

.ag-row-hover, .ag-row-hover .ag-cell {
  background-color: #ffefcb !important;
}

.ag-row-focus, .ag-row-focus .ag-cell {
  background-color: #ffcb5c !important;
}

.ag-cell-focus {
  border-color: darkgrey !important;
}

.ag-row-selected:not(.ag-row-focus) {
  background-color: #ffefcb !important;
}

.ag-header-cell-text {
  color: black;
}

.ag-theme-balham .ag-column-drop-horizontal {
  height: 36px !important;
}

.ag-column-drop-empty-message {
  color: black !important;
}

.ag-column-drop-cell-text {
  color: black !important;
}

.ag-column-drop-cell {
  z-index: 10 !important;
}

.new-event-btn .text-widget {
  font-size: 14px;
  height: 22px;
  line-height: 16px;
  width: 100px;
  background-color: #4AB84A;
}

.red-text {
  color: red;
}

.overflow-311 {
  overflow: auto;
  font-weight: 500;
  font-size: 1.2rem;
}

.error-311-outer {
  max-width: 80%;
}

.new-event-btn .text-widget:hover {
  background-color: #52cb52;
}

.create-copy-btn .text-widget {
  padding: 8px;
  background-color: #54a0ba;
  width: 185px;
}

.create-copy-btn .text-widget:hover {
  background-color: #62bbd9;
}

.circle-plus-btn {
  background-color: #226640;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  position: relative;
  margin: auto;
  display: inline-block;
  vertical-align: middle;
}

.circle-plus-btn:hover {
  background-color: #4E8566;
}

.circle-plus-btn:before, .circle-plus-btn:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background: white;
  pointer-events: none;
}

.circle-plus-btn:before {
  width: 2px;
  margin: 2px auto;
}

.circle-plus-btn:after {
  margin: auto 2px;
  height: 2px;
}

.mobile-task-list .card-list {
  padding-left: 5px;
  padding-right: 5px;
}

.mobile-task-list .card-item {
  margin-top: 5px;
}

.full-width-btn {
  padding-top: 5px;
  padding-bottom: 5px;
}

.full-width-btn .button {
  min-width: unset !important;
  max-width: unset !important;
  width: unset !important;
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
}

.full-width-btn .confirm-mode {
  margin-top: -5px;
}

.full-width-btn .confirm-mode .confirm-text {
  margin-top: -5px;
}

.pivot-grid {
  overflow: auto;
}

.pivot-grid .gw-body {
  border-top: 1px solid #dce2e8;
}

.pivot-grid .grid-row {
  flex-direction: column;
}

.pivot-grid .row-cell {
  flex: 0 35px !important;
  min-height: 35px;
}

.pivot-grid .control-cell {
  display: flex !important;
}

.pivot-grid .control-cell .pivot-label {
  text-align: left;
  flex: 1;
  font-weight: 500;
}

.disable-grid-pane {
  background-color: black;
  opacity: .2;
}

.grid-widget.pivot-grid .gw-body {
  display: block;
  flex-direction: unset;
  overflow: unset;
}

.pivot-grid .control-cell .control-wrapper {
  flex: 1;
}

.shadow-ag-grid .ag-root-wrapper {
  border-radius: 3px;
  box-shadow: #949494 2px 2px 5px;
}

.spray-event .spray-event-hidden {
  display: none !important;
}

.hide-delete .hide-delete-hidden {
  display: none !important;
}

.base-event .base-event-hidden {
  display: none !important;
}

.recur-stats-item {
  margin: 20px 20px;
  font-family: sans-serif;
  color: black;
  background-color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spray-event-next {
  padding: 5px;
  display: none !important;
  max-width: 200px;
}

.spray-event .spray-event-next {
  display: block !important;
}

.spray-event-next .text-widget {
  width: 100%;
  height: 100%;
}

.spray-event-next .text-widget {
  background-color: rgb(52, 77, 108);
}

.spray-event-next .text-widget:hover {
  background-color: rgb(69 101 140);
}

.spray-event-header {
  display: flex;
  font-weight: 500;
  font-size: 20px;
}

.spray-event-header .pipe-separator {
  margin-left: 10px;
  margin-right: 10px;
  height: 40px;
  border-right: 2px solid darkgrey;
}

.spray-event-warning-text {
  display: flex;
  padding: 15px;
  font-size: 18px;
  text-align: center;
}

.spray-event-warning-text .primary {
  display: inline-block;
}

.spray-event-warning-text .primary * {
  display: inline-block;
}

.spray-event-warning-text .spray-hilight {
  font-weight: 500;
  color: rgb(253, 186, 48);
}

.spray-events-btn .text-widget {
  background-color: #226640;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.spray-events-btn .text-widget:hover {
  background-color: #4E8566;
}

.cancel-spray-btn .text-widget {
  background-color: #dd7c0e;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.cancel-spray-btn .text-widget:hover {
  background-color: #f18811;
}

.goals-grid {
  color: blue;
  background-color: #f1f2f2;
  padding: 0px;
}

.goals-grid .grid-header {
  height: 25px;
}

.grid-row {
  position: relative;
}

.grid-row-chevron {
  transform: rotate(90deg);
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  z-index: 10;
  user-select: none;
  cursor: pointer;
}

.grid-row-chevron.expand {
  transform: rotate(-90deg);
  width: 20px;
}

.grid-row-chevron * {
  margin: auto;
  font-size: 30px;
  line-height: 0px;
}

.grid-row .chevron-pad>div {
  margin-left: 30px !important;
}

.grid-row.child-row .row-cell:first-of-type:not(.chevron-pad) {
  padding-left: 20px;
}

.hidden-link .button {
  width: 100% !important;
  height: 100% !important;
  margin-left: 0;
  border-radius: 0;
}

.hidden-link .button:hover {
  cursor: pointer !important;
}

.asset-search-hover-info {
  position: absolute;
  height: 15px;
  margin-top: 6px;
  left: 98px;
  overflow: visible;
}

.asset-search-header {
  position: absolute;
  left: 8px;
}

.row-cell>div:not(.control-cell) {
  z-index: 15;
}

.grid-widget .grid-row.child-row {
  border-left: 1px solid #d0d5da;
}

.grid-widget .grid-row.open {
  border-bottom: 1px solid #d0d5da;
}

.grid-widget .grid-row.child-primary.even {
  background-color: #e5f2f9;
}

.grid-widget .grid-row.child-secondary.even {
  background-color: #e5f9e5;
}

.grid-widget .grid-row.last-child+.grid-row:not(.child-row) {
  border-top: 1px solid #d0d5da;
}

.top-tabs .card-item {
  cursor: pointer;
}

.document-pad-top {
  padding-top: 45px;
}

.autodropdown-control {
  position: relative;
}

.auto-drop-down-items {
  position: absolute;
  overflow: auto;
  max-height: 200px;
  background-color: white;
  border: 1px solid #767676;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1000;
}

.auto-drop-down-item {
  flex: 0 32px;
  min-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  color: black;
  padding-left: 5px;
  display: flex;
  cursor: default;
  font-size: 14px;
}

.auto-drop-down-item * {
  margin-top: auto;
  margin-bottom: auto;
}

.auto-drop-down-item.hover {
  background-color: #1e90ff;
  color: white;
}

.autodropdown-control .input-container {
  position: relative;
}

.autodropdown-control .fill-text {
  position: absolute;
  top: 3px;
  left: 6px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  pointer-events: none;
}

.autodropdown-control .fill-text .hidden-fill {
  visibility: hidden;
}

.autodropdown-control .fill-text .show-fill {
  margin-left: 1px;
  color: grey;
  font-style: italic;
}

.clean-form.widget-loader {
  background-color: unset !important;
}

.clean-form .form .form-grid {
  background-color: unset !important;
}

.grid-widget input[type=date] {
  width: -webkit-fill-available;
}

.grid-widget input[type=date] {
  padding-left: 0px;
  margin-left: 0px;
}

.grid-widget .plus-button-svg {
  fill: #4AB84A;
}

.grid-widget .grid-cell-icon:hover .plus-button-svg {
  fill: #52cb52;
}

.grid-widget .grid-cell-icon {
  position: absolute;
  left: -5px;
  height: 100%;
  width: 35px;
  height: 30px;
  display: flex;
  cursor: pointer;
}

.grid-widget .grid-cell-icon>svg {
  margin: auto;
}

.AutoCompleteImageCard {
  min-height: 43px;
  border-radius: 3px;
  border: 1px solid transparent;
}

.AutoCompleteImageCard .info-card-text {
  display: flex;
  position: relative;
  width: 100%;
}

.AutoCompleteImageCard .info-card-text .auto-complete-image {
  height: 100%;
  width: 40px;
  display: flex;
  padding-left: 5px;
  overflow: hidden;
}

.AutoCompleteImageCard .info-card-text .auto-complete-image img {
  margin: auto;
  width: 30px;
  height: 30px;
}

.AutoCompleteImageCard .info-card-text .text-1 {
  flex: 1;
  display: flex;
  position: absolute;
  left: 40px;
  top: 14px;
  height: 30px;
  overflow: hidden;
}

.AutoCompleteImageCard .info-card-text .text-1>div {
  margin-left: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.AutoCompleteImageCard .info-card-text .text-2 {
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #8d8d8d;
}

.auto-complete-item.hover .AutoCompleteImageCard {
  border: 1px solid #ffcb5c;
}

.auto-complete-items .AutoCompleteImageCard {
  margin-bottom: -5px;
}

.auto-dropdown-chevron {
  position: absolute;
  top: 1px;
  right: 5px;
  transform: rotate(90deg);
  font-size: 24px;
  line-height: 0px;
  font-weight: bold;
  cursor: default;
  width: 20px;
  height: 100%;
  display: flex;
  user-select: none;
  overflow: hidden;
}

.auto-dropdown-chevron>div {
  margin: auto;
  margin-top: 9px;
}

.no-padding-grid .grid-widget {
  padding-left: 0px;
  padding-right: 0px;
}

.no-pad-grid .grid-widget {
  padding: 0px;
  padding-top: 1px;
}

.scroll-subscene {
  overflow-y: auto;
}

.scroll-subscene .form-grid {
  overflow-y: unset;
}

.selector-preview-text {
  display: flex;
  font-size: 13px;
  flex: 1;
  font-weight: bold;
}

.selector-preview-text>div {
  margin: auto;
}

svg.arrow-down {
  transform: rotate(-90deg);
}

svg.arrow-up {
  transform: rotate(90deg);
}

.no-pad-btn .button-container {
  padding: 0px;
}

.inline-delete .button-confirmation {
  flex-direction: row-reverse;
  width: 100%;
  margin-left: 0px;
  padding-left: 0px;
}

.inline-delete .button-confirmation .confirm-buttons {
  flex: 0 100px;
}

.inline-delete .button-confirmation .confirm-buttons .button {
  min-width: 55px;
}

.inline-delete .button-confirmation .confirm-buttons .button:first-of-type {
  margin-right: 3px;
}

.inline-delete .button-confirmation .confirm-text {
  flex: unset;
  margin-left: 5px;
}

.debug-data {
  position: absolute;
  top: 50%;
  top: calc(50% + (-300px));
  right: calc(50% + (-200px));
  width: 400px;
  height: 600px;
  border: 1px solid black;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.debug-data .close-debug-data {
  flex: 0 30px;
  display: flex;
  overflow: hidden;
}

.debug-data .close-debug-data div:last-of-type {
  margin: auto;
  margin-right: 5px;
  font-size: 20px;
  width: 20px;
  text-align: center;
  cursor: pointer;
}

.debug-data .close-debug-data div:first-of-type {
  margin: auto;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
}

.save-data-title {
  flex: 0 30px;
  font-weight: 500;
  display: flex;
}

.save-data-title>div {
  margin: auto;
}

.queue-name {
  font-size: 13px;
  margin-left: 5px;
}

.save-data-debug-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.save-data-debug-item {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.runnable-save-data textarea {
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: scroll;
  resize: none;
  outline: none;
  height: 100px;
  width: 100%;
  padding: 10px;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  background-color: #f4f5f7;
}

.show-info-container {
  position: relative;
}

.show-info-container .show-info-circle {
  position: absolute;
  display: flex;
  height: 14px;
  width: 14px;
  left: 5px;
  top: -1px;
  background-color: #828282;
  border-radius: 15px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 500;
}

.no-results-cardlist {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 30px;
  height: 100%;
  animation: no-result-fadein 1s;
}

@keyframes no-result-fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.show-info-container .show-info-circle div {
  margin: auto;
  color: white;
  user-select: none;
}

.hover-info {
  position: absolute;
}

.hover-info .hover-info-parent {
  position: absolute;
  left: -14px;
  top: 8px;
}

.hover-info .hover-info-container {
  display: flex;
  position: absolute;
  font-size: 14px;
  width: 105px;
  z-index: 2001;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  background-color: #eeeeee;
  margin-top: 20px;
  box-shadow: #949494 2px 2px 8px;
  color: black;
  font-weight: initial;
  white-space: pre-line;
}

.width-300 {
  width: 300px !important;
}

#mapMarker {
  color: lightcyan;
  background-color: black;
  padding: 5px;
  opacity: .8;
  border-radius: 10px;
  border: 1px lightcyan solid;
}

.hover-info .hover-info-container .hover-info-caret {
  position: absolute;
  top: -8px;
  left: 18px;
  width: 14px;
  height: 14px;
  background-color: #eeeeee;
  transform: rotate(45deg);
  border-top: 1px solid grey;
  border-left: 1px solid grey;
}

.hover-info .hover-info-container .hover-close-btn {
  position: absolute;
  right: 6px;
  top: 2px;
  cursor: pointer;
}

.title-label .show-info-outer {
  display: inline-block;
}

.title-label .show-info-container {
  margin-top: -12px;
}

.auto-size-card .card-item {
  min-height: unset !important;
  max-height: unset !important;
}

.icon-spinner {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.icon-spinner .text-widget>div {
  display: flex;
}

.icon-spinner .text-widget>div svg {
  margin: auto;
  width: 20px;
  height: 20px;
}

.header-sort-asc {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 6px;
  border-color: transparent transparent transparent white;
  transform: rotate(-90deg);
  margin-left: 6px;
  margin-top: 8px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.header-sort-desc {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 6px;
  border-color: transparent transparent transparent white;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-top: 8px;
  top: 10px;
  right: 10px;
  position: absolute;
}

.grid-widget .header-col {
  position: relative;
}

.grid-widget .sort-date:not(.chevron-pad) div {
  margin-left: auto !important;
  margin-right: auto !important;
  width: unset !important;
  padding-right: 0px !important;
}

.grid-widget .sort-number div {
  margin-left: auto !important;
  margin-right: 3px !important;
  width: unset !important;
  padding-right: 0px !important;
}

.resource-cost {
  display: flex;
  margin-top: 10px;
}

.resource-cost>div {
  margin: auto;
  font-size: 14px;
  background-color: #d5d5d5;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  font-weight: 500;
}

.bring-to-front .sub-scene {
  position: absolute;
  z-index: 10;
  pointer-events: none;
}

.bring-to-front .widget-loader {
  pointer-events: initial;
  background-color: white;
}

.freeform-draw-btn {
  border-radius: 10px;
  border: 3px solid #bdc3c7;
}

.freeform-draw-btn:hover {
  border: 3px solid #9ea2a5;
}

.freeform-draw-btn .button {
  width: 100%;
  height: 100%;
  min-width: unset;
  margin: 0;
  cursor: pointer;
}

.freeform-draw-btn .icon-btn-container {
  margin: auto;
}

.freeform-draw-btn .icon-btn-container svg {
  width: 30px;
  height: 30px;
}

.ag-grid-padding-top {
  pointer-events: none;
}

.ag-grid-padding-top .ag-grid-widget {
  padding-top: 35px;
  z-index: 0;
}

.ag-grid-padding-top .ag-grid-widget {
  pointer-events: none;
}

.ag-grid-padding-top .ag-grid-widget>div {
  pointer-events: auto;
}

.ag-grid-query-name.v-2 {
  pointer-events: none !important;
}

.ag-grid-padding-top .ag-top-bar-control {
  top: 40px;
}

.show-overflow-form {
  position: relative;
}

.show-overflow-form .form {
  position: absolute;
}

.show-overflow-form .form .form-control {
  position: fixed;
}

.grid .control-wrapper {
  min-width: unset !important;
  overflow: hidden;
}

.double-height-rows .grid-widget .grid-row {
  min-height: 65px;
}

.double-height-rows .grid-widget .grid-row .row-cell {
  min-height: 65px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.double-height-rows .grid-widget .control-cell {
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.double-height-rows .grid-widget .control-wrapper {
  height: 100%;
}

.double-height-rows .grid-widget .control-wrapper .richtextbox-control {
  padding-top: 0px;
}

.double-height-rows .row-cell {
  overflow: auto;
}

.double-height-rows .row-cell>div:not(.control-cell) {
  white-space: unset;
}

.auto-height-card.three-line .card-item {
  min-height: unset;
  max-height: unset;
}

.auto-height-card .text-1, .auto-height-card .text-2, .auto-height-card .text-3 {
  white-space: unset;
}

.header-bar .site-header .time-ticker {
  margin-right: 0px;
  margin-left: auto;
}

.header-bar .weather-bar .weather {
  margin-left: 0px;
}

.header-dot {
  background-color: black;
  display: flex;
}

.header-dot .text-widget {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: #fdba30;
  margin: auto;
}

.selector-item-text {
  display: flex;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
}

.selector-item-text div {
  margin: auto;
}

.menu-options .card-item {
  min-height: 30px;
  max-height: 30px;
  padding: 3px;
}

.menu-options .card-item .text-1 {
  margin-top: -2px;
}

.menu-options .card-list .card-item .text-1 {
  font-size: 17px;
}

.card-black-text .text-1 {
  color: black !important;
}

.card-black-text .text-2 {
  color: black !important;
}

.card-black-text .text-3 {
  color: black !important;
}

.tiny-title-label {
  font-size: 12px;
  line-height: 12px;
  height: 18px;
}

.left-label :first-child {
  flex-direction: row;
}

.left-label .control-label {
  margin: auto;
  margin-right: 5px;
}

.right-label .cbox {
  flex: unset;
}

.right-label :first-child {
  flex-direction: row-reverse;
}

.right-label .control-label {
  margin: auto;
  margin-left: 5px;
}

.control-wrapper .large-text-no-background {
  margin-left: 0px;
  margin-right: 0px;
}

.control-wrapper .large-text-no-background .title-label {
  background-color: unset !important;
  color: black;
  font-size: 20px;
  font-weight: normal;
}

.control-wrapper .grid-bottom {
  border-bottom: 2px solid grey;
  margin-left: 0px;
  margin-right: 0px;
}

.control-wrapper .grid-right {
  border-right: 2px solid grey;
  margin-left: 0px;
  margin-right: 0px;
}

.control-wrapper .center-control>div>* {
  margin-left: auto;
  margin-right: auto;
}

.control-wrapper .adjust-down {
  margin-top: 4px;
}

.control-wrapper .adjust-up {
  margin-top: -4px;
}

.control-wrapper .adjust-down-15 {
  margin-top: 15px;
}

.control-wrapper .adjust-up-15 {
  margin-top: -15px;
}

.control-wrapper .center-align input {
  text-align: center !important;
  padding-left: 10px !important;
  padding-right: unset !important;
}

.form-audit-text {
  position: absolute;
  bottom: 0;
  right: 0;
  color: grey;
  font-size: 11px;
  font-weight: 500;
  text-align: right;
  margin-right: 20px;
}

.form-audit-space {
  min-height: 30px;
  background-color: blue;
}

.form-audit-text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.audit-text {
  color: grey;
  font-size: 11px;
  font-weight: 500;
}

.audit-text .text-widget div {
  margin-right: 25px;
}

.form-widget {
  width: 100%;
  height: 100%;
}

.form-widget .widget-loader {
  width: 100%;
  height: 100%;
}

.card-list.pivot-list {
  display: flex;
  flex-wrap: wrap;
  /* gap: 0px; */
}

.card-list.pivot-list .card-list-new-line {
  width: 100%;
}

.ub-acct-status {
  flex: 1;
  padding: 5px;
  margin-top: 5px;
}

.ub-acct-status .top-label {
  height: 18px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.ub-acct-status .top-label div {
  margin: auto;
}

.ub-acct-status .total-amt {
  font-size: 34px;
  font-weight: bold;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: -1px;
  white-space: nowrap;
  overflow: hidden;
}

.ub-acct-status .total-amt div {
  margin: auto;
}

.ub-acct-status .day-lbl {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
}

.ub-acct-status .day-lbl div {
  margin: auto;
}

.ub-acct-status .day-lbl-subtext {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  color: grey;
  font-weight: 500;
}

.ub-acct-status .day-lbl-subtext div {
  margin: auto;
}

.ub-acct-aging {
  flex: 1;
}

.ub-acct-aging:first-of-type {
  margin-left: 5px;
}

.ub-acct-aging .aging-lbl {
  height: 28px;
  display: flex;
  font-weight: 500;
}

.ub-acct-aging .aging-lbl div {
  margin: auto;
}

.ub-acct-aging .aging-amt {
  display: flex;
  font-weight: 500;
  height: 35px;
  font-size: 17px;
}

.ub-acct-aging .aging-amt div {
  margin: auto;
}

.padded-title-label {
  margin-bottom: 5px;
}

.vertical-center-card .card-list {
  display: flex;
}

.vertical-center-card .card-list>div {
  margin-top: auto;
  margin-bottom: auto;
}

.full-height-card .card-list>div {
  height: 100%;
  overflow: hidden;
}

.full-height-card-150px-limit .card-list>div {
  height: 100%;
  overflow: hidden;
  max-height: 150px;
}

.big-height-card .card-list>div {
  height: 85%;
  overflow: hidden;
}

.recur-stats-title {
  border-bottom: 2px solid black;
  font-weight: bold;
  overflow: hidden;
  font-size: 1.5em;
  text-align: center;
}

.recur-stats-card .html-card {
  height: 100%;
}

.mysales-image-library-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 5px;
}

.mysales-image-library-item:hover {
  background-color: #ffefcb;
  cursor: default;
}

.mysales-image-library-item p {
  height: 70px;
  width: 100%;
  padding: 3px;
  text-align: center;
  overflow: hidden;

}

.mysalesman-image-library-cardlist {
  background-color: #eaeaea;
}

.mysalesman-image-library-cardlist .html-card {
  width: 50% !important;
  float: left;
  margin-left: 0px;
  padding: 5px;
  overflow-wrap: break-word;

}

.mysalesman-image-library-cardlist .html-card img {
  margin-top: 4px;
  width: 75px;
  height: 75px;
}

.secondary-title-label {
  padding-left: 10px;
  padding-right: 10px;
}

.secondary-title-label .text-widget {
  background-color: #d3dccb;
  font-size: 13px;
}

.modal-close-x {
  margin-top: 1px;
  margin-right: 1px;
  position: relative;
  display: flex;
  cursor: pointer;
}

.modal-close-x .text-widget {
  flex: 0;
  top: 0px;
  left: 0px;
  width: 44px;
  height: 44px;
  position: absolute;
}

.modal-close-x::before {
  content: '\2716';
  margin: auto;
  font-size: 35px;
}

.auto-row-height .grid-widget .gw-body .row-cell {
  height: unset;
}

.grid-widget .html-cell>div {
  width: 100%;
}

.fixed-aspect-ratio img {
  object-fit: contain;
}

.left-wing-button, .right-wing-button {
  padding-top: 23px;
  padding-bottom: 23px;
  font-size: 20px;
  font-weight: 500;
  cursor: default;
}

.left-wing-button.selected .text-widget, .right-wing-button.selected .text-widget {
  background-color: #c1de99;
  color: #3A501D;
  font-size: 22px;
  font-weight: bold;
}

.left-wing-button.selected .text-widget>div, .right-wing-button.selected .text-widget>div {
  /* border-bottom: 3px solid #3A501D; */
  padding-left: 5px;
  padding-right: 5px;
}

.left-wing-button .text-widget>div, .right-wing-button .text-widget>div {
  padding-top: 5px;
  border-bottom: 3px solid transparent;
}

.left-wing-button {
  padding-left: 5px;
}

.left-wing-button .text-widget {
  border: 1px solid #a6a6a6;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  background-color: white;
  box-shadow: #949494 2px 2px 5px;
  padding-right: 40px;
}

.right-wing-button {
  padding-right: 5px;
}

.right-wing-button .text-widget {
  border: 1px solid #a6a6a6;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  background-color: white;
  box-shadow: #949494 2px 2px 5px;
  padding-left: 40px;
}

.center-wing-button {
  padding: 5px;
}

.center-wing-button.selected svg {
  fill: #3A501D;
  stroke: #3A501D;
  stroke-width: 2%;
}

.center-wing-button.selected .text-widget {
  background-color: #c1de99;
}

.center-wing-button:not(.selected) svg {
  fill: #c1de99;
  stroke: #3A501D;
  stroke-width: 2%;
}

.center-wing-button .text-widget {
  border-radius: 100px;
  border: 1px solid #a6a6a6;
  background-color: white;
  box-shadow: #949494 2px 2px 5px;
}

.center-wing-button svg {
  width: 100%;
  height: 100%;
  padding: 15px;
}

.four-text-card {
  border: 1px solid black;
  margin-bottom: 15px;
  display: grid;
  grid-template-rows: 3fr 2fr;
  grid-template-columns: 4fr 100px;
  padding: 5px;
  border: 1px solid #a6a6a6;
  border-radius: 3px;
  overflow: hidden;
}

.four-text-card>div {
  display: flex;
}

.four-text-card .text-1>div, .four-text-card .text-3>div {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
}

.four-text-card .text-2>div, .four-text-card .text-4>div {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 5px;
}

.four-text-card .text-1 {
  grid-column: 1;
  grid-row: 1;
  font-weight: 500;
  font-size: 20px;
}

.four-text-card .text-2 {
  grid-column: 2;
  grid-row: 1;
  font-weight: bold;
}

.four-text-card .text-3 {
  grid-column: 1;
  grid-row: 2;
}

.four-text-card .text-4 {
  grid-column: 2;
  grid-row: 2;
}

.control-widget {
  display: flex;
}

.control-widget .control-wrapper {
  width: 100%;
  height: 100%;
}

.underline-tab.selected .text-widget>div {
  border-bottom: 4px solid #3A501D;
  padding-left: 5px;
  padding-right: 5px;
}

.underline-tab {
  cursor: default;
}

.left-edge-button .button, .right-edge-button .button {
  height: 100%;
  width: 100%;
  border-radius: 0px;
  margin: 0px;
  font-weight: 500;
  font-size: 18px;
  min-width: unset;
}

.left-edge-button .button {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.right-edge-button .button {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

:not(.failed-validate) .radio-lookup-items {
  border: 2px solid transparent;
}

.failed-validate .radio-lookup-items {
  border: 2px solid red;
}

/* 
  beaker temp fix for grid scrolling
 */

.grid-widget {
  -webkit-overflow-scrolling: touch;
}

.grid-header {
  min-height: 30px;
}

.grid-widget .gw-body {
  -webkit-overflow-scrolling: touch;
}

.full-height-body .grid-widget .gw-body {
  height: 100%;
}

.full-height-body .grid-widget .gw-body .grid-row:last-child {
  border-bottom: 1px solid #d0d5da;
}

.grid-widget .grid-row {
  min-height: 30px;
}

.grid-row.selected {
  background-color: #ffcb5c !important;
}

.finance-contacts .contact-role-label {
  margin-top: 0px;
}

.contact-card-content {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
}

.finance-financial-summary .ub-acct-status {
  margin-top: 0px;
}

.sub-scene-noblock {
  pointer-events: none;
}

.sub-scene-noblock .widget-loader {
  pointer-events: initial;
}

.link-column-cell {
  color: white;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex !important;
  border-right: 1px solid #e4e4e4 !important;
}

.link-column-cell svg {
  fill: #eeeeee;
  padding: 2px;
}

.link-column-cell:hover svg {
  fill: white;
}

.link-column-cell:hover {
  text-decoration: underline;
}

.link-column-cell {
  justify-content: center;
}

.link-column-cell span {
  margin: auto;
}

.ag-row-odd .link-column-cell {
  background-color: #8da376 !important;
}

.ag-row-even .link-column-cell {
  background-color: #74895c !important;
}

.ag-grid-column-picker {
  position: absolute;
  z-index: 10;
  height: 28px;
  width: 28px;
  top: 40px;
  left: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
}

.ag-grid-column-picker:hover {
  border: 2px solid #bdc3c7;
}

.ag-grid-column-picker .picker-container {
  display: flex;
}

.ag-grid-column-picker svg {
  fill: #313434;
  transform: rotate(90deg);
}

.column-picker-items {
  max-height: 400px;
  overflow: auto;
  width: fit-content;
  background-color: white;
  cursor: default;
  border: 1px solid rgb(148, 148, 148);
}

.column-picker-item {
  display: flex;
  padding: 5px;
  font-size: 14px;
}

.column-picker-item input {
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.column-picker-item div {
  margin-top: auto;
  margin-bottom: auto;
  white-space: nowrap;
}

.column-picker-item:hover div {
  color: #1543c4;
}

.row-highlight .grid-row {
  transition: background-color .1s ease-out;
  border-bottom: 1px solid #d0d5da;
}

.row-highlight .grid-row:hover {
  background-color: #ffefcb !important;
}

.row-highlight {
  user-select: none;
}

.swid-copy-input {
  width: 45px;
  margin-right: -1px;
  margin-bottom: -1px;
  padding-left: 3px;
}

.param-add-btn.swid-copy {
  width: 40px;
  font-size: 12px;
}

.control-pad-top {
  padding-top: 5px;
}

.modal-layer .button-container .button {
  max-width: 125px;
  margin-left: auto;
}

.grid-row-num {
  display: flex;
}

.grid-row-num div {
  margin: auto;
  font-weight: 500;
}

.header-RowNumber {
  display: flex;
}

.header-RowNumber div {
  margin: auto !important;
}

.auto-overflow-fix {
  overflow: visible;
}

.auto-overflow-fix .gw-body {
  overflow: visible;
}

.auto-overflow-up .auto-drop-down-items {
  transform: translateY(-100%);
  margin-top: -25px;
}

.full-width .text-widget>div {
  width: 100%;
}

.stretch-image img {
  object-fit: cover;
  max-height: unset;
  width: 100%;
}

.full-size-svg img {
  margin: unset;
  max-height: 100%;
  width: 100%;
}

.tab-list-icons .card-item .icon svg {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 8px;
  left: 8px;
}

.full-height-static .gw-body {
  height: 100%;
}

.full-height-static .gw-body .grid-row {
  flex: 1;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-30 {
  margin-left: 30px;
}

.full-height-static .gw-body .row-cell {
  height: 100%;
}

.full-height-static .gw-body .row-cell input {
  font-size: 16px;
}

.scrollable-subscene .sub-scene {
  overflow: auto;
}

.chrome-hide-field {
  display: none;
}

.green-text-button {
  background-color: #536142 !important;
  color: white;
  border-radius: 10px;
}

.green-text-button .text-widget:hover {
  background-color: #5e783e;
  cursor: pointer;
}

.blue-text-button .text-widget {
  background-color: #183eb2;
  color: white;
  border-radius: 10px;
}

.blue-text-button .text-widget:hover {
  background-color: #2156f5;
  cursor: pointer;
}

.blue-link {
  color: #0000EE;
  font-weight: 500;
}

.blue-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.orange-text-button {
  background-color: #dd7e0e !important;
  color: white;
  border-radius: 10px;
}

.orange-text-button .text-widget:hover {
  background-color: #ff9517;
  cursor: pointer;
}

.display-block-txt .text-widget {
  display: block;
  margin: 10px 0px 0px 10px;
}

.modal-frame-v2 {
  display: flex;
  background-color: black;
  color: white;
  border: 1px solid black;
}

.modal-frame-v2 .text-widget {
  display: none;
}

.black-border-left {
  border-left: 2px solid black;
  padding-left: 10px;
  margin-top: 4px;
}

.black-border {
  border: 1px solid black;
}

.modal-frame-v2:before {
  content: '';
  height: 100%;
  width: 100%;
}

.modal-label-tab {
  background-color: transparent !important;
  font-weight: bold;
  font-size: 18px;
  border: 1px solid black;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: -1px;
}

.modal-label-tab .text-widget {
  overflow: visible;
  display: flex;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: white;
}

.modal-label-tab .text-widget:before {
  content: '';
  border-left: 5px solid #ffa600;
  position: absolute;
  height: 100%;
  border-radius: 4px 0px 0px 4px;
}

.modal-label-tab .text-widget>div {
  white-space: nowrap;
  overflow: hidden;
  margin: auto;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  padding-right: 5px;
}

.clickable-link {
  color: #0056ac;
  cursor: pointer;
}

.clickable-link:hover {
  text-decoration: underline;
}

.text-btn.plus-btn .text-widget {
  height: 17px;
  line-height: 13px;
  font-weight: bold;
  background-color: #4AB84A;
  width: 35px;
}

.text-btn.plus-btn .text-widget:hover {
  background-color: #52cb52;
}

.top-card-fix .card-list .card-item:first-of-type {
  margin-top: 0px;
}

.form-small-labels .title-label {
  font-size: 13px;
  padding: 3px;
}

.nowrap-header .header-col>div {
  white-space: nowrap;
  overflow: hidden;
}

.recurring-btn {
  margin-top: 7px;
  margin-left: auto;
  margin-right: 20px;
}

.recurring-btn .text-widget {
  width: unset;
  padding-left: 15px;
  padding-right: 15px;
}

.recurrence {
  display: flex;
  flex-direction: column;
  overflow: auto !important;
}

.recurrence-title {
  display: flex;
  padding: 2px;
  flex: 0 40px;
}

.recurrence-title input {
  flex: 1;
  padding-left: 5px;
  font-size: 18px;
}

.recurrence-content {
  margin-left: 5px;
  display: flex;
  flex: 1;
}

.recurrence-content .left-pane, .recurrence-content .right-pane {
  flex: 1;
}

.recurrence .left-pane {
  display: flex;
  flex-direction: column;
}

.recurrence .left-pane .base-event-selection, .recurrence .right-pane .schedule-selection {
  flex: 1;
  display: flex;
  padding: 5px;
}

.base-event-selection textarea, .schedule-selection textarea {
  flex: 1;
}

.base-event-selection .rec-desc, .schedule-selection .rec-desc {
  flex: 1;
  border: 2px solid black;
  margin: auto;
  padding: 10px;
  min-height: 100px;
}

.rec-not-selected {
  border-left: 10px solid #963928 !important;
}

.rec-selected {
  border-left: 10px solid #226640 !important;
}

.rec-titles {
  font-weight: bold;
  margin-top: 10px;
}

.rec-desc-details {
  margin-left: 25px;
  margin-top: 5px;
  font-size: .9em;
}

.form-blocker {
  user-select: none;
  background-color: rgba(173, 173, 173, 0.3);
  pointer-events: none;
  z-index: 500px;
  padding: 10px;
}

.form-blocker-overlay {
  background-color: rgba(173, 173, 173, 0.3) !important;
}

.form-no-block {
  height: 90%;
}

.form-blocker input, .form-blocker textarea, .form-blocker select, .form-blocker input[type=radio] {
  border-color: darkgrey;
  background-color: rgb(223, 223, 223);
}

.recurrence .left-pane .recurrence-note {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.recurrence-note textarea {
  flex: 1;
  padding: 5px;
}

.recurrence-note .label {
  padding: 0;
  margin: 0;
  display: flex;
  font-size: 13px;
}

.recurrence .right-pane {
  display: flex;
  flex-direction: column;
}

.recurrence .right-pane .schedule-selection {
  flex: 1;
}

.recurrence .right-pane .recurrence-calendar {
  flex: 3;
  display: flex;
  margin: auto;
  margin-top: 15px;
}

.marked-day, .first-marked-day, .last-marked-day {
  color: black !important;
  font-weight: bold;
  border-radius: 15px;
  outline: 1px solid black !important;
}

.marked-day {
  background-color: rgb(253, 186, 48) !important;
}

.first-marked-day {
  background-color: green !important;
  color: white !important;
}

.last-marked-day {
  background-color: #c40000 !important;
  color: white !important;
}

.rcr-button-bar {
  flex: 0 40px;
  display: flex;
}

.rcr-button-bar .rcr-buttons {
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
}

.rcr-buttons .rcr-btn {
  width: 100px;
  margin-right: 20px;
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 20px;
  cursor: default;
  color: white;
  display: flex;
  user-select: none;
}

.rcr-buttons .rcr-btn>div {
  margin: auto;
}

.rcr-create-new-evt, .rcr-copy-existing-evt {
  width: 140px !important;
  background-color: #183eb2;
}

.rcr-create-new-evt:hover, .rcr-copy-existing-evt:hover {
  background-color: #2156f5;
}

.rcr-top-buttons {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.rcr-btn.rcr-save {
  background-color: #226640;
}

.rcr-btn.rcr-save:hover {
  background-color: #4E8566;
}

.rcr-btn.rcr-cancel {
  background-color: #dd7c0e;
}

.rcr-btn.rcr-cancel:hover {
  background-color: #f18811;
}

.rcr-btn.rcr-delete {
  background-color: #963928;
}

.rcr-btn.rcr-delete:hover {
  background-color: #b14531;
}

.grid-widget .sort-money div {
  margin-left: auto !important;
  width: unset !important;
  margin-right: 0px;
}

.hide-chevron select {
  appearance: none;
  padding-left: 4px;
}

.control-wrapper.AutoFillButton .auto-fill {
  flex: 1;
  padding: 10px;
  font-size: 12px;
}

.control-wrapper.AutoFillButton .auto-fill-button>div {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 5px;
}

.control-wrapper.AutoFillButton .auto-fill-label {
  display: flex;
  margin-left: 5px;
}

.control-wrapper.AutoFillButton .auto-fill-label div {
  margin: auto;
}

.same-line-label-form .form-control>div {
  display: flex;
  flex-direction: row;
}

.same-line-label-form .form-control>div>* {
  flex: 2;
}

.same-line-label-form .form-control>div .control-label {
  flex: 1;
  font-size: 12px;
  margin-right: 5px;
  text-align: right;
  display: inline-block;
  padding-top: 10px;
}

.same-line-label-form .form-control textarea {
  height: 100%;
  margin-left: 4px;
}

.same-line-label-form .form-control select {
  margin-left: 4px;
}

.same-line-label-form .form-control input[type=date] {
  margin-left: 4px;
}

.click-through {
  pointer-events: none;
}

.clickable {
  pointer-events: initial;
}

.flex-btn-bar {
  display: flex;
  padding-left: 10px;
  padding-bottom: 5px;
}

.flexbox-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-btn-bar .btn-bar-btn {
  border-radius: 50px;
  color: white;
  background-color: purple;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  min-width: 80px;
  cursor: default;
  font-size: 14px;
  height: 25px;
}

.flex-btn-bar .btn-container {
  display: flex;
}

.flex-btn-bar .btn-bar-btn div {
  margin: auto;
}


.flex-btn-bar .btn-green {
  background-color: #226640;
}

.flex-btn-bar .btn-green:hover {
  background-color: #4E8566;
}

.flex-btn-bar .btn-red {
  background-color: #963928;
}

.flex-btn-bar .btn-red:hover {
  background-color: #b14531;
}

.flex-btn-bar .btn-orange {
  background-color: #dd7c0e;
}

.flex-btn-bar .btn-orange:hover {
  background-color: #f18811;
}

.dialog .close-btn {
  cursor: pointer;
}

.button>div {
  margin: auto;
}

.button.Save {
  background-color: #226640;
}

.button.Save:hover {
  background-color: #4E8566;
}

.button.Approve {
  background-color: #226640;
}

.button.Approve:hover {
  background-color: #4E8566;
}

.button.Cancel {
  background-color: #dd7c0e;
}

.button.Cancel:hover {
  background-color: #f18811;
}

.button.Export {
  background-color: #dd7c0e;
}

.button.Export:hover {
  background-color: #f18811;
}

.button.Delete {
  background-color: #963928;
}

.button.Delete:hover {
  background-color: #b14531;
}

.margin-auto {
  margin: auto;
}

.scheduleDeleter {
  background-color: #963928;
}

.scheduleDeleter:hover {
  background-color: #b14531;
}

.button.Deny {
  background-color: #963928;
}

.button.Deny:hover {
  background-color: #b14531;
}

.button.Unsubmit {
  background-color: #963928;
}

.button.Unsubmit:hover {
  background-color: #b14531;
}

.clamp-header {
  font-size: clamp(16px, 1vw, 20px) !important;
}

.audit-record-list .card-item {
  max-height: unset;
  min-height: unset;
  flex-direction: row;
  padding-bottom: 20px;
  position: relative;
}

.audit-record-list .card-item .text-3 {
  position: absolute;
  bottom: 2px;
  top: unset;
  right: 5px;
  font-weight: initial;
  font-size: 11px;
}

.audit-record-list .card-item .text-2 {
  color: black;
  margin-left: 5px;
}

.hide-label .control-label {
  display: none;
}

.right-align-cards .card-list {
  width: unset;
  margin-right: 10px;
  margin-left: auto;
}

.user-activity {
  display: flex;
}

.user-activity .activity-rows {
  overflow: auto;
  flex: 1;
  padding: 0 10px 5px 5px;
  border: 1px solid #c0c2c7;
}

.user-activity .activity-rows .activity-row {
  border: 1px solid #cfd4da;
  margin-top: 5px;
  padding: 5px;
  box-shadow: #9f9f9f 2px 2px 3px;
  border-radius: 3px;
}

.activity-row .log-title {
  display: flex;
  font-weight: bold;
  font-size: 14px;
}

.activity-row .log-title .log-path {
  margin: auto;
  margin-left: 5px;
}

.activity-row .log-title .log-date {
  margin: auto;
  margin-right: 5px;
}

.activity-row .log-body {
  font-size: 13px;
  margin-left: 10px;
  padding-right: 10px;
}

.activity-row .log-body div {
  overflow: auto;
}

.activity-row .log-body div::-webkit-scrollbar {
  height: 8px;
}

.activity-row .log-body div::-webkit-scrollbar-thumb {
  background: rgb(163, 163, 163);
}

.activity-row .log-body div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.activity-row .log-body div::-webkit-scrollbar-track {
  background: #e6e6e6;
}

.user-activity {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-left: 5px;
}

.activity-label {
  font-weight: bold;
}

.activity-time input {
  margin-top: -5px;
  margin-bottom: 5px;
}

.activity-time div {
  font-size: 12px;
  margin-top: 5px;
}

.pointer-events-initial {
  pointer-events: initial;
}

.draggable-parent {
  z-index: 999999;
  display: block !important;
  position: fixed;
  top: 0px;
  left: 0px;
  pointer-events: none;
}

.widget-details {
  position: absolute;
  background-color: white;
  pointer-events: initial;
  border: 1px solid black;
}

.dtl-top-bar {
  height: 35px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  pointer-events: initial;
  user-select: none;
  border-bottom: 1px solid black;
  color: white;
  background-color: black;
  font-weight: 500;
  font-size: 15px;
}

.dtl-top-bar div {
  margin: auto;
  padding: 5px;
}

.dtl-top-bar div:first-child {
  width: 100%;
  padding-left: 10px;
}

.dtl-top-bar div:last-child {
  margin-right: 5px;
}


.card-list-details {
  padding: 10px;
  max-width: 1000px;
}

.editor-details {
  font-size: 11px;
}

.editor-details .data-header {
  display: flex;
  font-weight: bold;
}

.editor-details .data-header .data-header-cell {
  flex: 1 1 0;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
}

.editor-details .data-rows {
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow: auto;
}

.editor-details .data-rows .data-row {
  display: flex;
}

.editor-details .data-rows {
  padding-top: 1px;
}

.editor-details .data-rows .data-row {
  padding-left: 1px;
  margin-top: -1px;
}

.editor-details .data-rows .data-row .data-cell {
  flex: 1 1 0;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #bbb;
  margin-left: -1px;
}

.detail-input {
  height: 20px;
  padding-left: 5px;
  margin-right: 5px;
}

.horizontal-btn-bar {
  display: flex;
}

.horizontal-btn-bar * {
  flex: 1 1 0;
  width: 0;
}

.go-btn {
  border: 1px solid grey;
  border-radius: 3px;
  font-size: 10px;
  max-width: 20px;
  height: 20px;
  cursor: default;
  user-select: none;
  display: flex;
}

.go-btn div, .dtl-save-btn div {
  margin: auto;
  margin-left: 2px;
}

.go-btn:hover, .dtl-save-btn:hover {
  background-color: #ccf1dc;
}

.dtl-save-btn {
  border: 1px solid grey;
  border-radius: 3px;
  font-size: 10px;
  max-width: 30px;
  min-width: 30px;
  height: 20px;
  cursor: default;
  user-select: none;
  display: flex;
}

.dtl-save-btn div {
  margin: auto !important;
}

.details-content {
  display: flex;
}

.widget-detail-tabs {
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 100px;
}

.widget-detail-tab {
  display: flex;
  width: 100%;
  height: 30px;
  border: 1px solid grey;
  margin-bottom: 5px;
  font-size: 12px;
  cursor: default;
}

.widget-detail-tab.selected {
  font-weight: bold;
}

.widget-detail-tab:not(.selected):hover {
  text-decoration: underline;
}

.widget-detail-tab div {
  margin: auto;
}

.auto-drop-down-item.multiline {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  border-top: 2px solid #e5e5e5;
  padding-top: 2px;
  padding-bottom: 2px;
}

.auto-drop-down-item.multiline div:first-child {
  font-weight: 500;
}

.auto-drop-down-item.multiline div:last-child {
  font-size: 12px;
}

.editor-form-grid {
  position: relative;
  display: grid;
  min-width: 0;
  min-height: 0;
  overflow: auto;
  margin: 5px;
  margin-bottom: 0px;
  max-height: 500px;
  max-width: 600px;
  padding-top: 1px;
  padding-left: 1px;
  border-bottom: 1px solid grey;
}

.editor-form-grid .form-cell {
  border: 1px dashed purple;
  margin-top: -1px;
  margin-left: -1px;
}

.form-details {
  display: flex;
  flex-direction: column;
}

.editor-form-grid {
  width: 600px;
}

.form-right-pane {
  width: 300px;
  display: flex;
  min-width: 0px;
}

.form-detail-prop {
  display: flex;
  padding: 5px;
  user-select: none;
  pointer-events: none;
}

.form-detail-prop.dragging:not(.drag-target) {
  opacity: .6;
}

.form-detail-prop.stretching:not(.stretch-target) {
  opacity: .6;
}

.form-detail-prop .prop-container {
  width: 100%;
  height: 100%;
  background-color: white;
  flex: 1;
  display: flex;
  border: 1px solid grey;
  position: relative;
  pointer-events: none;
}

.form-detail-prop .prop-label {
  margin: auto;
  font-weight: 500;
  font-size: 13px;
}

.form-detail-prop .stretch-handle {
  background-color: #1d85a6;
  width: 10px;
  height: 10px;
  cursor: nesw-resize;
  position: absolute;
  right: -2px;
  bottom: -2px;
  border-radius: 10px;
  pointer-events: initial;
  z-index: 10;
}

.form-detail-prop .stretch-container {
  position: relative;
}

.form-header>div {
  padding: 5px;
  display: flex;
}

.form-header>div .editor-details {
  flex: 1;
}

.form-header>div .dtl-save-btn {
  margin-top: auto;
  margin-bottom: 5px;
  flex: 0 0 35px;
  margin-left: 5px;
}


.data-rows .data-cell input {
  padding-left: 5px;
}

.form-right-pane {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-right-pane .dtl-save-btn {
  margin: auto;
}

.clickable-title-card {
  border: 1px solid transparent;
  cursor: pointer;
}

.clickable-title-card:hover {
  border: 1px solid black;
}

.icon-card-menu .card-list {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.mysales-action-menu .card-list {
  padding: 5px 10px;
  user-select: none;
}

.mysales-new-item .text-widget div {
  margin: 6px 25px;
  margin-top: 6px;
  font-weight: bold;
  font-size: .9rem;
}

.widget-blocker {
  background-color: rgb(255, 255, 255, .5);
  z-index: 5;
}

.mysales-total-estimate {
  background-color: white;
  /* border-top: solid 1px grey; */
  border-bottom: solid 1px grey;
}

.mysales-new-item {
  color: black;
}

/* .mysales-action-menu .card-item:hover, .mysales-action-menu-selected {
  border: 1px solid orange;
  fill: orange;
  box-shadow: none;
} */

.mysales-action-menu .card-item {
  background-color: white;
  color: black;
  box-shadow: #c5c5c5 2px 2px 4px;
  max-height: unset;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid grey;
}

.mysales-action-menu .mysales-action-menu-active:hover {
  border: 1px solid orange;
  fill: orange;
  box-shadow: none;
}

.mysales-action-menu .mysales-action-menu-inactive {
  background-color: #DEDEDE;
  color: darkgrey;
  box-shadow: none;
  pointer-events: none;
}

.mysales-action-menu .selected.mysales-action-menu-inactive {
  color: black;
  background-color: #ffcb5c;
}

.icon-card-menu .card-item {
  background-color: #344d6c;
  color: white;
  box-shadow: #c5c5c5 2px 2px 4px;
  max-height: unset;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid transparent;
}

.icon-card-menu .card-item .text-2 {
  color: white;
}

.icon-card-menu .card-item:not(.top-level-parent-card):not(.selected):hover {
  background-color: #4e74a3;
}

.icon-card-menu .card-item.selected {
  background-color: #ffcb5c;
  color: black;
  border: 1px solid grey;
}

.icon-card-menu .card-item.selected .text-2 {
  color: black;
}

.icon-card-menu .card-item.selected .icon svg {
  fill: black;
}

.icon-card-menu .card-item .icon svg {
  fill: white;
}

.kanban-card {
  border: 1px solid grey;
  margin-top: 5px;
  background-color: white;
  margin: 5px;
  margin-left: 0px;
  margin-top: 0px;
  border: 1px solid #c0c2c7;
  background-clip: content-box;
  box-shadow: #949494 1px 1px 2px;
  border-radius: 3px;
  cursor: default !important;
  width: 270px;
  max-height: 100px;
}

.card-wrapper {
  padding: 5px;
  max-height: inherit;
}

.card-wrapper>div {
  overflow: hidden;
  white-space: normal;
}


.card-wrapper .svg-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.card-wrapper .svg-container svg {
  position: absolute;
  height: 100%;
  width: unset;
  background-color: white;
}

.svg-container.clickable {
  cursor: pointer;
}

.no-header-grid .grid-header {
  display: none;
}

.no-header-grid .row-cell {
  border: none !important;
}

.no-header-grid .gw-body {
  border: none !important;
}

.no-header-grid .grid-widget {
  padding: 0px;
}

.picked-date {
  background-color: #ffcb5c !important;
  border-radius: 5px;
  font-weight: bold;
}

.DatePicker-widget .react-calendar__tile--now {
  border-radius: 5px;
  background-color: #ffefcb;
}

.DatePicker-widget .react-calendar__tile--now:hover {
  background-color: #ffefcb !important;
}

.DatePicker-widget .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  border-radius: 5px;
}

.DatePicker-widget {
  display: flex;
}

.DatePicker-widget .react-calendar {
  margin: auto;
  margin-top: 0px;
}

.pdf-html .text-widget>div {
  margin: unset;
  width: 100%;
  height: 100%;
}

.pdf-html .text-widget>div>html {
  width: 100%;
  height: 100%;
  position: relative;
}

.pdf-html .text-widget>div body {
  width: 100%;
  height: 100%;
  position: relative;
}

.text-widget>object {
  width: 100%;
  margin-right: 5px;
}

.no-pad-top .form .form-grid {
  margin-top: 0px;
}

.no-pad-top .form .form-grid .control-wrapper .form-control>div {
  margin-top: 0px;
}

.no-pad-top .form .form-grid .control-wrapper .control-label {
  margin-top: 0px;
}

.border-bottom-list {
  border-bottom: 1px solid #e4e4e4;
}

.ag-table-widget .ag-floating-filter-button {
  display: none;
}

.ag-table-widget .ag-icon-filter {
  display: none;
}

.agtable-filter-type {
  flex: 0 0 40px;
  font-size: 13px;
  min-width: 0px;
  font-family: monospace;
  font-weight: bold;
}

.ag-table-widget .ag-floating-filter {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ag-table-widget .ag-floating-filter input {
  width: unset !important;
  flex: 1;
  min-width: 0px;
  padding-left: 5px;
  margin-left: -1px;
}

.ag-table-widget .ag-floating-filter-body {
  width: 100%;
  padding-bottom: 2px;
  padding-left: 5px;
}

.ag-table-widget .ag-header-cell {
  padding-left: 0px !important;
}

.ag-table-widget .ag-header-cell .ag-header-cell-label {
  margin-left: 5px;
}

.ag-table-checkbox {
  display: flex;
}

.ag-table-checkbox .ag-table-checkbox-item {
  display: flex;
}

.ag-table-checkbox .ag-table-checkbox-item div {
  margin-right: 5px;
  margin-top: 1px;
  font-weight: normal;
}

.ag-table-checkbox .ag-table-checkbox-item input {
  margin-right: 5px;
}

.group-parent-card .card-list {
  overflow: hidden;
}

.group-parent-card .card-list .card-item {
  box-shadow: none !important;
  padding: 0px !important;
  padding-left: 5px !important;
  margin-top: 2px !important;
  max-height: unset !important;
  min-height: unset !important;
}

.group-parent-card .card-list {
  border-top: 1px #cfd4da;
  margin-top: 2px;
}

.ag-table-lookup {
  width: 100%;
  position: relative;
  overflow: visible;
  display: flex;
}

.ag-table-lookup.hidden .ag-table-lookup-container {
  display: none;
}

.ag-table-widget .ag-header-container {
  z-index: 1;
}

/* .ag-table-widget .ag-header {
  overflow: visible !important;
}

.ag-table-widget .ag-header * {
  overflow: visible !important;
} */

.ag-table-widget .ag-header, .ag-table-widget .ag-header-viewport, .ag-table-widget .ag-header-container, .ag-table-widget .ag-header-row, .ag-table-widget .ag-header-cell {
  overflow: visible;
}

.ag-table-lookup-container {
  position: absolute;
  z-index: 100;
  width: 200px;
  border: 1px solid grey;
  border-bottom: none;
  background-color: white;
  overflow: unset !important;
  margin-top: 22px;

}

.ag-table-lookup-container .ag-table-lookup-item {
  border-bottom: 1px solid grey;
  display: flex;
  overflow: unset !important;
  min-height: 25px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
}

.ag-table-lookup-container .ag-table-lookup-item:hover {
  background-color: #e3f2fc;
}

.ag-table-lookup-container .ag-table-lookup-item input {
  flex: 0 30px;
  height: 13px;
  margin-top: auto;
  margin-bottom: auto;
  overflow: unset !important;
}

.ag-table-lookup-container .ag-table-lookup-item div {
  flex: 1;
  min-width: 0px;
  overflow: unset !important;
  white-space: initial;
}

.ag-table-widget .filter-options-link {
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
}

.ag-table-widget .filter-options-link:hover {
  text-decoration: underline;
}

.ag-editor-container select {
  height: 100%;
  width: 100%;
}

.ag-editor-container {
  height: 40px;
  width: 100%;
}

.ag-table-widget .ag-cell {
  padding-left: 5px !important;
  padding-right: 0px !important;
}

.ag-table-widget .ag-cell .control-wrapper {
  width: 100%;
  height: 100%;
  padding-left: 3px;
  padding-right: 3px;
}

.ag-table-widget .ag-cell .form-control {
  margin: 0px;
  height: 100%;
}

.ag-table-widget .dropdown-control {
  display: flex;
  height: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
}

.ag-table-widget .dropdown-control select {
  margin: auto;
  height: 100%;
}

.ag-table-widget .textbox-control {
  height: 100%;
}

.ag-table-widget .textbox-control>div {
  display: flex;
  height: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
}

.ag-table-widget .textbox-control>div input {
  height: 100%;
  margin: auto;
}

.ag-table-widget span.changed .form-control textarea, .ag-table-widget span.changed .form-control input:not([type='checkbox']), .ag-table-widget span.changed .form-control select {
  border-color: #4cd0f8;
  box-shadow: 0 0 0 1px #0ab1e4;
  -webkit-box-shadow: 0 0 0 1px #0ab1e4;
  outline: 0;
  outline-offset: -2px;
}

.ag-table-widget .cbox {
  margin-top: 7px;
}

.ag-table-widget span.changed .form-control .cbox>svg {
  fill: #0ab1e4;
}

.ag-table-widget span.changed .form-control .cbox>svg :first-child {
  fill: #98e7ff;
}

.ag-table-widget .ag-row-hover {
  background-color: initial !important;
}

.ag-table-widget .ag-row-focus, .ag-table-widget .ag-row-focus .ag-cell {
  background-color: initial !important;
}

.ag-table-widget .ag-row-focus.ag-row-odd, .ag-table-widget .ag-row-focus.ag-row-odd .ag-cell {
  background-color: #f7fafc !important;
}

.ag-table-widget .ag-row-hover, .ag-table-widget .ag-row-hover .ag-cell {
  background-color: initial !important;
}

.ag-table-widget .ag-row-hover.ag-row-odd, .ag-table-widget .ag-row-hover.ag-row-odd .ag-cell {
  background-color: #f7fafc !important;
}

.ag-table-widget.ag-theme-alpine .ag-row-hover:not(.ag-full-width-row)::before, .ag-table-widget.ag-theme-alpine .ag-row-hover.ag-full-width-row.ag-row-group::before {
  background-color: initial !important;
}

.ag-table-widget.ag-theme-alpine .ag-row-hover.ag-row-odd:not(.ag-full-width-row)::before, .ag-table-widget.ag-theme-alpine .ag-row-hover.ag-full-width-row.ag-row-group::before {
  background-color: #f7fafc !important;
}

.ag-table-widget .ag-cell-focus {
  border-color: transparent !important;
}

.ag-table-widget .ag-row.deleted-row {
  opacity: .25;
}

.auto-fit-items .card-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.item-catalog-card .photo-upload-control {
  border: none;
}

.item-catalog-card {
  background-color: #eaeaea;
  border: 1px solid #adadad;
  padding: 5px;
  margin: 8px;
  border-radius: 4px;
  box-shadow: #a4a4a4 1px 2px 4px;
}

.item-catalog-card .photo-upload-control {
  padding: 10px;
}

.item-catalog-card .photo-upload-control>div {
  padding-left: 10px;
  padding-right: 10px;
}

.item-catalog-card .photo-upload-control img {
  width: 90px;
  height: 90px;
}

.item-category-options .card-list {
  display: flex;
  padding-top: 7px;
  padding-bottom: 7px;
}

.item-category-options .card-item {
  margin-right: 15px;
  padding: 0px;
  height: 100%;
  width: 130px;
  min-height: unset;
  max-height: unset;
  display: flex;
  border-radius: 5px;
  background-color: #344d6c;
  color: white;
  border: 1px solid #4e74a3;
}

.item-category-options .card-item:hover {
  background-color: #4e74a3 !important;
}

.item-category-options .card-item.selected {
  color: black;
  background-color: #ffcb5c;
  border: 1px solid grey;
}

.item-category-options .card-item.selected:hover {
  background-color: #ffcb5c !important;
}

.item-category-options .text-1 {
  margin: auto;
}

.grey-background {
  background-color: #5e5e5e;
  margin-right: 5px;
  margin-left: 5px;
}

.new-catalog-item-label {
  color: white;
  font-weight: 500;
  font-size: 20px;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 5px;
  background: linear-gradient(to bottom, #4b4b4b 0%, #333333 100%) !important;
}

.new-cat-card .item-catalog-card {
  margin: 5px;
}

.ag-table-widget .ag-header-cell-text {
  margin-left: 10px;
  font-size: 16px;
}

.task-header-tab {
  margin-bottom: 5px;
  margin-right: 5px;
}

.first-header-tab {
  margin-left: 5px;
}

.second-header-tab {
  margin-left: 10px;
}

.task-header-tab .text-widget {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #344d6c;
  position: relative;
}

.task-header-tab.selected .text-widget:before {
  content: '';
  width: 100%;
  position: absolute;
  border-radius: 5px 5px 0px 0px;
  border-top: 5px solid #344d6c;
  margin-top: -1px;
}

.task-header-tab:not(.selected) .text-widget {
  color: white;
  background-color: #252525;
}

.big-form .textbox-control {
  height: 100%;
  margin-top: 5px;
}

.big-form .textbox-control>div:not(.control-label) {
  height: 100%;
  padding-bottom: 20px;
}

.big-form .textbox-control input {
  height: 100%;
  font-size: 25px;
  text-align: left;
}

.big-form .control-label {
  font-size: 14px;
}

.big-form.normal-text .textbox-control input {
  font-size: 16px;
}

.status-btn {
  padding: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.status-btn .text-widget {
  background-color: grey;
  border-radius: 8px;
  color: white;
}

.status-btn.active .text-widget {
  background-color: #226640;
}

.status-btn.active .text-widget:hover {
  background-color: #4E8566;
}

.status-btn.follow-up.active .text-widget {
  background-color: #dd7c0e;
}

.status-btn.follow-up.active .text-widget:hover {
  background-color: #f18811;
}

.full-edit-subscene {}

.full-edit-widget {
  opacity: .4;
  display: flex;
  border: 1px solid grey;
}

.full-edit-widget .widget-name {
  margin: auto;
}

.full-edit-subscene .full-edit-widget {
  opacity: 1;
  border: 2px solid #56abab;
}

.full-edit-subscene .full-edit-widget {}

.full-edit-subscene .sub-scene {
  opacity: .4;
}

.full-edit-subscene .sub-scene .full-edit-widget {
  border: 1px solid grey;
}

.full-edit-widget.sub-scene-handle {
  position: absolute;
  top: 0;
  left: 0;
}

.full-edit-mode .widget-loader {
  padding: 2px;
}

.sub-scene-container {
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 300px;
  overflow: auto;
}

.sub-scene-container .sub-scene-item {
  border: 1px solid black;
  margin-top: 5px;
  cursor: default;
  padding: 2px;
}

.sub-scene-container .sub-scene-item.selected {
  border-left: 4px solid green;
  border-right: 4px solid green;
  font-weight: 500;
}

.sub-scene-container .sub-scene-item:not(.selected):hover {
  border-left: 4px solid #91cc91;
  border-right: 4px solid #91cc91;
}

.sub-scene-item .sub-scene-name {
  display: flex;
}

.sub-scene-item .sub-scene-name div {
  margin: auto;
}

.sub-scene-label {
  display: flex;
  font-weight: 500;
  color: grey;
}

.sub-scene-label div {
  margin: auto;
}

.push-code-container {
  height: 75px;
  width: 300px;
  display: flex;
}

.push-code-container .push-code {
  margin: auto;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  font-size: 20px;
  background-color: #606060;
}

.no-controls .document-widget .right-pane {
  display: none;
}

.no-controls .document-widget .button-bar {
  display: none;
}

.no-controls .document-widget .hyperlink-preview {
  display: none;
}

.no-controls .document-widget .attach-save-cancel {
  display: none;
}

.no-controls .document-widget .doc-edit-container {
  border-bottom: 5px solid black;
}

.no-controls .document-widget .doc-edit {
  min-height: 130px;
  max-height: 130px;
}

.no-controls .document-widget .doc-edit .add-attachment {
  padding-bottom: 0px;
}

.no-controls .document-widget .doc-edit {
  min-height: 130px;
  max-height: 130px;
}

.no-controls .document-item .doc-btn {
  display: none;
}

.no-controls .document-item .top-detail {
  display: none;
}

.no-controls .document-item .bottom-detail {
  font-weight: bold;
}

.auto-row-height .grid-row {
  min-height: unset !important;
}

.link-text {
  color: #1a0dab;
  margin-top: 0px !important;
}

.link-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.doc-bubble {
  position: absolute;
  top: -3px;
  right: -1px;
  background-color: #3060cd;
  border-radius: 20px;
  width: 15px;
  height: 15px;
  display: flex;
}

.doc-bubble div {
  margin: auto !important;
  font-size: 10px !important;
  color: white !important;
}

.clean-grid .grid-widget .grid-row.even {
  background-color: #f1f1f1;
}

.clean-grid .grid-widget .row-cell {
  border-right: none;
  height: 45px;
  font-size: 17px;
}

.clean-grid .grid-widget .row-cell a {
  text-decoration: none;
}

.clean-grid .grid-widget .row-cell a:hover {
  text-decoration: underline;
}

.clean-grid.bold-content .grid-widget .row-cell {
  font-weight: 500;
  color: #606060;
}

.clean-grid .grid-widget .header-col {
  border-right: none;
}

.guide-line {
  overflow: visible;
  position: relative;
}

.guide-line {
  border-left: 1px dotted orange;
  border-top: 1px dotted orange;
}

.guide-line {
  display: flex;
  pointer-events: none;
}

.guide-line .row-marker {
  margin-left: auto;
  margin-right: auto;
  margin-top: -1px;
  font-size: 12px;
  color: orange;
}

.guide-line .col-marker {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  font-size: 12px;
  color: orange;
}

.flex-height-cards .info-card {
  height: unset;
}

.flex-height-cards .info-card .text-1 {
  margin-top: 12px;
}

.flex-height-cards .info-card .info-card-text {
  padding-bottom: 10px;
}

.flex-height-cards .info-card .text-2 {
  white-space: nowrap;
  overflow: hidden;
  margin-left: 5px;
}

/* mysalesman imported css */

.failed-validate select, .failed-validate textarea, .failed-validate input {
  border: 2px solid #E0100B !important;
}

.mysales-ad-text .text-widget div {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: auto !important;
  margin-top: 0px !important;
  text-align: center !important;
}

.back-to-homepage .button {
  text-align: center;
  width: 210px !important;
  margin-left: 0px;
}

.back-to-homepage {
  width: 220px;
}

.mysalesman-grid-checkbox {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  overflow: visible;
  padding-bottom: 3px;
}

.mysalesman-grid-checkbox .checkbox-control {
  justify-content: center;
  flex-direction: row;
}

.mysalesman-grid-checkbox .control-label {
  font-size: .9rem;
  user-select: none;
  margin-top: 2px;
  order: 2;
  margin-left: 3px;
  font-weight: 500;
  z-index: 2005;
  overflow: visible;
}

.flex-btn-bar .btn-bar-btn {
  height: 40px;
  border-radius: 10px;
}

.dialog .polygon-btn-neg {
  color: black;
}

.dialog .btn-container {
  justify-content: center;
}

.flex-btn-bar {
  padding-left: 25px;
}

/* .width-300 {
  width: 150px !important;
} */

.checkbox-control .cbox {
  width: 20px;
  height: 20px;
}

.form .plain-text-label .title-label {
  background-color: unset !important;
  color: black;
  font-weight: 400;
  font-size: 12px;
  white-space: unset !important;
}

.form .plain-text-label .title-label>div {
  margin-left: 0px;
}

.template-widget {
  overflow: auto;
  height: 100%;
}

.template-widget>* {
  padding: 5px;
}

@media print {
  @page {
    margin: 0 !important;
  }

  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .do-not-print * {
    visibility: hidden;
  }

  .template-widget, .template-widget * {
    visibility: visible !important;
  }

  .template-widget {
    position: fixed;
    top: 0px;
  }
}

.template-editor-widget {
  padding: 10px;
  display: flex;
}

.editor-main-content {
  display: flex;
  flex: 1;
}

.editor-bottom-content {
  display: flex;
  flex: 1;
  width: 100%;
}

.edit-template-sidebar {
  flex: 0 250px;
  display: flex;
  flex-direction: column;
}

.edit-templates-list {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  padding: 5px;
}

.edit-templates-list .edit-templates-item {
  display: flex;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid black;
  margin-top: 5px;
  cursor: default;
}

.edit-templates-list .edit-templates-item:hover {
  background-color: #efefef;
}

.edit-templates-list .edit-templates-item.selected {
  background-color: #efefef;
}

.edit-templates-item .tmpl-name {
  font-size: 16px;
}

.markup-editor {
  flex: 1;
  min-width: 0px;
  padding: 5px;
  overflow: hidden;
  margin-top: 30px;
}

.markup-preview {
  flex: 1;
  min-width: 0px;
  overflow: auto;
  margin-left: 10px;
  margin-top: 30px;
}

.templates-test-data {
  flex: 1;
  padding: 5px;
}

.test-data-container {
  height: 100%;
  border: 1px solid grey;
  padding: 5px;
}

.markup-editor {
  height: 100%;
  border: 1px solid grey;
  padding: 5px;
}

.tmpl-edit-lbl {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}

.editor-top-bar {
  position: absolute;
  top: 0;
  display: flex;
  flex: 0 40px;
  min-height: 40px;
  width: 100%;
  padding-left: 250px;
  padding-right: 20px;
}

.editor-email {
  display: flex;
  margin: auto;
}

.editor-email input {
  height: 20px;
  margin-top: 2px;
  margin-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 2px;
  width: 300px;
}

.editor-email .e-to {
  margin-right: 5px;
}

.editor-email .send-test, .editor-save, .editor-cancel {
  border: 1px solid grey;
  border-radius: 20px;
  width: 80px;
  text-align: center;
  height: 23px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
  user-select: none;
}

.editor-save, .editor-cancel {
  margin-right: 5px;
  margin-top: auto;
  margin-bottom: auto;
}

.editor-save.active {
  background-color: #226640;
  color: white;
  border: 1px solid transparent;
}

.editor-save.active:hover {
  background-color: #4E8566;
}

.editor-cancel.active {
  background-color: #dd7c0e;
  color: white;
  border: 1px solid transparent;
}

.editor-cancel.active:hover {
  background-color: #f18811;
}

.editor-email .send-test {
  background-color: #d6eef9;
}

.editor-email .send-test:hover {
  background-color: #d0e6f0;
}

.full-height-grid .grid-widget {
  height: 100%;
}

.full-height-grid .grid-widget .gw-body {
  height: 100%;
}

.inline-fund .fund-card {
  padding: 5px;
  flex-direction: row;
  white-space: nowrap;
  width: unset !important;
}

.inline-fund .fund-card div:first-child {
  margin-left: 10px !important;
}

.inline-fund .fund-card div:last-child {
  margin-right: 10px !important;
  margin-left: 20px !important;
}

.overflow-dropdown {
  position: relative;
}

.overflow-dropdown .autodropdown-control {
  position: absolute;
  width: 100%;
}

.overflow-dropdown .auto-drop-down-items {
  position: fixed;
  width: unset;
}

.overflow-dropdown .auto-drop-down-items .auto-drop-down-item {
  padding-right: 200px;
}

.break-overflow-hover-info .hover-info {
  position: relative;
}

.break-overflow-hover-info .hover-info-parent {
  position: absolute;
  width: 100%;
}

.break-overflow-hover-info .hover-info-parent .hover-info-container {
  position: fixed;
}

.event-ag-grid .ag-column-drop-wrapper {
  display: none;
}

.event-ag-grid .ag-grid-column-picker, .event-ag-grid .ag-grid-refresh-arrow, .event-ag-grid .ag-grid-row-count-v2, .event-ag-grid .ag-grid-query-name {
  display: none;
}

.event-ag-grid .ag-grid-button.ag-top-bar-control {
  display: none;
}

.event-ag-grid .ag-grid-clear-filters-v2 {
  display: none;
}

.event-ag-grid .ag-cell-label-container {
  padding: 2px 0px 2px 0px;
}

.event-ag-grid .ag-header-row {
  height: 25px !important;
}

.event-ag-grid .ag-header {
  height: 27px !important;
  min-height: 27px !important;
}

.event-ag-grid .ag-grid-clear-filters-v2 div {
  padding-top: 0px;
  padding-bottom: 0px;
}

.event-ag-grid .ag-lookup-filter {
  max-height: 125px;
}

.dbmanager-widget {
  display: flex;
}

.db-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 5px;
  min-width: 250px;
}

.dbl-item {
  display: flex;
  padding: 0px 5px 0px 5px;
  cursor: default;
}

.dbl-name {
  margin-left: 5px;
}

.db-content {
  display: flex;
  flex-direction: column;
  min-width: 0px;
  width: 100%;
}

.db-tabs {
  display: flex;
  margin-left: auto;
  margin-right: 100px;
  margin-top: 5px;
}

.dbm-tab {
  display: flex;
  border: 1px solid black;
  width: 100px;
  height: 30px;
  margin: auto 5px 0px 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: default;
  position: relative;
  font-size: 14px;
}

.dbm-tab.active:before {
  content: '';
  border-top: 4px solid #ffa600;
  position: absolute;
  width: 100%;
  margin-right: -5px;
  border-radius: 5px 5px 0px 0px;
}

.dbm-tab div {
  margin: auto;
}

.db-content-pane {
  height: 100%;
  width: 100%;
  flex: 1;
  min-height: 0px;
  min-width: 0px;
}

.monaco-editor, .monaco-text {
  height: 100%;
}

.dbm-label {
  font-weight: 500;
}

.db-sql-tab {
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 100%;
  width: 100%;
}

.dbm-sql-box {
  border: 1px solid black;
  padding: 5px;
  min-height: 0px;
  display: flex;
}

.monaco-editor {
  width: 100%;
  height: 100%;
}

.dbm-btn {
  padding: 3px;
  border: 1px solid black;
  font-weight: 500;
}

.dbm-run-result {
  display: flex;
  flex-direction: column;
  min-height: 0px;
  flex: 1;
  min-width: 0px;
}

.dbm-run-result .db-tabs {
  margin-left: 5px;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: -1px;
}

.dbm-db-list {
  width: 150px;
  flex-direction: column;
  padding: 0px 5px 5px 0px;
  border-right: 1px solid black;
}

.dbm-run-item {
  cursor: default;
  padding-left: 5px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

.dbm-run-item.active {
  font-weight: 500;
  border-left: 3px solid #ffa600;
  border-right: 3px solid #ffa600;
}

.dbm-sql-result {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  min-height: 0px;
}

.dbm-sql-header {
  display: flex;
  border-bottom: 1px solid black;
  font-size: 12px;
}

.dbm-sql-header-cell {
  border-right: 1px solid black;
  padding-right: 5px;
  padding-left: 5px;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.dbm-sql-column:last-of-type .dbm-sql-header-cell {
  border-right: none;
}

.dbm-sql-body-cell {
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  max-width: 200px;
  display: flex;
}

.dbm-sql-body-cell div {
  margin-top: auto;
  margin-bottom: auto;
}

.dbm-sql-column ::-webkit-scrollbar {
  height: 4px;
}

.dbm-sql-column ::-webkit-scrollbar-thumb {
  background: rgb(163, 163, 163);
}

.dbm-sql-column ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dbm-sql-column ::-webkit-scrollbar-track {
  background: #e6e6e6;
}

.dbm-sql-header .dbm-sql-column:last-of-type {
  border-right: 1px solid black;
}

.dbm-sql-body {
  display: flex;
  flex-direction: column;
}

.dbm-sql-row {
  display: flex;
}

.dbm-sql-row-cell {
  border-right: 1px solid #f0f0f0;
  padding-right: 5px;
  padding-left: 5px;
  border-bottom: 1px solid #f0f0f0;
}

.dbm-label-btn {
  display: flex;
}

.dbm-label-btn .dbm-tri-btn {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  padding: 3px;
  border: 1px solid transparent;
  background-color: #e1e1e1;
  border-radius: 100px;
  display: flex;
  padding-left: 5px;
  margin-top: 1px;
}

.dbm-label-btn .dbm-tri-btn:hover {
  border: 1px solid #505050;
}

.dbm-label-btn .dbm-tri-btn div {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 9px solid green;
  margin: auto;
}

.db-schema-tab {
  display: flex;
  padding: 5px;
  height: 100%;
}

.schema-container {
  display: flex;
  flex-direction: column;
  cursor: default;
  overflow: auto;
  min-height: 0px;
  padding: 5px;
  height: 100%;
}

.schema-container .category-item {
  display: flex;
  flex-direction: column;
}

.category-item .category-name {
  display: flex;
}

.category-item .category-exp {
  margin-right: 5px;
  width: 15px;
}

.schema-item {
  display: flex;
  margin-left: 25px;
  font-size: 14px;
}

.schema-item .schema-lbl {
  margin-right: 10px;
}

.schema-item .schema-qty {
  margin: auto;
  margin-right: 5px;
}

.schema-compare {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  min-height: 0px;
}

.compare-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.editor-monaco-box {
  flex: 1;
  display: flex;
  padding: 5px;
  border: 1px solid black;
}

body>iframe {
  display: none;
}

.a-b-tabs {
  display: flex;
}

.a-b-tabs .db-tabs {
  margin-left: 10px;
  flex: 1;
}

.text-ellipsis-truncate > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}