#driver-locations-metadata-overlay.disabled {
    display: none;
}

#driver-locations-metadata-overlay {
    box-shadow: 0 2px 4px #1a1a1a;
    border: 2px solid #1a1a1a;
    background-color: white;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
}

#driver-locations-metadata-overlay span {
    font-size: 0.75rem;
}