.banner-311, .banner-311-dark {
  background-color: #FDC134;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  color: white;
  z-index: 1;
}

.banner-311-dark {
  background-color: #B3B2B3;
}

.roboto-text * {
  font-family: 'Roboto', sans-serif;
}

.light-border {
  border: 2px solid #f2f2f2;
}

.center-contents * {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
}

.large-text {
  font-size: 24px;
}

.medium-large-text {
  font-size: 20px;
}

.medium-text {
  font-size: 16px;
  font-weight: 500;
}

.grey-text {
  color: #2C2C2C8A;
}

.white-background {
  background-color: white;
}

.no-top-border {
  border-top: none;
}

.no-bottom-border {
  border-bottom: none;
}

.no-right-border {
  border-right: none;
}

.no-left-border {
  border-left: none;
}

.clean-form .form-grid {
  background-color: transparent;
}

.mobile-dropdown-control {
  flex: 1;
  display: flex;
}

.mobile-dropdown-button.focus-control {
  background-color: #4AB84A;
}

.mobile-dropdown-button {
  background-color: #707070;
  color: white;
  display: flex;
  flex: 1;
  padding: 5px;
  margin: 5px;
  font-size: 18px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  height: 60px;
  cursor: default;
  min-width: 0px;
}

.mobile-dropdown-button.disabled {
  background-color: #91939633;
  box-shadow: none;
  color: #919396;
}

.mobile-dropdown-button .dropdown-text {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  display: flex;
  flex: 1;
  min-width: 0px;
}

.mobile-dropdown-button .dropdown-text div:not(.mobile-dropdown-chevron) {
  flex: 1;
  white-space: nowrap;
  min-width: 0px;
}

.mobile-dropdown-button .dropdown-text .mobile-dropdown-chevron {
  transform: rotate(90deg);
  margin-left: auto;
  margin-right: 15px;
  font-size: 30px;
  line-height: 18px;
  min-width: 0px;
}

.mobile-options-picker {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #7070708A;
  display: flex;
  position: fixed;
  z-index: 2222;
}

.mobile-options-container {
  flex: 1;
  display: flex;
  z-index: 1;
  margin: 20px;
  border: 1px solid #707070;
  border-radius: 6px;
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mobile-options-scrollbox {
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.mobile-options-container .mobile-option-item {
  display: flex;
  flex: 0 0 60px;
  padding-left: 20px;
  font-size: 17px;
  font-weight: 500;
  cursor: default;
}

.mobile-options-container .mobile-option-item:hover {
  background-color: #ffefcb;
}

.mobile-options-container .mobile-option-item>div {
  margin-top: auto;
  margin-bottom: auto;
}

.mobile-button .button-container {
  padding: 5px;
}

.mobile-button .form-grid {
  margin: 0px;
  padding: 5px;
  background-color: unset;
}

.mobile-button .form-control {
  margin-left: 0px;
  margin-right: 0px;
}

.mobile-button .button {
  width: 100%;
  height: 100%;
  margin-left: 0;
  font-size: 20px;
  border-radius: unset;
  background-color: #4AB84A;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
}

.mobile-button .button.disabled {
  background-color: #91939633 !important;
  color: #a1a1a1;
  box-shadow: none;
}

.footer-text {
  color: #e0e0e0;
  font-size: 12px;
  white-space: nowrap;
}

.footer-text .text-widget>div {
  margin-left: 0px;
}

.wf-formula {
  display: flex;
  margin-top: auto;
  flex: 0 0 40px;
  font-size: 22px;
  color: white;
  background-color: teal;
}

.wf-formula>div {
  margin: auto;
}

.data-entry-banner {
  background-color: #F2F2F3;
  z-index: 0;
}

/* Keyframes for the fade-in */

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  opacity: 1 \9;
  /*just in case ie*/
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fade-in.one {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.fade-in.two {
  -webkit-animation-delay: 1.75s;
  -moz-animation-delay: 1.75s;
  animation-delay: 1.75s;
}

.fade-in.three {
  -webkit-animation-delay: 1.75s;
  -moz-animation-delay: 1.75s;
  animation-delay: 2.5s;
}

.welcome-311 {
  -webkit-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.welcome-button-311 {
  margin: auto;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16pt;
  cursor: pointer;
}

.welcome-button-311:hover {
  font-size: 18pt;
  text-shadow: 0px 0px 4px rgb(58, 53, 53);
}

.large-card-311 .card-item {
  display: inline-block;
  width: 100%;
}

.large-card-311 .card-item .text-2 {
  margin-top: 10px;
}

.button-311 {
  margin: auto;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14pt;
  cursor: pointer;
}

.button-311.active {
  margin: auto;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18pt;
  text-shadow: 0px 0px 4px rgb(58, 53, 53);
}

.form-311 .form-grid {
  background-color: white;
}

.card-list-311 .card-list {
  background-color: white;
}

/* cyrillic-ext */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}